import fetchApi from "../fetchApi";

export interface GetUsersListAllQueryParams {
  cursor?: string;
  take?: number;
  ageMin?: number;
  ageMax?: number;
  heightMax?: number;
  heightMin?: number;
  weightMax?: number;
  weightMin?: number;
  eyeColor?: string[];
  hairColor?: string[];
  nationality?: string[];
  ethnicity?: string[];
  languages?: string[];
}

const getUsersListAll = async (
  token: string,
  queryParams?: Partial<GetUsersListAllQueryParams>
) => {
  const params: Record<string, any> = {};

  if (queryParams?.cursor) {
    params.cursor = queryParams?.cursor;
  }

  if (queryParams?.take) {
    params.take = queryParams?.take;
  }

  if (queryParams?.ageMin) {
    params.ageMin = queryParams?.ageMin;
  }

  if (queryParams?.ageMax) {
    params.ageMax = queryParams?.ageMax;
  }

  if (queryParams?.heightMax) {
    params.heightMax = queryParams?.heightMax;
  }

  if (queryParams?.heightMin) {
    params.heightMin = queryParams?.heightMin;
  }

  if (queryParams?.weightMax) {
    params.weightMax = queryParams?.weightMax;
  }

  if (queryParams?.weightMin) {
    params.weightMin = queryParams?.weightMin;
  }

  if (queryParams?.eyeColor) {
    params.eyeColor = queryParams?.eyeColor;
  }

  if (queryParams?.hairColor) {
    params.hairColor = queryParams?.hairColor;
  }

  if (queryParams?.nationality) {
    params.nationality = queryParams?.nationality;
  }

  if (queryParams?.ethnicity) {
    params.ethnicity = queryParams?.ethnicity;
  }

  if (queryParams?.languages) {
    params.languages = queryParams?.languages;
  }

  return await fetchApi({
    service: "user",
    endPoint: "/search-creators",
    method: "GET",
    token,
    params,
  });
};

export default getUsersListAll;

import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ProviderProps } from "../types/interfaces";
import { getElementByPath } from "../utils/navigationDesk";

export interface Params {
  [key: string]: string;
}

type IScreenKeys = "principal" | "secondary" | "tertiary" | "main" | "feed";
type IScreenState = {
  principal: string | undefined | null;
  secondary: string | undefined | null;
  tertiary: string | undefined | null;
  main: string | undefined | null;
  feed: string | undefined | null;
};

interface DeskNavigationContextType {
  setPrincipalScreen: (path: string, params?: Params) => void;
  setSecondaryScreen: (path: string, params?: Params) => void;
  setTertiaryScreen: (path: string, params?: Params) => void;
  setMainScreen: (path: string, params?: Params) => void;
  setFeedScreen: (path: string, params?: Params) => void;
  componentPrincipal: ReactNode;
  componentSecondary: ReactNode;
  componentTertiary: ReactNode;
  mainScreen: ReactNode;
  componentFeed: ReactNode;
  params: Params;
}

const DeskNavigationContext = createContext({} as DeskNavigationContextType);

const DeskNavigationProvider = ({ children }: ProviderProps) => {
  const [principalScreen, setPrincipalScreenState] = useState<string>("");
  const [componentPrincipal, setComponentPrincipal] =
    useState<ReactNode | null>(null);

  const [feedScreenState, setFeedScreenState] = useState<string>("");
  const [componentFeed, setComponentFeed] = useState<ReactNode | null>(null);

  const [secondaryScreen, setSecondaryScreenState] = useState<string>("");
  const [componentSecondary, setComponentSecondary] =
    useState<ReactNode | null>(null);

  const [tertiaryScreen, setTertiaryScreenState] = useState<string>("");
  const [componentTertiary, setComponentTertiary] = useState<ReactNode | null>(
    null
  );

  const [mainScreen, setMainScreenState] = useState<string>("");
  const [componentMain, setComponentMain] = useState<ReactNode | null>(null);

  const [screenState, setScreenState] = useState({} as IScreenState);

  const [params, setParams] = useState<Params>({});

  const setScreenWithHistory = (screenKey: IScreenKeys, path: string) => {
    const state = { params, path, screenKey };
    window.history.pushState(
      state,
      "",
      ""
      // `?${new URLSearchParams(params).toString()}`
    );
  };

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (event.state) {
        restoreScreen(event.state);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const restoreScreen = (state: { screenKey: IScreenKeys; path: string }) => {
    if (!state) {
      return;
    }

    const { screenKey, path } = state;

    switch (screenKey) {
      case "feed":
        setFeedScreenState(path);
        setScreenState((prev) => ({ ...prev, feed: path }));
        break;
      case "principal":
        setPrincipalScreenState(path);
        if (!screenState.secondary) {
          setComponentSecondary(null);
          setScreenState((prev) => ({ ...prev, secondary: "" }));
        }
        if (!screenState.tertiary) {
          setComponentTertiary(null);
          setScreenState((prev) => ({ ...prev, tertiary: "" }));
        }

        if (!screenState.feed) {
          setComponentFeed(null);
          setScreenState((prev) => ({ ...prev, feed: "" }));
        }
        break;
      case "secondary":
        setSecondaryScreenState(path);
        setScreenState((prev) => ({ ...prev, secondary: path }));

        if (!screenState.tertiary) {
          setComponentTertiary(null);
          setScreenState((prev) => ({ ...prev, tertiary: "" }));
        }

        if (!screenState.feed) {
          setComponentFeed(null);
          setScreenState((prev) => ({ ...prev, feed: "" }));
        }

        break;
      case "tertiary":
        setTertiaryScreenState(path);
        setScreenState((prev) => ({ ...prev, tertiary: path }));

        if (!screenState.feed) {
          setComponentFeed(null);
          setScreenState((prev) => ({ ...prev, feed: "" }));
        }

        break;
      case "main":
        setMainScreenState(path);
        setScreenState((prev) => ({ ...prev, main: path }));
        if (!screenState.principal) {
          setComponentPrincipal(null);
          setScreenState((prev) => ({ ...prev, principal: "" }));
        }

        if (!screenState.secondary) {
          setComponentSecondary(null);
          setScreenState((prev) => ({ ...prev, secondary: "" }));
        }
        if (!screenState.tertiary) {
          setComponentTertiary(null);
          setScreenState((prev) => ({ ...prev, tertiary: "" }));
        }

        if (!screenState.feed) {
          setComponentFeed(null);
          setScreenState((prev) => ({ ...prev, feed: "" }));
        }

        break;
      default:
        setFeedScreenState(path);
        setPrincipalScreenState(path);
        setSecondaryScreenState(path);
        setTertiaryScreenState(path);
        break;
    }
  };

  const setScreen = (
    setState: React.Dispatch<React.SetStateAction<string>>,
    path: string,
    params?: Params
  ) => {
    if (params) {
      setParams(params);
    }
    setState(path);
  };
  useEffect(() => {
    const initialState = {
      params: {},
      path: "/catalog",
      screenKey: "main",
    };

    if (!window.history.state) {
      window.history.replaceState(initialState, "", "");
    }
  }, []);

  useEffect(() => {
    const page = getElementByPath(feedScreenState);

    if (page) {
      setComponentFeed(page);
      setScreenWithHistory("feed", feedScreenState);
    } else {
      setComponentFeed(null);
    }
    setScreenState((prev) => ({ ...prev, feed: feedScreenState }));
  }, [feedScreenState]);

  useEffect(() => {
    const page = getElementByPath(principalScreen);
    if (page) {
      setComponentPrincipal(page);
      setScreenWithHistory("principal", principalScreen);
    } else {
      setComponentPrincipal(null);
    }
    setScreenState((prev) => ({ ...prev, principal: principalScreen }));
  }, [principalScreen]);

  useEffect(() => {
    const page = getElementByPath(secondaryScreen);

    if (page) {
      setComponentSecondary(page);
      setScreenWithHistory("secondary", secondaryScreen);
    } else {
      setComponentSecondary(null);
    }
    setScreenState((prev) => ({ ...prev, secondary: secondaryScreen }));
  }, [secondaryScreen]);

  useEffect(() => {
    const page = getElementByPath(tertiaryScreen);

    if (page) {
      setComponentTertiary(page);
      setScreenWithHistory("tertiary", tertiaryScreen);
    } else {
      setComponentTertiary(null);
    }
    setScreenState((prev) => ({ ...prev, tertiary: tertiaryScreen }));
  }, [tertiaryScreen]);

  useEffect(() => {
    const page = getElementByPath(mainScreen);

    if (page) {
      setComponentMain(page);
      setScreenWithHistory("main", mainScreen);
    } else {
      setComponentMain(null);
    }
    setScreenState((prev) => ({ ...prev, main: mainScreen }));
  }, [mainScreen]);

  return (
    <DeskNavigationContext.Provider
      value={{
        componentPrincipal,
        componentSecondary,
        componentTertiary,
        componentFeed,

        setFeedScreen: (path, params) =>
          setScreen(setFeedScreenState, path, params),
        setPrincipalScreen: (path, params) =>
          setScreen(setPrincipalScreenState, path, params),
        setSecondaryScreen: (path, params) =>
          setScreen(setSecondaryScreenState, path, params ?? {}),
        setTertiaryScreen: (path, params) =>
          setScreen(setTertiaryScreenState, path, params),
        setMainScreen: (path, params) =>
          setScreen(setMainScreenState, path, params),
        mainScreen: componentMain,
        params,
      }}
    >
      {children}
    </DeskNavigationContext.Provider>
  );
};

const useDeskNavigation = () => {
  const context = useContext(DeskNavigationContext);
  return context;
};

export { DeskNavigationProvider, useDeskNavigation };

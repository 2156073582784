import { useState } from "react";
import Header from "components/Headers/Header";
import "./styles.scss";
import EyeIcon from "assets/icons/eye-icon-gray.svg";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import { useNavigate } from "react-router-dom";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import createUser from "../../api/auth/postCreateUser";
import PasswordStrengthBar from "react-password-strength-bar";
import getUserInfo from "api/userInfo/getUserInfo";
import { useToast } from "hooks/useToast";
import { localStorageClear } from "utils/localStorageClear";

const CreateAccountPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userNameWarning, setUserNameWarning] = useState(false);
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [createdPassword, setCreatedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [isSignNewsLetter, setIsSignNewsLetter] = useState(true);

  const { setComponent } = useLoginSignUpNavigation();
  const { showToast } = useToast();

  const { setUserInfo, userInfo } = useUserInfo();

  const getUserInfoRequest = async (token: string) => {
    try {
      const response = await getUserInfo(token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
          } else {
            setUserInfo(
              "user_profile_avatar",
              response.res?.photos[0]?.thumbnailUrl
            );
          }
          break;
        case 401:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createAccountSubmit = async () => {
    if (userName.trim().length < 3) {
      showToast({
        description: "Your display name should be at least 3 characters long",
        title: "Short Display name",
        type: "warning",
      });

      setUserNameWarning(true);

      setTimeout(() => {
        setUserNameWarning(false);
      }, 4000);
      return;
    }

    if (
      userEmail.length <= 5 ||
      !userEmail.includes("@") ||
      !userEmail.includes(".com")
    ) {
      showToast({
        description: "Check your email and try again",
        title: "Invalid email",
        type: "warning",
      });

      setUserEmailWarning(true);

      setTimeout(() => {
        setUserEmailWarning(false);
      }, 4000);
      return;
    }

    if (confirmPassword.trim().length < 8) {
      showToast({
        description: "Your password should be at least 8 characters long",
        title: "Short Pass",
        type: "warning",
      });

      setPasswordInputWarning(true);

      setTimeout(() => {
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    if (createdPassword.trim() !== confirmPassword.trim()) {
      showToast({
        description: "Check passwords and try again",
        title: "Passwords don't match",
        type: "warning",
      });

      setPasswordInputWarning(true);

      setTimeout(() => {
        setPasswordInputWarning(false);
      }, 4000);
      return;
    }

    setIsLoading(true);

    try {
      const createdUserData = {
        displayname: userName,
        email: userEmail,
        password: confirmPassword.trim(),
      };

      const createUserResponse = await createUser(createdUserData);

      switch (createUserResponse.status) {
        case 201:
          // The cleaners below keep the information up to data
          localStorageClear();
          sessionStorage.clear();
          const accessToken = createUserResponse.res.access_token;

          setUserInfo("access_token", accessToken);

          const activateResponse = await getUserSelfDetail(accessToken);

          switch (activateResponse?.status) {
            case 200:
              getUserInfoRequest(accessToken);
              setUserInfo("user_id", activateResponse.res.userId);
              setUserInfo("display_name", activateResponse.res.displayname);
              setUserInfo("account_type", activateResponse.res.role);
              setUserInfo("user_email", activateResponse.res.email);
              setUserInfo("user_phone", activateResponse.res.phone);

              setComponent("/create-email-verify");
              break;

            default:
              showToast({
                description:
                  "Something went wrong on user activate. Please try again.",
                title: "Error",
                type: "error",
              });

              break;
          }

          break;

        case 409:
          showToast({
            description: "Register with new credentials or log in",
            title: "User already registered",
            type: "warning",
          });

          setUserEmailWarning(true);

          break;

        case 422:
          if (createUserResponse.res.message[0].includes("email")) {
            showToast({
              description: "Check your email and try again",
              title: "Invalid email",
              type: "warning",
            });

            setUserEmailWarning(true);

            setTimeout(() => {
              setUserEmailWarning(false);
            }, 4000);
          } else {
            showToast({
              description:
                "Something went wrong on user creation. Please try again.",
              title: "Error",
              type: "error",
            });
          }

          break;

        default:
          showToast({
            description:
              "Something went wrong on user creation. Please try again.",
            title: "Error",
            type: "error",
          });

          break;
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  return (
    <div className="create-account-page margin-vert-40">
      <Header
        headerIcon="back-button"
        titleClass="header"
        marginTop={0}
        backButtonClick={() => setComponent("/account-login")}
        title={
          <>
            Create your <br />
            account
          </>
        }
      />
      <div className="input-container">
        <InputText
          value={userName}
          placeholder="Name"
          onChangeInput={(e) => setUserName(e)}
          isWarning={userNameWarning}
          className="no-margin"
          inputType="text"
        />
        <SubText icon={EyeIcon} altIcon="clock icon">
          This will be shown on your profile
        </SubText>
        <hr className="no-margin" />
        <InputText
          value={userEmail}
          placeholder="Email"
          onChangeInput={(e) => setUserEmail(e)}
          isWarning={userEmailWarning}
          className="no-margin"
          inputType="email"
        />
        <SubText
          checkAnswer
          checkValueCallback={setIsSignNewsLetter}
          isChecked={isSignNewsLetter}
        >
          I want to receive news, updates, and offers from Roses
        </SubText>
        <InputText
          value={createdPassword}
          placeholder="Password"
          inputType="password"
          onChangeInput={(e) => setCreatedPassword(e)}
          isWarning={passwordInputWarning}
          className="no-margin"
        />

        <InputText
          value={confirmPassword}
          placeholder="Confirm Password"
          inputType="password"
          onChangeInput={(e) => setConfirmPassword(e)}
          isWarning={passwordInputWarning}
          className="no-margin"
        />
        <PasswordStrengthBar
          minLength={8}
          barColors={[
            "var(--charcoal)",
            "var(--peach)",
            "#FFC045",
            "var(--green)",
            "var(--green",
          ]}
          scoreWords={[
            "(add more characters to strengthen) very weak",
            "(add more characters to strengthen) weak",
            "good",
            "strong",
            "very strong",
          ]}
          className="teste"
          scoreWordClassName="texto"
          shortScoreWord="Your password should be at least 8 characters long"
          password={createdPassword}
        />
      </div>
      <div className="buttons-container margin-top-24">
        <Button
          disabled={isLoading}
          buttonStyle="quaternary"
          onClick={() => setComponent("/account-login")}
        >
          Already have an account?<span className="highlight">Login</span>
        </Button>
        <Button
          disabled={isLoading}
          buttonStyle="primary"
          onClick={createAccountSubmit}
        >
          Create Account
        </Button>
      </div>
    </div>
  );
};

export default CreateAccountPage;

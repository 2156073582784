import { useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import ListItemLink from "components/Lists/ListItemLink";
import TextActionCard from "components/Cards/TextActionCard";
import SlidUp from "components/Modals/SlidUp";
import SendRosesModal from "components/Chat/ChatModals/SendRosesModal";
import RosesDashboard from "components/Wallet/RosesDashboard";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useBuyRoses } from "hooks/useBuyRoses";
import { useIconContext } from "../../contexts/iconNavContext";
import { useAvailableRoses } from "contexts/availableRosesContext";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import accountIconGray from "assets/icons/gear-icon-gray.svg";
import linkIcon from "assets/icons/link-icon-gray.svg";
import myOffersIconGray from "assets/icons/offers-icon-gray.svg";
import notificationsIconGray from "assets/icons/notification-icon-gray.svg";
import profileIconGray from "assets/icons/profile-icon-gray.svg";
import startGray from "assets/icons/star-icon-gray.svg";
import wallet from "assets/icons/wallet-icon-gray.svg";
import faceGray from "assets/icons/face-pink-gray.svg";
import mobileIcon from "assets/icons/mobile.svg";
import { thousandsFormat } from "utils/thousandsFormat";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useToast } from "hooks/useToast";
import { IToast } from "types/toast";

const UserSettingsPage: React.FC = () => {
  const { availableRoses } = useAvailableRoses();
  const { setPrincipalScreen } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { userInfo, setUserInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const pastTokenStorage = localStorage.getItem("user-info-past-token");
  const [isOpen, setIsOpen] = useState(false);
  const { showToast } = useToast();

  const { buyRoses } = useBuyRoses();

  useEffect(() => {
    const creatorApplyStatus = async () => {
      const responseStatus = await getCreatorStatus(userInfo.access_token);

      if (responseStatus?.status === 200) {
        setUserInfo("referral_code", responseStatus?.res.referralCode);
        setUserInfo("creator_application_status", responseStatus?.res.status);
        setUserInfo(
          "creator_application_id",
          responseStatus?.res.creatorApplicationId
        );
      } else if (responseStatus?.status === 404) {
        if (userInfo.account_type === "CREATOR") {
          setUserInfo("creator_application_status", "APPROVED");
        }
        setUserInfo("creator_application_status", "unsolicited");
      }
    };

    creatorApplyStatus();
  }, []);

  const becameACreatorHandler = () => {
    if (userInfo.creator_application_status === "PENDING") {
      setPrincipalScreen("/wait-list-application");
    } else {
      setPrincipalScreen("/create-legal-name");
    }
  };

  const userSettingsItems = [
    {
      labelContent: "Profile",
      iconLeftContent: profileIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-profile"),
      isActive: true,
    },

    {
      labelContent: "My Wallet",
      iconLeftContent: wallet,
      clickAction: () => setPrincipalScreen("/user-settings-wallet"),
      isActive: true,
    },

    {
      labelContent: "Notifications",
      iconLeftContent: notificationsIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-notifications"),
      isActive: true,
    },

    {
      labelContent: "Subscription",
      iconLeftContent: startGray,
      clickAction: () => setPrincipalScreen("/user-settings-subscriptions"),
      isActive: true,
    },

    {
      labelContent: "Referrals",
      iconLeftContent: linkIcon,
      clickAction: () => setPrincipalScreen(""),
      isActive: userInfo.creator_application_status !== "unsolicited",
    },

    {
      labelContent: "My Offers",
      iconLeftContent: myOffersIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-myoffers"),
      isActive: true,
    },

    {
      labelContent: "Manage Profiles",
      iconLeftContent: faceGray,
      clickAction: () => setPrincipalScreen("/user-settings-change-creator"),
      isActive: userInfo.account_type === "ADMIN" || pastTokenStorage,
    },

    {
      labelContent: "Account Settings",
      iconLeftContent: accountIconGray,
      clickAction: () => setPrincipalScreen("/user-settings-account"),
      isActive: true,
    },
  ];

  const pageBack = () => {
    setPrincipalScreen("");
    handleButtonClick("grid");
  };

  const handleBuyRoses = async () => {
    const res = await buyRoses();

    if (res) {
      handleToast({
        type: "success",
        title: "Buy Roses",
        description: "Congratulations",
      });
    } else {
      handleToast({
        type: "error",
        title: "Buy Roses",
        description: "Congratulations",
      });
    }
  };

  const handleToast = ({ type, title, description }: IToast) => {
    showToast({
      type,
      title,
      description,
    });
  };

  return (
    <div className="user-settings">
      <PageTitle
        title={"DashBoard"}
        subtitle={"Configuration"}
        hasCloseIcon={windowSize >= 768}
        className="margin-hor-16"
        onBackClick={pageBack}
      />

      <section className="user-settings__top">
        <ListItemLink
          highlightText={"Get Mobile App"}
          simpleText={"Scan QR Code"}
          imageLeft={mobileIcon}
          isImageCircle
          hasArrowRight
          isBackTransparent
          isBigIcon
          imageCircleMidnight
          className="user-settings-list-item margin-bottom-16 padding-16"
        />

        <RosesDashboard
          title={thousandsFormat(availableRoses)}
          showAddButton={true}
          showGoldRoses={true}
          onClick={() => setIsOpen(!isOpen)}
        />

        <SlidUp
          isOpen={isOpen}
          classNameContainer="user-settings-wallet__slid"
          classNameChildren="user-settings-wallet__slide-children"
          closeArea={() => {
            setIsOpen(false);
          }}
        >
          <SendRosesModal
            title="Buy Roses"
            description="Select Roses below"
            showRosesOptions
            showInfoGold
            showTable={{
              table: false,
              header: {
                gold: true,
                roses: true,
                total: true,
              },
            }}
            typeModal="buy"
            buttonText="Buy Roses"
            onClose={() => setIsOpen(false)}
            onClick={() => handleBuyRoses()}
          />
        </SlidUp>
      </section>

      <div className="list-container-settings">
        {userSettingsItems
          .filter((item) => item.isActive)
          .map((item, index, arr) => (
            <ListItemLink
              key={index}
              onClick={item.clickAction}
              hasArrowRight
              imageLeft={item.iconLeftContent}
              isImageCircle
              highlightText={item.labelContent}
              hasSeparator={arr.length > index + 1}
            />
          ))}
      </div>

      {userInfo.creator_application_status === "unsolicited" ? (
        <TextActionCard
          className="margin-24"
          buttonClick={becameACreatorHandler}
          principalText="Start Earning (Monetize Content)"
          secondaryText={`Apply for a creator’s account and monetize your 
							exclusive content today.`}
          textButton="Become a Creator"
        />
      ) : (
        false
      )}
    </div>
  );
};

export default UserSettingsPage;

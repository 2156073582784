import React from "react";
import ReactDOM from "react-dom/client";
import "./scss";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { initializeApp } from "firebase/app";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import AppProvider from "./provider";
import Toast from "components/Toast";
import { ToastProvider } from "hooks/useToast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const firebaseConfig = {
  apiKey: "AIzaSyBfpzG7NcAdYUFiWDIKrln_bSbZT3FZIyI",
  authDomain: "roses--main.firebaseapp.com",
  projectId: "roses--main",
  storageBucket: "roses--main.appspot.com",
  messagingSenderId: "961345023378",
  appId: "1:961345023378:web:20fd72bf30e3b1d48ea54b",
  measurementId: "G-WXX5KQBN5X",
};

const app = initializeApp(firebaseConfig);

root.render(
  // <React.StrictMode>

  <AppProvider>
    <RouterProvider router={router} />
    <Toast />
  </AppProvider>

  // </React.StrictMode>
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service Worker registered successfully:", registration);
      })
      .catch((error) => {
        console.log("Failed to register Service Worker:", error);
      });
  });
}

import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import Button from "components/Buttons/Button";
import InputMessage from "components/TextAreas/Textarea";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import getUserInfo from "../../api/userInfo/getUserInfo";
import { UserInfoRequest } from "../../types/userInfoRequest";
import { IToast } from "../../types/toast";
import Toast from "components/Toast";
import { useToast } from "hooks/useToast";

interface ListItemBioProps {
  tokenUser?: string;
  key?: string;
  valueb?: string;
}

const UserSettingsEditBioPage: React.FC<ListItemBioProps> = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { showToast } = useToast();
  const { userInfo } = useUserInfo();
  const [value, setValue] = useState("");
  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);

  const maxLen = 250;

  const saveEditHandler = async () => {
    await patchProfileDetails(userInfo.access_token, "bio", value);
    showToast({
      title: "Success",
      description: "Description changed successfully",
      type: "success",
    });

    setTimeout(() => {
      setPrincipalScreen("/user-settings-profile");
    }, 2000);
  };

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo.access_token);
    setUserInfoRequest(response.res);
    if (userInfoRequest) {
      if (userInfoRequest.bio) {
        setValue(userInfoRequest.bio);
      }
    }
  };

  useEffect(() => {
    requestUserInfo();
  }, [userInfo, userInfoRequest?.bio]);

  return (
    <div className="user-settings-edit-profile">
      <PageTitle
        title={"Edit Profile"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        className="margin-hor-16"
        onBackClick={() =>
          setPrincipalScreen("/user-settings-profile", { btn: "button2" })
        }
      />
      <main className="user-settings-edit-profile__main margin-24">
        <div className="user-settings-edit-profile__input-container">
          <InputMessage
            value={value}
            onChangeInput={(e) => setValue(e)}
            label="Bio"
            placeholder={"About me"}
            maxLen={maxLen}
          />
          <div className="character-counter">
            {value.length}/{maxLen} characters
          </div>
        </div>

        <Button
          className="margin-top-40"
          buttonStyle="primary"
          onClick={saveEditHandler}
        >
          Done
        </Button>
      </main>
    </div>
  );
};

export default UserSettingsEditBioPage;

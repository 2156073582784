import { useState } from "react";
import Header from "components/Headers/Header";

import "./styles.scss";
import EyeIcon from "assets/icons/eye-icon-gray.svg";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import ListItem from "components/Lists/ListItem";

const InviteFriendsPage: React.FC = () => {
  const [searchFriend, setSearchFriend] = useState("");

  const friends = [
    {
      name: "Jonatas Filipe",
      initials: "jf",
      tel: "(11)9 99999 9999",
    },
    {
      name: "Fulano da Silva",
      initials: "FS",
      tel: "(11)9 99999 9999",
    },
    {
      name: "John Doe",
      initials: "Jd",
      tel: "(11)9 99999 9999",
    },
  ];

  const sendInvite = () => {};
  const copyCode = () => {};

  return (
    <div className="invite-friends">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={() => {}}
          title="Invite friends"
          subTitle={`Expand your network and increase your chances of membership 
					approval by inviting friends. Share your unique member ID, copy the 
					link, or use the QR code to invite.`}
        />
        <InputText
          value={searchFriend}
          placeholder="Search"
          onChangeInput={(e) => setSearchFriend(e)}
          searchInput
          inputType="text"
        />

        {friends?.map((friends, index) => (
          <ListItem
            key={index}
            showLabel={true}
            labelContent={friends.name}
            showDescription
            descriptionContent={friends.tel}
            contactList
            initialsContact={friends.initials}
            showSeparator
            showCheckboxRight
          />
        ))}
      </div>

      <Button buttonStyle="primary" onClick={sendInvite}>
        Send Invite
      </Button>
      <Button buttonStyle="tertiary" onClick={copyCode}>
        Copy Code
      </Button>
    </div>
  );
};

export default InviteFriendsPage;

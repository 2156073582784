import { GetUsersListAllQueryParams } from "api/user/getUsersListAll";
import fetchApi from "../fetchApi";

const getPublicCreators = async (
  queryParams?: Partial<GetUsersListAllQueryParams>
) => {
  const params: Record<string, any> = {};

  if (queryParams?.cursor) {
    params.cursor = queryParams?.cursor;
  }

  if (queryParams?.take) {
    params.take = queryParams?.take;
  }

  if (queryParams?.ageMin) {
    params.ageMin = queryParams?.ageMin;
  }

  if (queryParams?.ageMax) {
    params.ageMax = queryParams?.ageMax;
  }

  if (queryParams?.heightMax) {
    params.heightMax = queryParams?.heightMax;
  }

  if (queryParams?.heightMin) {
    params.heightMin = queryParams?.heightMin;
  }

  if (queryParams?.weightMax) {
    params.weightMax = queryParams?.weightMax;
  }

  if (queryParams?.weightMin) {
    params.weightMin = queryParams?.weightMin;
  }

  if (queryParams?.eyeColor) {
    params.eyeColor = queryParams?.eyeColor;
  }

  if (queryParams?.hairColor) {
    params.hairColor = queryParams?.hairColor;
  }

  if (queryParams?.nationality) {
    params.nationality = queryParams?.nationality;
  }

  if (queryParams?.ethnicity) {
    params.ethnicity = queryParams?.ethnicity;
  }

  if (queryParams?.languages) {
    params.languages = queryParams?.languages;
  }

  return await fetchApi({
    service: "user",
    endPoint: `/public-creators`,
    method: "GET",
    params,
  });
};

export default getPublicCreators;

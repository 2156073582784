import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "./styles.scss";
import photoDefault from "assets/images/roses-photo-default.png";
import HeartIconSolidPink from "assets/icons/heartIcons/heart-icon-solid-pink.svg";
import HeartIcoWhite from "assets/icons/heartIcons/heart-white.svg";
import { abbreviationForLargeNumbers } from "../../../utils/abbreviationForLargeNumbers";
import { formatTimeFromNowToDate } from "../../../utils/formatTimeFromNowToDate";
import Button from "../../Buttons/Button";
import ButtonIcon from "../../Buttons/ButtonIcon";
import LockIcon from "assets/icons/lock-icon-gray.svg";
import ImageCircleBorder from "../../ImageCircleBorder";
import RosesIconPink from "assets/icons/logos/logo-fill-pink.svg";
import { useMediaModal } from "hooks/useMediaModal";

interface MomentsCardsProps {
  className?: string;
  clickLikeMomentCallback: () => void;
  creatorMomentInfo: ICreatorMomentInfo;
  clickInMomentCallBack: (a: boolean) => void;
  unlockMomentCallback: () => void;
  momentsMessage: string;
  setMomentsMessage: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (a: any) => void;
  clickProfileHandlerCallback: (userId: string) => void;
}

export type ICreatorMomentInfo = {
  creatorName: string;
  type: "photo" | "video";
  momentIsAbleToShow: boolean;
  media: string;
  creatorAvatar: string;
  creatorId: string;
  postTime: Date;
  isPaidContent: boolean;
  unlockPrice: number;
};

const MomentsCards: React.FC<MomentsCardsProps> = (props) => {
  const {
    className,
    creatorMomentInfo,
    clickLikeMomentCallback,
    clickInMomentCallBack,
    unlockMomentCallback,
    momentsMessage,
    setMomentsMessage,
    sendMessage,
    clickProfileHandlerCallback,
  } = props;
  const { setOpenUseMediaModal, setOpenUseMediaMomentModal } = useMediaModal();
  const [isLiked, setIsLiked] = useState(false);
  const [likesNumber, setLikesNumber] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const videoCapturedRef = useRef<HTMLVideoElement>(null);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const useTextareaAutoResize = (
    ref: React.RefObject<HTMLTextAreaElement>,
    message: string
  ) => {
    useEffect(() => {
      const textarea = ref.current;
      if (textarea) {
        const minHeight = 50;
        textarea.style.height = `${minHeight}px`;
        const newHeight = Math.max(textarea.scrollHeight, minHeight);
        textarea.style.height = `${Math.min(newHeight, 100)}px`;
      }
    }, [message, ref]);
  };
  const chatTextareaRef = useRef<HTMLTextAreaElement | null>(null);

  useTextareaAutoResize(chatTextareaRef, momentsMessage);

  useEffect(() => {
    const videoElement = videoCapturedRef.current;

    if (
      creatorMomentInfo.type === "video" &&
      creatorMomentInfo.momentIsAbleToShow &&
      videoElement
    ) {
      videoElement.play().catch((error) => {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      });
    }

    return () => {
      if (videoElement) {
        videoElement.pause();
      }
    };
  }, [creatorMomentInfo]);

  useEffect(() => {
    if (videoCapturedRef.current) {
      if (isPaused) {
        videoCapturedRef.current.pause();
      } else {
        videoCapturedRef.current.play().catch((error) => {
          console.error("error", error);
        });
      }
    }
  }, [isPaused]);

  const closeModal = () => {
    setOpenUseMediaModal(false);
    setOpenUseMediaMomentModal(false);
  };

  const clickLikeMomentHandler = () => {
    const hasLiked = !isLiked;
    setIsLiked(hasLiked);
    setLikesNumber(() => (hasLiked ? likesNumber + 1 : likesNumber - 1));
    clickLikeMomentCallback();
  };

  const momentStop = () => {
    if (creatorMomentInfo.type === "video") {
      setIsPaused(true);
    }
    clickInMomentCallBack(true);
  };

  const momentContinue = () => {
    if (creatorMomentInfo.type === "video") {
      setIsPaused(false);
    }
    clickInMomentCallBack(false);
  };

  return (
    <section id="moments-cards" className={className ?? ""}>
      <div
        className="moments-cards__moment-wrapper"
        onMouseDown={(e) => {
          // e.preventDefault();
          momentStop();
        }}
        onMouseUp={(e) => {
          // e.preventDefault();
          momentContinue();
        }}
        onMouseLeave={() => momentContinue()}
        onTouchStart={(e) => {
          // e.preventDefault();
          momentStop();
        }}
        onTouchEnd={(e) => {
          // e.preventDefault();
          momentContinue();
        }}
      >
        {creatorMomentInfo.type === "photo" ||
        !creatorMomentInfo.momentIsAbleToShow ? (
          <img
            src={creatorMomentInfo.media}
            alt="media moment"
            className="moment-wrapper__media-photo"
          />
        ) : (
          <div className="moment-wrapper__media-video">
            <video
              key={creatorMomentInfo.media}
              ref={videoCapturedRef}
              autoPlay
              playsInline
              disablePictureInPicture
              controls={false}
              preload="auto"
              className="media-video__video"
              src={creatorMomentInfo.media}
            ></video>
          </div>
        )}
      </div>
      <header className="moments-details__creator-moments-details">
        <ImageCircleBorder
          centerImage={creatorMomentInfo.creatorAvatar}
          centerImageAlt={`${creatorMomentInfo.creatorName} avatar`}
          size={40}
          onClick={() =>
            clickProfileHandlerCallback(creatorMomentInfo.creatorId)
          }
        />

        <div className="creator-moments-details__names-container">
          <p className="no-margin profile-name">
            {creatorMomentInfo.creatorName}
            <span className="post-time">
              {formatTimeFromNowToDate(creatorMomentInfo.postTime)}
            </span>
          </p>
          <p className="no-margin profile-subtext">
            {creatorMomentInfo.creatorName}
          </p>
        </div>
        <span
          onClick={closeModal}
          className="icon icon-md icon-gray-light icon-close close-moment"
        />
      </header>
      {!creatorMomentInfo.isPaidContent ? (
        <div className="locked-info-container">
          <div className="locked-info-container__content">
            <img src={RosesIconPink} alt="lock icon" />
            <h3>{creatorMomentInfo.unlockPrice}</h3>
            <p>
              Step into the extraordinary — this reveal is yours and yours
              alone. Ready to see what’s been waiting for you?
            </p>
            <Button
              buttonStyle="quintenary"
              className="margin-top-16"
              onClick={unlockMomentCallback}
            >
              Unlock
            </Button>
          </div>
        </div>
      ) : (
        <>
          {isIOS ? (
            <footer className="moments-details__footer margin-vert-24 ">
              <div className="footer__interaction-elements margin-vert-16">
                <textarea
                  ref={chatTextareaRef}
                  value={momentsMessage}
                  onFocus={momentStop}
                  onBlur={momentContinue}
                  onChange={(e) => setMomentsMessage(e.target.value)}
                  placeholder="Type a message"
                  className={`textarea ${
                    momentsMessage ? "textarea-active" : ""
                  }`}
                />

                <ButtonIcon
                  onClick={clickLikeMomentHandler}
                  buttonStyle={
                    isLiked ? "transparentActive" : "transparent-dawn"
                  }
                  icon={isLiked ? "icon-heart-solid" : "icon-heart-outline"}
                />

                <ButtonIcon
                  buttonStyle="primary"
                  icon="icon-send-1"
                  className={`paper-airplane-button margin-bottom-16`}
                  onClick={sendMessage}
                />
              </div>
            </footer>
          ) : (
            <footer className="moments-details__footer ">
              <div className="footer__interaction-elements margin-vert-16">
                <textarea
                  ref={chatTextareaRef}
                  value={momentsMessage}
                  onFocus={momentStop}
                  onBlur={momentContinue}
                  onChange={(e) => setMomentsMessage(e.target.value)}
                  placeholder="Type a message"
                  className={`textarea ${
                    momentsMessage ? "textarea-active" : ""
                  }`}
                />
                <ButtonIcon
                  onClick={clickLikeMomentHandler}
                  buttonStyle={
                    isLiked ? "transparentActive" : "transparent-dawn"
                  }
                  icon={isLiked ? "icon-heart-solid" : "icon-heart-outline"}
                />
                <ButtonIcon
                  buttonStyle="primary"
                  icon="icon-send-1"
                  className={`paper-airplane-button margin-bottom-16`}
                  onClick={sendMessage}
                />
              </div>
            </footer>
          )}
        </>
      )}
    </section>
  );
};

export default MomentsCards;

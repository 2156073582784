import Dialog from "components/Modals/Dialog";
import React, { createContext, useState, ReactNode, useContext } from "react";
import { IButtonStyles } from "types/buttonStyles";

interface DialogProps {
  openUseDialog: boolean;
  setOpenDialog: (open: boolean) => void;
  setDialogContent: (content: DialogContent) => void;
}

type DialogContent = {
  iconHeader: string;
  title: string;
  text: string;
  buttons: Buttons[];
  iconColor: string;
};

type Buttons = {
  text: string;
  buttonStyle: IButtonStyles;
  click: (a: any) => void;
};

const DialogContext = createContext<DialogProps | undefined>(undefined);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};

export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [openUseDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState<DialogContent>(
    {} as DialogContent
  );

  return (
    <DialogContext.Provider
      value={{ openUseDialog, setOpenDialog, setDialogContent }}
    >
      {children}
      {openUseDialog && (
        <div className="modal-overlay">
          <div className="modal-content">
            <Dialog
              iconColor={dialogContent.iconColor}
              isOpen={openUseDialog}
              title={dialogContent.title}
              buttons={dialogContent.buttons}
              iconHeader={dialogContent.iconHeader}
              text={dialogContent.text}
            />
          </div>
        </div>
      )}
    </DialogContext.Provider>
  );
};

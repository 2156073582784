import React, { forwardRef, ReactNode } from "react";
import "./styles.scss";
import ArrowRightGray from "assets/icons/navigationIcons/bx-chevron-right.svg";

interface ListItemLinkProps {
  className?: string;
  highlightText: string;
  simpleText?: string;
  imageLeft?: string;
  isImageCircle?: boolean;
  hasArrowRight?: boolean;
  hasSeparator?: boolean;
  rightNodeContent?: ReactNode;
  iconFill?: boolean;
  isBackTransparent?: boolean;
  isBigIcon?: boolean;
  onClick?: (a: any) => void;
  imageCircleMidnight?: boolean;
  hasCloseIconRight?: boolean;
  closeIconClick?: () => void;
}

const ListItemLink = forwardRef<HTMLDivElement, ListItemLinkProps>(
  (props, ref) => {
    const {
      className,
      highlightText,
      simpleText,
      imageLeft,
      isImageCircle,
      hasArrowRight,
      hasSeparator,
      rightNodeContent,
      iconFill,
      isBackTransparent,
      isBigIcon,
      onClick,
      hasCloseIconRight,
      imageCircleMidnight,
      closeIconClick,
    } = props;

    const listItemClass = () => {
      if (imageLeft && !rightNodeContent) {
        return "column-for-image";
      } else if (imageLeft && rightNodeContent && hasArrowRight) {
        return "column-for-image-and-node";
      } else if (imageLeft && rightNodeContent && !hasArrowRight) {
        return "column-for-image-and-node-without-arrow";
      } else if (!imageLeft && rightNodeContent) {
        return "column-for-node";
      } else {
        return "";
      }
    };

    const listItemClasses = [
      "list-item-link",
      className ?? "",
      onClick ? "cursor-pointer" : "",
      listItemClass(),
    ]
      .filter(Boolean)
      .join(" ")
      .trim();

    return (
      <div
        id="list-item-link"
        className={listItemClasses}
        onClick={onClick}
        ref={ref}
      >
        {imageLeft && (
          <div
            className={`item__image-left-container 
              ${isImageCircle ? "image-circle" : ""}
              ${isBackTransparent ? "icon-transparent" : ""}
            `}
          >
            <img
              src={imageLeft}
              alt="left icon"
              className={`
              item__left-icon 
              ${iconFill ? "icon-fill" : ""}
              ${isBigIcon ? "icon-big" : ""}
              `}
            />
          </div>
        )}

        <div className="item__main-container">
          <p
            className="main-container__highlight-text no-margin"
            data-highlight
          >
            {highlightText}
          </p>
          <p className="main-container__simple-text no-margin" data-simple>
            {simpleText}
          </p>
        </div>
        {rightNodeContent && (
          <section className="list-item-link__right-node">
            {rightNodeContent}
          </section>
        )}

        {hasArrowRight ? (
          <span className="icon icon-chevron-right icon-gray icon-md arrow-right" />
        ) : hasCloseIconRight && closeIconClick ? (
          <span
            className="icon icon-peach icon-md close-right icon-close"
            onClick={closeIconClick}
          />
        ) : (
          false
        )}

        {hasSeparator && <div className="separator" />}
      </div>
    );
  }
);

export default ListItemLink;

import "./styles.scss";
import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";

import { useUserInfo } from "../../hooks/userInfo";
import DeleteMembershipUserApi from "../../api/deleteMembershipApplicationAdmin";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";

const UserSettingsDeleteAccountPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { component, setComponent } = useLoginSignUpNavigation();

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
  } = useDeskNavigation();

  const phone = userInfo.user_phone.replace("+", "");
  // const renderApproveButton = () => {
  //   if (
  //     phoneToUse === "5511967700452" ||
  //     phoneToUse === "14074464664" ||
  //     phoneToUse === "13053704780" ||
  //     phoneToUse === "5545999640276" ||
  //     phoneToUse === "5511914013654" ||
  //     phoneToUse === "5519984484218"
  //   ) {
  //     return (
  //       <>
  //         <Button buttonStyle="primary" onClick={approveRegisterSubmit}>
  //           Approve
  //         </Button>
  //         <Button buttonStyle="secondary" onClick={declineHandler}>
  //           Decline
  //         </Button>
  //       </>
  //     );
  //   }
  // };

  const deleteUser = async () => {};

  return (
    <div className="user-settings">
      <PageTitle title={"Language"} subtitle={"Account Security"} />

      <Button
        buttonStyle="quaternary"
        onClick={deleteUser}
        children={"Delete Account"}
      />
    </div>
  );
};

export default UserSettingsDeleteAccountPage;

import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useWebSocket } from "../../contexts/webSocketContext";
import { useChat } from "../../contexts/openedChatContext";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import {
  Chat,
  IEventIsOnline,
  INewMessage,
  IReadMessage,
  Message,
} from "../../types/chatInfo";
import PageTitle from "components/PageTitles";
import ChatTextarea from "components/TextAreas/ChatTextArea";
import SendAudioChat from "components/SendAudioChat";
import Loading from "components/Loading";
import PackMessageModal from "components/Chat/ChatModals/PackMessageModal";
import VideoMessageModal from "components/Chat/ChatModals/VideoMessageModal";
import ImageMessageModal from "components/Chat/ChatModals/ImageMessageModal";
import PreviewPackModal from "components/Chat/ChatModals/PreviewPackModal";
import PreviewMediaModal from "components/Chat/ChatModals/PreviewMediaModal";
import SendRosesModal from "components/Chat/ChatModals/SendRosesModal";
import ChatButton from "components/Buttons/ChatButton";
import ChatTyping from "components/Chat/ChatTyping";
import ChatMessageNew from "components/Chat/ChatMessage/ChatMessageNew";
import CreateChatContact from "../../api/postCreateChatContact";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import postUploadMedia from "../../api/chat/postUploadMedia";
import postSendRoses from "../../api/rosesTransactions/postSendRoses";
import { useProducerDetails } from "../../contexts/producerDetailsContext";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { usePublishContent } from "../../hooks/usePublishContent";
import { useModal } from "../../hooks/useModal";
import { useCanPurchasedMedia } from "../../hooks/useCanPurchasedMedia";
import { useUnlockContent } from "../../hooks/useUnlockContent";
import { hourFormat } from "../../utils/dateFormat";
import AddMediaPage from "../AddMediaPage";
import { useAvailableRoses } from "../../contexts/availableRosesContext";
import { useMediaContext } from "../../contexts/mediaContext";
import phoneticAlphabetMock from "../../mocks/phoneticAlphabetMock";
import { useToast } from "hooks/useToast";

const ChatPage: React.FC = () => {
  const {
    addChatListener,
    removeListener,
    sendMessage,
    getMessages,
    getChat,
    updateMessageStatus,
    updateIsTyping,
    sendTransferMessage,
  } = useWebSocket();

  const {
    setSecondaryScreen,
    setTertiaryScreen,
    componentFeed,
    setFeedScreen,
    params,
    componentTertiary,
  } = useDeskNavigation();

  const { creatorContent } = usePublishContent();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);
  const { userInfo } = useUserInfo();
  const { activeChatId, setActiveChatId, setIsFirstMessage } = useChat();
  const { producerDetails, updateProducerDetails } = useProducerDetails();
  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();
  const { canPurchase } = useCanPurchasedMedia();
  const { unlockContentSubmit } = useUnlockContent();
  const { unlockedMedias } = useMediaContext();
  const { showToast } = useToast();
  const [chatInfo, setChatInfo] = useState<Chat>();
  const [chatLocked, setChatLocked] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasAMomentToSee, setHasAMomentToSee] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isContactOnline, setIsContactOnline] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isProducer, setIsProducer] = useState<boolean>(false);
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [unreadMessageIds, setUnreadMessageIds] = useState<string[]>([]);
  const [readMessagesIds, setReadMessagesIds] = useState<string[]>([]);

  const inputChatRef = useRef<HTMLInputElement>(null);
  const messagesContainer = useRef<HTMLDivElement | null>(null);
  // const chatIdRef = useRef(params.chatId);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  let priceContent = 0;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const unreadMessagesTotal =
    Number(params.unreadCount) > 0 ? Number(params.unreadCount) : 0;

  // useEffect(() => {
  //   chatIdRef.current = params.chatId;
  // }, [params.chatId]);

  useEffect(() => {
    const isSystemMessage = messages.at(-1)?.isSystemMessage ?? false;
    const hasMoreThatOneMessage = messages.length < 2;

    setIsFirstMessage(isSystemMessage && hasMoreThatOneMessage);
  }, [messages, setIsFirstMessage]);

  useEffect(() => {
    getChat(params.chatId, (e) => {
      setChatInfo(e);
    });
  }, []);

  // Execute when send a Media/Photo/Video
  useEffect(() => {
    if (!isFirstRequest) {
      handleMessageDefault();
    }
  }, [creatorContent, unlockedMedias]);

  const backPageHandler = () => {
    //TODO
    //need clear the params
    setSecondaryScreen("");
    setTertiaryScreen("");
    setActiveChatId(null);
    setIsChatPageOpen(false);
    removeAllListeners();
    setUnreadMessageIds([]);
    if (componentFeed) {
      setFeedScreen("/feed", params);
    }
  };

  const openMediaLibrary = () => {
    if (componentFeed) {
      params["feedWasOpen"] = "true";
    }
    setFeedScreen("", params);
    setTertiaryScreen("/media-library", params);
  };

  useEffect(() => {
    const updateIsTyping = (update_isTyping: any) => {
      if (update_isTyping.eventType === "USER_TYPING") {
        const { isTyping } = update_isTyping.payload;
        setIsTyping(isTyping);
        setTimeout(scrollToBottom, 500);
      }
    };

    addChatListener(updateIsTyping);

    return () => {
      removeListener("USER_TYPING", updateIsTyping);
    };
  }, [addChatListener, removeListener]);

  useEffect(() => {
    const getChatInfo = async () => {
      removeAllListeners();
      addChatListener(handleNewMessage);
      addChatListener(handleSendTransferMessage);
      addChatListener(isContactOnlineListener);
      addChatListener(readMessageListener);
      getChat(params.chatId, (e) => {
        setChatInfo(e);
        handleFirstRequestMessages(false, e.messages[0].messageId);
      });
      setIsFirstRequest(false);
    };

    const isChatLocked = params.isLocked === "false" ? false : true;
    setChatLocked(!isChatLocked);

    const isProducer = params.isProducer === "CREATOR" ? true : false;
    setIsProducer(isProducer);

    if (!isChatLocked && params.userId) {
      handleCreateChatContact();
    }

    producerDetail();
    getChatInfo();

    // const scrollTimeout = setTimeout(scrollToBottom, 500);
    return () => {
      removeAllListeners();
      // clearTimeout(scrollTimeout);
    };
  }, [params.chatId, params.isLocked, params.userId]);

  const removeAllListeners = () => {
    removeListener("NEW_MESSAGE", handleNewMessage);
    removeListener("NEW_MESSAGE", handleSendTransferMessage);
    removeListener("CONTACT_ONLINE", isContactOnlineListener);
    removeListener("READ_MESSAGE", readMessageListener);
  };

  useEffect(() => {
    const isChatLocked = params.isLocked === "false" ? false : true;
    setChatLocked(!isChatLocked);

    if (!isChatLocked && params.userId) {
      handleCreateChatContact();
    }
  }, [params.chatId, params.isLocked, params.userId]);

  useEffect(() => {
    if (unreadMessageIds.length > 0) {
      const messagesIds = new Set(unreadMessageIds);

      markMessagesAsRead(Array.from(messagesIds));
    }
  }, [unreadMessageIds, updateMessageStatus]);

  const scrollToElement = (messageId: string) => {
    if (messagesContainer.current) {
      const element = document.getElementById(messageId);
      if (element) {
        const containerTop = messagesContainer.current.offsetTop;
        const elementTop = element.offsetTop;
        messagesContainer.current.scrollTop = elementTop - containerTop;
      }
    }
  };

  const scrollToBottom = () => {
    if (messagesContainer.current) {
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight;
    }
  };

  const handleFirstRequestMessages = async (
    skip: boolean = true,
    cursor?: string
  ) => {
    const chatId = params.chatId;

    const fetchParams = [
      {
        chatId,
        take: 15,
        cursor,
        skip,
      },
      {
        chatId,
        take: -15,
        cursor,
        skip: true,
      },
    ];

    // Use this cursor for test 15/01/2025
    // cursor: "9c8c084d-743d-4bba-8416-d0909cbd860d",

    try {
      // Realizar as duas chamadas em paralelo
      const [messagesBackward, messagesForward] = await Promise.all(
        fetchParams.map(
          (params) =>
            new Promise<Message[]>((resolve) => getMessages(params, resolve))
        )
      );

      // Combinar as respostas
      const allMessages = [...messagesBackward, ...messagesForward];

      // Filtrar mensagens do chat atual
      const filteredMessages = allMessages.filter(
        (msg) => msg.chatId === chatId
      );

      // Atualizar o estado das mensagens
      setMessages([...filteredMessages]);

      // Identificar mensagens não lidas
      const unreadMessageIds = filteredMessages
        .filter((msg) => !msg.isRead && msg.userId === params.userId)
        .map((msg) => msg.messageId);

      // Checar se a última mensagem é do usuário
      const lastMessageIsRight =
        userInfo.user_id ===
        filteredMessages[filteredMessages.length - 1]?.userId;

      if (unreadMessageIds.length > 0 && !lastMessageIsRight) {
        // Marcar mensagens não lidas e rolar até a primeira
        unreadMessageIds.forEach(addUnreadMessage);
        scrollToElement(unreadMessageIds[0]);
      } else {
        // Caso contrário, rolar para o final
        setTimeout(scrollToBottom, 0);
      }
    } catch (error) {
      console.error("Erro ao buscar mensagens:", error);
    }
  };

  const handleMessageDefault = (
    isUnblockingMedia: boolean = false,
    idUnlockMedia?: string
  ) => {
    const chatId = params.chatId;
    const lastMessageId = messages.at(-1)?.messageId;

    const messageParams = {
      chatId,
      take: isUnblockingMedia ? 15 : -15,
      cursor: isUnblockingMedia ? idUnlockMedia : lastMessageId,
    };

    try {
      getMessages(messageParams, (newMessages: Message[]) => {
        const filteredMessages = newMessages.filter(
          (msg) => msg.chatId === params.chatId
        );

        isUnblockingMedia
          ? setMessages([...newMessages])
          : setMessages((prevMessages) => [
              ...prevMessages,
              ...filteredMessages,
            ]);

        setLoading(false);
      });
    } catch (error) {
      console.error("Erro");
    } finally {
      setMessages((prevMessages) => [...prevMessages]);
      idUnlockMedia
        ? setTimeout(() => scrollToElement(idUnlockMedia), 1000)
        : setTimeout(scrollToBottom, 500);
    }
  };

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const chatMessages = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = chatMessages;

    const isAtTop = scrollTop === 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    const loadMessages = async (
      cursor: string | undefined,
      direction: "top" | "bottom"
    ) => {
      if (loading || !cursor || !messages.length) return;

      setLoading(true);

      const messageParams = {
        chatId: params.chatId,
        take: direction === "top" ? 15 : -15,
        cursor,
      };

      getMessages(messageParams, (newMessages: Message[]) => {
        const filteredMessages = newMessages.filter(
          (msg) => msg.chatId === params.chatId
        );

        setMessages((prevMessages) =>
          direction === "top"
            ? [...filteredMessages, ...prevMessages]
            : [...prevMessages, ...filteredMessages]
        );

        setLoading(false);

        if (direction === "top") {
          const previousHeight = scrollHeight;
          requestAnimationFrame(() => {
            chatMessages.scrollTop = chatMessages.scrollHeight - previousHeight;
          });
        }
      });
    };

    // Carrega mensagens conforme a posição do scroll
    if (isAtTop) {
      const firstMessageId = messages[0]?.messageId;
      loadMessages(firstMessageId, "top");
    } else if (isAtBottom) {
      const lastMessageId = messages.at(-1)?.messageId;
      loadMessages(lastMessageId, "bottom");
    }
  };

  const isContactOnlineListener = (event: IEventIsOnline) => {
    if (
      event.eventType === "USER_STATUS" &&
      event.payload.userId === params.userId
    ) {
      setIsContactOnline(event.payload.isOnline);
    }
  };

  const readMessageListener = (event: IReadMessage) => {
    if (params.userId) {
      if (event.eventType === "MESSAGE_READ") {
        setReadMessagesIds((prev) => [...prev, event.payload.messageId]);
      }
    }
  };

  const handleNewMessage = (event: INewMessage) => {
    if (event.eventType === "NEW_MESSAGE") {
      // if (
      //   params.chatId &&
      //   event.payload.chatId === params.chatId &&
      // ) {
      // markMessagesAsRead([event.payload.messageId]);
      // }

      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (msg) => msg.messageId === event.payload.messageId
        );
        if (!isDuplicate) {
          return [...prevMessages, event.payload];
        }
        return prevMessages;
      });

      // TODO: remove in future
      setIsTyping(false);

      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  };

  const markMessagesAsRead = (messageIds: string[]) => {
    updateMessageStatus(messageIds, (response) => {
      setMessages((prev) =>
        prev.map((message) =>
          messageIds.includes(message.messageId)
            ? { ...message, isRead: true }
            : message
        )
      );
    });
  };

  const showIsTyping = (typing: boolean) => {
    updateIsTyping(
      { chatId: params.chatId, isTyping: typing },
      (response) => {}
    );
  };

  function getTwoRandomWords(): [string, string] {
    const shuffled = [...phoneticAlphabetMock].sort(() => 0.5 - Math.random());
    return [shuffled[0], shuffled[1]];
  }

  const addUnreadMessage = (newMessageId: string) => {
    setUnreadMessageIds((prevIds) => [...prevIds, newMessageId]);
  };

  const handleSendMessage = async (text: string) => {
    if (chatLocked) {
      const canUnlock = await handleUnlockChatContact();

      if (!canUnlock) return;
    }

    if (text.toLocaleLowerCase() === "mvfm") {
      const [word1, word2] = getTwoRandomWords();

      for (let index = 1; index <= 30; index++) {
        let word = index <= 15 ? word1 : word2;
        let number = index <= 15 ? index : index - 15;
        let content = `${word} - ${number}`;

        await new Promise<void>((resolve, reject) => {
          const chatId = params.chatId;
          sendMessage({ chatId, content: content }, (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
            resolve();
          });
        });

        setTimeout(scrollToBottom, 0);
      }
    } else {
      try {
        // TODO: remove in future
        if (producerDetails.profile.isAI || producerDetails.isVip) {
          setIsTyping(true);
          await new Promise<void>((resolve, reject) => {
            const chatId = params.chatId;
            sendMessage({ chatId, content: text }, (message) => {
              setMessages((prevMessages) => [...prevMessages, message]);
              resolve();
            });
          });
        } else {
          await new Promise<void>((resolve, reject) => {
            const chatId = params.chatId;
            sendMessage({ chatId, content: text }, (message) => {
              setMessages((prevMessages) => [...prevMessages, message]);
              resolve();
            });
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => {
          scrollToBottom();
        }, 0);
        setLoading(false);
        inputChatRef.current?.focus();
      }
    }
  };

  const handleUnlockChatContact = async () => {
    const canUnlock = await handlePurchasedChat();

    if (canUnlock) {
      await handleCreateChatContact();
    }

    return canUnlock;
  };

  const handlePurchasedChat = async () => {
    const resCanPurchase = await canPurchase(priceContent);
    if (resCanPurchase) {
      showToast({
        type: "success",
        title: "Purchased Done",
        description: "Congratulations",
      });
    } else {
      showToast({
        type: "info",
        title: "Unable to complete your purchase",
        description: "You don't have roses enough",
      });
    }

    return resCanPurchase;
  };

  const handleCreateChatContact = async () => {
    setChatLocked(false);

    const chatInfo = await CreateChatContact().postCreateChatContact(
      params.userId,
      userInfo.access_token
    );

    setIsContactOnline(chatInfo?.res?.chatParticipants[0]?.user?.isOnline);
  };

  const producerDetail = async () => {
    if (userInfo.access_token && params.userId) {
      const response = await getPublicDetails(
        userInfo.access_token,
        params.userId
      );

      switch (response?.status) {
        case 200:
          updateProducerDetails(response.res);
          break;
        default:
          // setPrincipalScreen("");
          break;
      }
    }
  };

  const prepareSendFiles = (files: File[]) => {
    return files.map((file) => {
      return new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          } else {
            reject(new Error("Failed to read file"));
          }
        };
        reader.onerror = () => reject(new Error("File reading error"));

        reader.readAsDataURL(file);
      });
    });
  };

  const uploadFileCallBack = async (
    files: File[],
    content: string,
    thumbnail?: string
  ) => {
    setModalContent(
      <div className="loading-send-file">
        <Loading />
      </div>
    );
    const chatId = params.chatId;

    const prepareData = {
      chatId,
      content,
      price: 55,
      paidContent: true,
      files: files,
    };
    const response = await postUploadMedia(userInfo.access_token, prepareData);

    switch (response.status) {
      case 201:
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
        break;

      default:
        break;
    }

    setOpenUseModal(false);
    setModalContent(null);
    handleMessageDefault();
  };

  const contentModalMedia = (files: File[]) => {
    const imagePromises = prepareSendFiles(files);
    const isVideoType = files[0].type.includes("video/") ? "video" : "image";

    Promise.all(imagePromises).then((images) => {
      setModalContent(
        <PreviewMediaModal
          files={files}
          images={images}
          fileType={isVideoType}
          uploadFileCallBack={uploadFileCallBack}
        />
      );
    });
    setOpenUseModal(true);
  };

  const contentModalPreviewPack = (files: File[]) => {
    let currentFiles = [...files];
    const removeFile = (indexToRemove: number) => {
      currentFiles = currentFiles.filter((_, index) => index !== indexToRemove);
      updateModalContent();
      if (!currentFiles.length) {
        setOpenUseModal(false);
      }
    };

    const updateModalContent = () => {
      const packPromises = prepareSendFiles(currentFiles);

      Promise.all(packPromises)
        .then((packSources) => {
          setModalContent(
            <PreviewPackModal
              files={files}
              packSources={packSources}
              removeFile={removeFile}
              uploadFileCallBack={uploadFileCallBack}
            />
          );
        })
        .catch((error) => {
          console.error("Error loading videos:", error);
        });
    };
    updateModalContent();
    setOpenUseModal(true);
  };

  const imageMessageModalHandler = (url: string) => {
    setModalContent(<ImageMessageModal url={url} />);

    setOpenUseModal(true);
  };

  const videoMessageModalHandler = (url: string) => {
    setModalContent(<VideoMessageModal url={url} />);

    setOpenUseModal(true);
  };

  const packMessageModalHandler = (
    urlsObj: {
      url: string;
      fileType: string;
      mimetype: string;
    }[]
  ) => {
    setModalContent(<PackMessageModal urlsObj={urlsObj} />);

    setOpenUseModal(true);
  };

  const sendRosesRequest = async (roses: number, message: string) => {
    await postSendRoses(userInfo.access_token, params.userId, roses, message);
    setOpenUseModal(false);
    handleFirstRequestMessages();
  };

  const sendRosesModalHandler = () => {
    setModalContent(
      <SendRosesModal
        title="Send Roses"
        description={
          <>
            Capture Your Love's Heart: <br />
            Send <b>Roses</b> Now!
          </>
        }
        showLogo
        showSendMessage
        maxValue={availableRoses}
        onClose={() => setOpenUseModal(false)}
        rosesMessageCallback={(amount, message) =>
          handleSendTransferMessage({
            chatId: params.chatId,
            receiverId: params.userId,
            content: message,
            amount: amount,
          })
        }
        className="padding-16"
      />
    );
    setOpenUseModal(true);
  };

  const handleSendTransferMessage = (messageParams: {
    chatId: string;
    receiverId: string;
    content: string;
    amount: number;
  }) => {
    sendTransferMessage(messageParams, () => {
      setOpenUseModal(false);
      updateSubtractAvailableRoses(messageParams.amount);
      handleMessageDefault();
    });
  };

  const contentModalOpenCamera = () => {
    setModalContent(
      <AddMediaPage
        previousPage="chat"
        chatIdToSendContent={params.chatId}
        onUploadComplete={scrollToBottom}
      />
    );

    setOpenUseModal(true);
  };

  const avatarClick = () => {
    setFeedScreen("/feed", {
      userId: params.userId,
      chatId: params.chatId,
      isProducer: params.isProducer,
    });
  };

  const sendAudioToServer = async (audioBlob: Blob | null) => {
    if (!audioBlob) return;

    const audio = new File([audioBlob], `${userInfo.user_id}`, {
      type: audioBlob.type,
    });

    const chatId = params.chatId;

    const prepareData = {
      chatId,
      content: "",
      price: 55,
      paidContent: true,
      files: [audio],
    };

    await postUploadMedia(userInfo.access_token, prepareData);

    handleMessageDefault();
  };

  const [chatTextFocus, setChatTextFocus] = useState(false);
  const [chatTextHasContent, setChatTextHasContent] = useState(false);

  const handleMessageChange = (newMessage: string) => {
    setChatTextHasContent(newMessage ? true : false);
    if (newMessage.length > 0) {
      showIsTyping(true);
    } else {
      showIsTyping(false);
    }
  };

  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  const options = {
    root: messagesContainer.current,
    rootMargin: "0px",
    threshold: 1.0,
  };

  useEffect(() => {
    if (itemsRef.current.length === 0) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const beholder = entry.target;

          const messageId = beholder.id;
          const message = messages.find((msg) => msg.messageId === messageId);
          if (
            message &&
            !message.isRead &&
            activeChatId &&
            userInfo.user_id !== message.userId
          ) {
            markMessagesAsRead([messageId]);
          }

          observer.unobserve(entry.target);
        }
      });
    }, options);

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => observer.disconnect();
  }, [messages, setMessages]);

  return (
    <div className="chat-container-page">
      <PageTitle
        title={producerDetails?.displayname}
        // subtitle={isContactOnline ? "Online" : "Offline"}
        subtitle={"Online"}
        avatarUrl={producerDetails?.profile?.photos?.[0]?.url}
        chatLocked={chatLocked}
        isChat
        isProducer={isProducer}
        isOnLine={isContactOnline}
        hasCloseIcon={windowSize >= 768}
        hasAMomentToSee={hasAMomentToSee}
        className="margin-hor-16"
        avatarClick={avatarClick}
        mediaButtonClick={openMediaLibrary}
        onBackClick={backPageHandler}
      />

      {/* {chatLocked ? (
        <ChatLocked onClickButton={handleUnlockChatContact} />
      ) : ( */}
      <>
        <div
          ref={messagesContainer}
          id="chat-messages-container"
          className="chat-messages-container messages-container"
          onScroll={handleScroll}
        >
          {
            // NOT DELETE it will use in future
            //messages.length <= 15 && (
            // <>
            //   <CardExplanatory
            //     title="Disappearing Messages"
            //     info={`You turned on disappearing messages. All new messages
            // 				 will disappear from this chat 24 hours after they’re
            // 				 sent.`}
            //     linkRoute=""
            //     linkText="Tap to change."
            //     className="margin-bottom-16"
            //   />
            //   <CardExplanatory
            //     title="Encrypted Messages"
            //     info={`Messages and calls are end-to-end encrypted. No one
            // 				outside of this chat, not even Roses, can read or listen
            // 				to them.`}
            //     linkRoute=""
            //     linkText="Tap to learn more."
            //     className="margin-bottom-16"
            //   />
            // </>
            //)
          }

          {messages
            .filter((msg) => msg.chatId === params.chatId)
            ?.map((message, index) => {
              return (
                <ChatMessageNew
                  key={`${message.messageId}`}
                  ref={(el) => {
                    if (el) {
                      itemsRef.current[index] = el;
                    }
                  }}
                  id={message.messageId}
                  side={userInfo.user_id === message.userId ? "right" : "left"}
                  message={message.content}
                  time={hourFormat(message?.createdAt)}
                  fileSended={message.files}
                  messageSettings={message.messageSettings}
                  messageType={message.messageType}
                  transfer={message?.transfer}
                  user={userInfo.display_name}
                  clickOnImage={imageMessageModalHandler}
                  clickOnVideo={videoMessageModalHandler}
                  clickOnPack={packMessageModalHandler}
                  unlockFileCallback={() => {
                    unlockContentSubmit(
                      message.messageId,
                      message.messageSettings.price,
                      "chat",
                      () => handleMessageDefault(true, message.messageId)
                    );
                  }}
                  isMessageRead={
                    message.isRead ||
                    readMessagesIds.includes(message.messageId)
                  }
                  isSystemMessage={message.isSystemMessage}
                />
              );
            })}
          {isTyping && <ChatTyping />}
        </div>

        <div className="actions-input-chat">
          {isRecording ? (
            <SendAudioChat
              sendAudioCallback={sendAudioToServer}
              startRecordingCallback={setIsRecording}
            />
          ) : (
            <ChatTextarea
              onSendMessage={handleSendMessage}
              isButtonSendDisabled={isLoading}
              onFocus={() => setChatTextFocus(true)}
              onBlur={() => setChatTextFocus(false)}
              onMessageChange={handleMessageChange}
              className="chat-page__message"
            >
              <ChatButton
                sendPack={contentModalPreviewPack}
                sendAudio={() => {
                  setIsRecording(true);
                }}
                sendMedia={contentModalMedia}
                sendRoses={sendRosesModalHandler}
                openCamera={contentModalOpenCamera}
                isButtonsDisabled={isLoading}
                parentFocus={chatTextFocus}
                parentHasContent={chatTextHasContent}
              />
            </ChatTextarea>
          )}
        </div>
      </>
    </div>
  );
};

export default ChatPage;

import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";
import Progressbar from "components/Progressbar";
import NewProfile from "components/Cards/NewProfile";
import MediaModalCard from "./MediaModalCard";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useUserInfo } from "hooks/userInfo";
import { useMediaModal } from "hooks/useMediaModal";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import getPublicDetails from "api/publicDetails/getProfileDetails";
import { IUserPublicInfo } from "types/userInfo";
import photoDefault from "assets/icons/profile-icon-gray.svg";
import { PhotosProfile } from "types/catalog";
import postAddToFavorite from "api/favorite/postAddToFavorite";
import { FavoritesListContext } from "contexts/favoritesListTrigger";
import deleteRemoveFromFavorites from "api/favorite/deleteRemoveFromFavorites";
import { ToggleChatOpenContext } from "contexts/toggleChatOpen";
import CreateChatContact from "api/postCreateChatContact";
import { useWebSocket } from "contexts/webSocketContext";
import { Chat } from "types/chatInfo";
import { useChat } from "contexts/openedChatContext";
import Loading from "components/Loading";
import { useToast } from "hooks/useToast";

interface MediaModalProps {
  className?: string;
  medias: ICreatorMediaInfo[];
  creatorId: string;
  unlockMediaCallback: (media: ICreatorMediaInfo) => void;
}

export type ICreatorMediaInfo = {
  fileDuration: number;
  type: "photo" | "video";
  media: string;
  isPaidContent: boolean;
  unlockPrice: number;
  hasPaid: boolean;
};

const MediaModal: React.FC<MediaModalProps> = (props) => {
  const { className, medias, creatorId, unlockMediaCallback } = props;
  const { windowSize } = useWindowWidthSize();
  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);
  const { setFavoritesListTrigger } = useContext(FavoritesListContext);
  const { setPrincipalScreen, setFeedScreen } = useDeskNavigation();
  const { setOpenUseMediaModal } = useMediaModal();
  const { userInfo } = useUserInfo();
  const { getChats, sendMessage } = useWebSocket();
  const { setActiveChatId } = useChat();
  const [mediaIndexCurrent, setMediaIndexCurrent] = useState(0);
  const [isPausedMedia, setIsPausedMedia] = useState(false);
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [isLoadingMedias, setIsLoadingMedias] = useState(true);
  const [photoProducer, setPhotoProducer] = useState<PhotosProfile[] | []>([]);
  const [profileAvatar, setProfileAvatar] = useState("");
  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const startX = useRef<number | null>(null);

  const [creatorMediaInfo, setCreatorMediaInfo] = useState(
    {} as IUserPublicInfo
  );
  const [mediaAbleToShow, setMediaAbleToShow] = useState(false);
  const { showToast } = useToast();
  const [isLoadingMoments, setIsLoadingMoments] = useState(true);
  const [clickTime, setClickTime] = useState(0);
  const [activeChatIdState, setActiveChatIdState] = useState("");
  const mediaModalRefContent = useRef<HTMLElement>(null);
  const mediaModalRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setIsLoadingMoments(true);
    Promise.all([creatorDetails(creatorId)]).finally(() => {
      setIsLoadingMoments(false);
      setIsLoadingMedias(false);
    });
  }, [creatorId, userInfo.access_token]);

  useEffect(() => {
    const media = medias[mediaIndexCurrent];
    if (media.isPaidContent) {
      if (medias[mediaIndexCurrent].hasPaid) {
        setMediaAbleToShow(true);
      } else {
        setMediaAbleToShow(false);
      }
    } else {
      setMediaAbleToShow(true);
    }
    setIsLoadingMedias(false);
  }, [mediaIndexCurrent]);

  useEffect(() => {
    // getListChats();
    const handleClick = (event: MouseEvent) => {
      if (
        mediaModalRef.current &&
        !mediaModalRef.current.contains(event.target as Node)
      ) {
        closeModalHandler();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const closeModalHandler = () => {
    setOpenUseMediaModal(false);
  };

  // const getListChats = useCallback(async () => {
  //   getChats({}, (res: Chat[]) => {
  //     const openedChatsIds: string[] | [] = res?.map(
  //       (chat: Chat) => chat?.chatParticipants[0]?.user?.userId
  //     );
  //     setOpenedChatUserIds(openedChatsIds);
  //     setChats(res);
  //   });
  // }, [getChats]);

  const conveyorActionsHandler = (completed: boolean) => {
    setIsLoadingMedias(true);

    if (completed) {
      if (mediaIndexCurrent + 1 < medias.length) {
        setMediaIndexCurrent((prev) => prev + 1);
      } else if (mediaIndexCurrent + 1 === medias.length) {
        closeModalHandler();
      }
    }

    setIsLoadingMedias(false);
  };

  const playPauseMedia = (event: boolean) => {
    setIsPausedMedia(event);
  };

  const clickUserFeed = (creatorId: string) => {
    if (creatorId === userInfo.user_id) return;

    setOpenUseMediaModal(false);
    setFeedScreen("/feed", { userId: creatorId });
  };

  const creatorDetails = async (userId: string) => {
    const request = await getPublicDetails(userInfo.access_token, userId);

    setCreatorMediaInfo(request.res);
    setIsProfileFavorite(request.res.isFavorite);
    setPhotoProducer(request.res.profile?.photos);

    setProfileAvatar(
      request.res.profile?.photos[0].thumbnailUrl || photoDefault
    );
  };

  const clickLikeHandler = async () => {
    if (creatorMediaInfo?.userId === userInfo.user_id) return;
    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(
        userInfo.access_token,
        creatorMediaInfo?.userId
      );
      switch (response.status) {
        case 201:
        case 204:
          setFavoritesListTrigger((prev) => !prev);
          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });
          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        creatorMediaInfo?.userId
      );

      switch (response.status) {
        case 204:
        case null:
          setFavoritesListTrigger((prev) => !prev);

          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again 0000",
            type: "error",
          });
          break;
      }
    }
  };

  const sendMessageClick = async () => {
    if (creatorMediaInfo?.userId === userInfo.user_id) return;

    if (creatorMediaInfo?.userId) {
      const isOpenedChat = openedChatUserIds?.includes(
        creatorMediaInfo?.userId
      );
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorMediaInfo?.userId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          setActiveChatId(response.res.chatId);
          setActiveChatIdState(response.res.chatId);
          break;

        default:
          break;
      }
      setOpenUseMediaModal(false);
      setPrincipalScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId: creatorMediaInfo?.userId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };

  const sendMessageHandler = () => {
    setIsChatPageOpen(true);
    sendMessageClick();
  };

  const onMouseDown = (e: React.MouseEvent) => {
    startX.current = e.clientX;
    const screenHeight = window.innerHeight;
    const footerHeight = 120;
    const centralAreaHeight = screenHeight - footerHeight;

    if (e.clientY > 120 && e.clientY < centralAreaHeight) {
      setClickTime(Math.round(e.timeStamp));
    }
  };

  const onMouseUp = (e: React.MouseEvent) => {
    const timeClickUp = Math.round(e.timeStamp);
    if (timeClickUp - clickTime < 500) {
      handleSectionClick(e);
    }

    startX.current = null;
  };

  const markTouchPosition = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const markClickDrag = (e: React.MouseEvent) => {
    if (startX.current === null) return;

    const diffX = e.clientX - (startX.current || 0);
    if (diffX > 100) {
      if (mediaIndexCurrent) {
        setMediaIndexCurrent((prev) => prev - 1);
      }
      startX.current = null;
    } else if (diffX < -100) {
      if (mediaIndexCurrent + 1 < medias.length) {
        setMediaIndexCurrent((prev) => prev + 1);
      }
      startX.current = null;
    }
  };

  const markTouchDrag = (e: React.TouchEvent) => {
    if (startX.current === null) return;

    const diffX = e.touches[0].clientX - (startX.current || 0);
    if (diffX > 100) {
      if (mediaIndexCurrent) {
        setMediaIndexCurrent((prev) => prev - 1);
      }
      startX.current = null;
    } else if (diffX < -100) {
      if (mediaIndexCurrent + 1 < medias.length) {
        setMediaIndexCurrent((prev) => prev + 1);
      }
      startX.current = null;
    }
  };

  const hasClickArea = () => {
    if (medias[mediaIndexCurrent]?.isPaidContent) {
      if (medias[mediaIndexCurrent]?.hasPaid) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSectionClick = (e: React.MouseEvent) => {
    if (!mediaModalRefContent.current) return;

    if (hasClickArea()) {
      const sectionWidth = mediaModalRefContent.current.clientWidth;
      const clickPosition = e.clientX;
      const sectionLeftBoundary =
        mediaModalRefContent.current.getBoundingClientRect().left;

      const relativeClickPosition = clickPosition - sectionLeftBoundary;

      if (relativeClickPosition < sectionWidth / 2) {
        if (mediaIndexCurrent) {
          setMediaIndexCurrent((prev) => prev - 1);
        }
      } else {
        conveyorActionsHandler(true);
      }
    }
  };
  if (isLoadingMoments) {
    return (
      <div className="moments-track__loading-page-wrapper">
        <Loading />
      </div>
    );
  }

  return (
    <section
      id="media-modal-content"
      className={className ?? ""}
      ref={mediaModalRef}
    >
      <section
        ref={mediaModalRefContent}
        id="moments-track"
        className={className ?? ""}
        onMouseDown={onMouseDown}
        onMouseMove={markClickDrag}
        onTouchStart={markTouchPosition}
        onTouchMove={markTouchDrag}
        onMouseUp={onMouseUp}
      >
        <div className="content-wrapper__progressbar-wrapper">
          {Array.from({ length: medias.length }, (_, index) => {
            return (
              <Progressbar
                key={index}
                sizeBar={index < mediaIndexCurrent ? 100 : 0}
                index={index}
                currentIndex={mediaIndexCurrent}
                pause={isPausedMedia}
                // time={Infinity}
                time={
                  medias[mediaIndexCurrent]?.type.toUpperCase() === "PHOTO"
                    ? 10000
                    : Math.round(medias[mediaIndexCurrent]?.fileDuration * 1000)
                }
                trigger={index === mediaIndexCurrent && !isLoadingMedias}
                triggerCallback={conveyorActionsHandler}
              />
            );
          })}
        </div>

        <MediaModalCard
          className="media-card-wrapper"
          clickInMediaCallBack={playPauseMedia}
          clickProfileHandlerCallback={clickUserFeed}
          media={{
            creatorId: creatorMediaInfo?.userId,
            creatorAvatar: profileAvatar,
            creatorName: creatorMediaInfo?.displayname,
            isPaidContent: mediaAbleToShow,
            media: medias[mediaIndexCurrent]?.media as string,
            type: medias[mediaIndexCurrent]?.type.toLowerCase() as
              | "video"
              | "photo",

            unlockPrice: medias[mediaIndexCurrent]?.unlockPrice,
            // mediaIsAbleToShow: mediaAbleToShow,
            mediaIsAbleToShow: mediaAbleToShow,
          }}
          unlockMediaCallback={() =>
            unlockMediaCallback(medias[mediaIndexCurrent])
          }
        />
      </section>
      {windowSize >= 768 ? (
        <section className="media-creator-details">
          <NewProfile
            momentStyle
            isIA={true}
            photos={photoProducer}
            age={creatorMediaInfo?.profile?.age}
            isVip={creatorMediaInfo?.isVip}
            idProfile={creatorMediaInfo?.userId}
            name={creatorMediaInfo?.displayname}
            principalPhoto={creatorMediaInfo?.profile?.photos[0].url}
            profilePhoto={
              creatorMediaInfo?.profile?.photos[0].thumbnailUrl as string
            }
            heartButtonClick={clickLikeHandler}
            sendMessageClick={sendMessageHandler}
            buttonStyle={
              isProfileFavorite ? "transparentActive" : "transparent"
            }
            icon={
              isProfileFavorite
                ? "icon-heart-solid"
                : "icon-heart-outline icon-gray-light"
            }
            iconSecondary={"icon-messages icon-gray-light"}
          />
        </section>
      ) : (
        false
      )}
    </section>
  );
};

export default MediaModal;

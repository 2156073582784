import { useState } from "react";
import Header from "components/Headers/Header";

import "./styles.scss";
import GalleryIcon from "assets/icons/keyBoardIcons/gallery-icon-gray.svg";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import PhotosPlaceholder from "components/PhotosPlaceholder";
import SubText from "components/Texts/SubText";
import { useUserInfo } from "../../hooks/userInfo";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useFiles } from "../../hooks/documentContext";
import { useToast } from "hooks/useToast";

const SendPhotoAlbumPage: React.FC = () => {
  const [instagramProfile, setInstagramProfile] = useState("");
  const [loading, setLoading] = useState(false);
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>();
  const { userInfo, setUserInfo } = useUserInfo();
  const isCreator = userInfo.account_type === "creator";
  const { setPrincipalScreen } = useDeskNavigation();
  const { setFiles } = useFiles();
  const { showToast } = useToast();

  const verifyImages = () => {
    if (photosAlbum) {
      const hasNullImage = (photosAlbum ?? []).filter(
        (photo): photo is File => photo === null
      );
      if (hasNullImage.length) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const sendPhotoSubmit = async () => {
    if (loading) return;
    setLoading(true);
    setUserInfo("instagram", instagramProfile);

    if (verifyImages()) {
      const validPhotos = (photosAlbum ?? []).filter(
        (photo): photo is File => photo !== null
      );

      setFiles(validPhotos);
      setPrincipalScreen("/terms-and-conditions");
    } else {
      showToast({
        title: "Add 3 photos",
        description: "You need to add three photos to continue",
        type: "warning",
      });
    }
    // const prepareData = {
    //   displayName: userInfo.display_name,
    //   phone: userInfo.user_phone.split("+")[1] as string,
    //   instagramUser: instagramProfile,
    //   type: userInfo.account_type === "creator" ? "CREATOR" : "CONSUMER",
    //   files: photosAlbum,
    // };

    // const response = await postNewMembership.postRegisterMembership(
    //   prepareData
    // );

    // switch (response.status) {
    //   case 201:
    //     setUserInfo("user_id", response.data.id);
    //     setUserInfo("instagram", instagramProfile);
    //     setComponent("/terms-and-conditions");
    //     break;

    //   default:
    //     setHasError(true);
    //     setToastErrorMessage("There was an error. Please try again.");

    //     setTimeout(() => {
    //       setHasError(false);
    //     }, 5000);

    //     break;
    // }

    setLoading(false);
  };

  const onChangeInput = (instagram: string) => {
    let userInstagram;
    if (instagram[0] !== "@") {
      userInstagram = "@" + instagram;
    } else {
      userInstagram = instagram;
    }

    setInstagramProfile(userInstagram);
  };

  return (
    <div className="send-photo-album margin-hor-16">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/recovery-email")}
      />

      <main className="send-photo-album__main">
        <Header
          title={
            <>
              Add your <br />
              photos
            </>
          }
          subTitle={"Select 3 photos that clearly shows you."}
          marginTop={0}
        />
        <PhotosPlaceholder
          quantityFiles={3}
          photoAlbumCallback={setPhotosAlbum}
        />

        <SubText className="margin-vert-8">
          Boost your approval chances by adding your Instagram profile. Your
          profile must be set to public (highly recommended).
        </SubText>

        <InputText
          value={instagramProfile}
          placeholder="@instagram (optional)"
          onChangeInput={(e) => onChangeInput(e)}
          textTransform="none"
          inputType="text"
        />

        <SubText
          icon={isCreator ? GalleryIcon : ""}
          altIcon="gallery photos icon"
          className="margin-vert-8"
        >
          We welcome sensual expression that's artistic and sophisticated.
          Please avoid explicit content to maintain the respectful and tasteful
          spirit of our community.
        </SubText>
      </main>
      <Button
        className="margin-bottom-40"
        buttonStyle="primary"
        onClick={sendPhotoSubmit}
        disabled={loading}
      >
        Submit application
      </Button>
    </div>
  );
};

export default SendPhotoAlbumPage;

import React, { forwardRef, useContext, useEffect, useState } from "react";
import "./styles.scss";

import Button from "components/Buttons/Button";
import ButtonIcon from "components/Buttons/ButtonIcon";
import ButtonPill from "components/Buttons/ButtonPill";
import CardsArrows from "../CardsArrows";
import ImageWithSize from "components/ImageWithSize";
import Progressbar from "components/Progressbar";

import { useToast } from "hooks/useToast";
import { useUserInfo } from "hooks/userInfo";

import { useCanPurchasedMedia } from "hooks/useCanPurchasedMedia";
import { useUnlockContent } from "hooks/useUnlockContent";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useChat } from "contexts/openedChatContext";
import { ToggleChatOpenContext } from "contexts/toggleChatOpen";

import postPostsLike from "api/posts/postPostsLike";
import deletePostsLike from "api/posts/deletePostsLike";
import CreateChatContact from "api/postCreateChatContact";

import { thousandsFormat } from "utils/thousandsFormat";

import { Post } from "types/post";

import ChevronRight from "assets/icons/navigationIcons/bx-chevron-right.svg";
import ChevronLeft from "assets/icons/navigationIcons/arrow-left-gray.svg";
import logo from "assets/icons/logos/logo-fill-pink.svg";
import Verify from "assets/icons/verify.svg";

interface IFeedCards {
  post: Post;
  className?: string;
  onDelete?: (e?: any) => void;
  onUnlockedPost?: (id: string, price: number) => void;
}

const FeedCards = forwardRef<HTMLDivElement, IFeedCards>(
  ({ post, className, onDelete, onUnlockedPost }, ref) => {
    const {
      postId,
      autorId,
      content,
      paidContent,
      price,
      createdAt,
      updatedAt,
      deleteAt,
      autor: { userId, displayname, profile: { photos } = { photos: [] } },
      files = [],
      _count: { likedBy, seenBy } = { likedBy: 0, seenBy: 0 },
      hasLiked = false,
      hasSeen = false,
      hasPaid = false,
      hasActiveSubscription = false,
    } = post;
    const { userInfo } = useUserInfo();
    const { activeChatId, setActiveChatId } = useChat();
    const { isChatPageOpen, setIsChatPageOpen } = useContext(
      ToggleChatOpenContext
    );
    const { setSecondaryScreen, setTertiaryScreen, params } =
      useDeskNavigation();

    const { showToast } = useToast();

    const [isOptionsActive, setIsOptionsActive] = useState(false);
    const [postHasLiked, setPostHasLiked] = useState(hasLiked);
    const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);

    const [photosCounter, setPhotosCounter] = useState(0);
    const [videosCounter, setVideosCounter] = useState(0);

    const [photoSelect, setPhotoSelect] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [mediaAbleToShow, setMediaAbleToShow] = useState(false);
    const [mediaIndexCurrent, setMediaIndexCurrent] = useState(0);
    const [isPausedMedia, setIsPausedMedia] = useState(false);
    const [isLoadingMedias, setIsLoadingMedias] = useState(true);
    const [likedByCount, setLikedByCount] = useState(likedBy);

    const isLocked = paidContent && !hasPaid && !hasActiveSubscription;
    const isOwn = userInfo.user_id === autorId;

    useEffect(() => {
      counterMedias();
    }, []);

    const handlerLike = () => {
      if (postHasLiked) {
        return clickUnlikeHandler();
      } else {
        return clickLikeHandler();
      }
    };

    const clickLikeHandler = async () => {
      try {
        const res = await postPostsLike(userInfo.access_token, { postId });

        if (res.status === 201) {
          setPostHasLiked(true);
          setLikedByCount((prev) => prev + 1);
        }
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, Try again",
          type: "error",
        });
      }
    };

    const clickUnlikeHandler = async () => {
      try {
        const res = await deletePostsLike(userInfo.access_token, { postId });

        if (res.status === 204) {
          setPostHasLiked(false);
          setLikedByCount((prev) => prev - 1);
        }
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, Try again",
          type: "error",
        });
      }
    };

    const formatTimeDifference = (postDate: string) => {
      const postTime = new Date(postDate).getTime();
      const now = new Date().getTime();

      const diffMs = now - postTime;
      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      const diffHours = Math.floor(diffMinutes / 60);
      const diffDays = Math.floor(diffHours / 24);

      if (diffMinutes < 1) return "Just now";
      if (diffMinutes < 60)
        return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
      if (diffHours < 24) return `${diffHours}h`;
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    };

    const counterMedias = () => {
      const photosTotal = files.reduce((total, file) => {
        return file.fileType === "PHOTO" ? total + 1 : total;
      }, 0);

      const videosTotal = files.reduce((total, file) => {
        return file.fileType === "VIDEO" ? total + 1 : total;
      }, 0);

      setPhotosCounter(photosTotal);
      setVideosCounter(videosTotal);
    };

    const sendMessageClick = async () => {
      if (userInfo.account_type === "CREATOR") return;
      setIsChatPageOpen(true);
      const creatorId = userId && userId !== "0" ? userId : params.userId;

      if (creatorId) {
        const isOpenedChat = openedChatUserIds?.includes(creatorId);
        const chatContactService = CreateChatContact();
        const response = await chatContactService.postCreateChatContact(
          creatorId,
          userInfo.access_token
        );

        switch (response.status) {
          case 201:
            setActiveChatId(response.res.chatId);
            break;

          default:
            break;
        }
        setSecondaryScreen("/personal-chat", {
          chatId: response.res.chatId,
          userId: creatorId,
          isLocked: JSON.stringify(isOpenedChat),
        });
      }
    };

    const changePrevious = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === 0) return 0;

        const newIndex = prevIndex - 1;
        setPhotoSelect(files[newIndex]?.url || files[prevIndex]?.url || "");
        return newIndex;
      });
    };

    const changeNext = () => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex >= files.length - 1) return prevIndex;

        const newIndex = prevIndex + 1;
        setPhotoSelect(files[newIndex]?.url || files[prevIndex]?.url || "");
        return newIndex;
      });
    };

    const conveyorActionsHandler = (completed: boolean) => {
      setIsLoadingMedias(true);

      if (completed) {
        if (mediaIndexCurrent + 1 < files.length) {
          setMediaIndexCurrent((prev) => prev + 1);
          changeNext();
        }
      }

      setIsLoadingMedias(false);
    };

    useEffect(() => {
      setPhotoSelect(files[0].url);
    }, [files]);

    useEffect(() => {
      if (paidContent) {
        if (hasPaid) {
          setMediaAbleToShow(true);
        } else {
          setMediaAbleToShow(false);
        }
      } else {
        setMediaAbleToShow(true);
      }
      setIsLoadingMedias(false);
    }, [mediaIndexCurrent]);

    const renderLocked = () => {
      return (
        <div className="feed-cards-locked">
          <img
            className="feed-cards-locked-icon margin-bottom-8"
            src={logo}
            alt="Logo Roses"
          />
          <span className="feed-cards-locked-price">{price}</span>
          {/* <p className="feed-cards-locked-text margin-vert-16">Memo Message</p> */}
          <Button
            children={"Unlock"}
            buttonStyle={"quintenary"}
            onClick={() => onUnlockedPost?.(post.postId, price)}
          />
        </div>
      );
    };

    return (
      <div ref={ref} id={postId} className={`feed-cards ${className ?? ""}`}>
        <div className="feed-creator-image-post">
          <CardsArrows
            className="feed-cards-arrows"
            changePrevious={changePrevious}
            changeNext={changeNext}
          />

          {/* {!isLocked ? (
            <div className="feed-cards__progressbar-wrapper">
              {Array.from({ length: files.length }, (_, index) => {
                return (
                  <Progressbar
                    key={index}
                    sizeBar={index < mediaIndexCurrent ? 100 : 0}
                    index={index}
                    currentIndex={mediaIndexCurrent}
                    pause={isPausedMedia}
                    time={
                      files[mediaIndexCurrent]?.fileType.toUpperCase() ===
                      "PHOTO"
                        ? 5000
                        : Math.round(
                            (files[mediaIndexCurrent]?.duration ?? 1) * 1000
                          )
                    }
                    trigger={index === mediaIndexCurrent && !isLoadingMedias}
                    triggerCallback={conveyorActionsHandler}
                  />
                );
              })}
            </div>
          ) : (
            false
          )} */}

          <ImageWithSize src={photoSelect} alt={"creator feed post"} />

          {isLocked && renderLocked()}

          <div className="feed-card-options">
            <button
              className="feed-card-top-btn"
              onClick={() => setIsOptionsActive(!isOptionsActive)}
            >
              <span className="feed-card-options-icon icon icon-dots-v"></span>
            </button>
            <div
              className={`feed-card-box ${
                isOptionsActive ? "feed-card-box--active" : ""
              }`}
            >
              {isOwn ? (
                <>
                  <ButtonPill
                    clickPill={function (a: any): void {
                      showToast({
                        description: "Please try again",
                        title: "Feature is coming soon!",
                        type: "error",
                      });
                    }}
                    textPill={"Edit"}
                    iconName={"icon-edit"}
                    isPillSelected={false}
                  />
                  <ButtonPill
                    clickPill={() => onDelete?.()}
                    textPill={"Delete"}
                    iconName={"icon-trash"}
                    isPillSelected={false}
                  />
                </>
              ) : (
                <>
                  <ButtonPill
                    clickPill={handlerLike}
                    textPill={"Like"}
                    iconName={"icon-heart-solid"}
                    isPillSelected={false}
                  />
                  <ButtonPill
                    clickPill={sendMessageClick}
                    textPill={"Chat"}
                    iconName={"icon-messages"}
                    isPillSelected={false}
                  />
                </>
              )}
            </div>
          </div>

          <ButtonIcon
            buttonStyle={postHasLiked ? "transparentActive" : "transparent"}
            icon={
              postHasLiked
                ? "icon-heart-solid"
                : "icon-heart-outline icon-gray-light"
            }
            className="feed-card-btn"
            onClick={handlerLike}
          >
            {thousandsFormat(likedByCount)}
          </ButtonIcon>

          <ButtonIcon
            buttonStyle={"transparent"}
            icon={"icon-messages icon-gray-light"}
            className="feed-card-btn feed-card-btn--chat"
            onClick={sendMessageClick}
          />
        </div>

        {/* {isVerify && <img src={Verify} alt="verify icon" />} */}

        <div className="creator-pack-media-info margin-16 margin-bottom-24">
          <span className="creator-pack clock">
            <span className="icon icon-clock icon-md icon-gray" />
            <p className="qnt-media-text no-margin">
              {formatTimeDifference(createdAt)}
            </p>
          </span>
          <span className="creator-pack video">
            <span className="icon icon-video icon-md icon-gray" />
            <p className="no-margin qnt-media-text">{videosCounter}</p>
          </span>
          <span className="creator-pack photo">
            <span className="icon icon-photo icon-md icon-gray" />
            <p className="no-margin qnt-media-text">{photosCounter}</p>
          </span>
          {/* <span className="creator-pack price">
            <span className="icon icon-money icon-md icon-gray" />
            <p className="no-margin qnt-media-text">{price}</p>
          </span> */}
          <span className="creator-pack views">
            <span className="icon icon-public icon-md icon-gray" />
            <p className="no-margin qnt-media-text">{seenBy}</p>
          </span>
        </div>

        <div className="feed-content padding-hor-16">
          <h3 className="feed-content-title no-margin">
            Unlock my latest exclusive content! 🔥
          </h3>
          <p className="feed-content-text no-margin">
            Get a sneak peek into my world and experience the behind-the-scenes
            magic. 🌹
          </p>
          <p className="feed-content-text no-margin">
            Tap to unlock and join my VIP fan club for more! 💋
          </p>

          <ul className="feed-content-tag-list no-padding">
            <li className="feed-content-tag">#ExclusiveContent</li>
            <li className="feed-content-tag">#VIPAccess</li>
          </ul>
        </div>
      </div>
    );
  }
);

export default FeedCards;

import { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import CodeVerification from "components/CodeVerification";

import "./styles.scss";
import ClockIcon from "assets/icons/clock-icon-gray.svg";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import { CountryCode } from "libphonenumber-js";
import { setupPhoneNumberInput } from "../../utils/masks";
import codesMock from "../../mocks/countryCodes.json";
import VerifyPhoneApiWpp from "../../api/verifyPhone/postVerifyPhoneWPP";
import VerifyPhoneApiSms from "../../api/verifyPhone/postVerifyPhoneSMS";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useToast } from "hooks/useToast";

const CreateNumberVerifyPage: React.FC = () => {
  const [codeValue, setCodeValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [phoneWontPlus, setPhoneWontPlus] = useState("");
  const [isWarning, setIsWarning] = useState(false);
  const { showToast } = useToast();

  const { setComponent, params } = useLoginSignUpNavigation();
  const { userInfo } = useUserInfo();
  const { sendingMethod, enterMethod } = params;

  useEffect(() => {
    const country = codesMock.find(
      (code) => code.countryCode === userInfo?.country_code
    );

    const formattedNumber = setupPhoneNumberInput(
      country?.code as CountryCode,
      userInfo.user_phone ?? ""
    );

    setFormattedNumber(formattedNumber);

    setPhoneWontPlus(userInfo.user_phone.split("+")[1] as string);
  }, [userInfo?.country_code, userInfo.user_phone]);

  const handleVerificationSuccess = async () => {
    if (enterMethod === "check-application" || enterMethod === "login") {
      setComponent("/password-sign-in");
    } else {
      setComponent("/create-account");
    }
  };

  const addPhoneNumberSubmit = async () => {
    setIsLoading(true);
    let response;

    if (sendingMethod === "whatsapp") {
      response = await VerifyPhoneApiWpp.createVerification(
        phoneWontPlus
      ).verifyCode(codeValue);
    } else {
      response = await VerifyPhoneApiSms.createVerification(
        phoneWontPlus
      ).verifyCode(codeValue);
    }

    switch (response?.status) {
      case 200:
        const body = await response.json();

        if (body.valid) {
          await handleVerificationSuccess();
        } else {
          setIsWarning(true);

          showToast({
            title: "Error",
            type: "error",
            description: "The code number is wrong",
          });

          setTimeout(() => {
            setIsWarning(false);
          }, 4000);
        }

        break;
      case 422:
        setIsWarning(true);

        showToast({
          title: "Error",
          type: "error",
          description: "Invalid code. Check and try again.",
        });

        setTimeout(() => {
          setIsWarning(false);
        }, 4000);
        break;

      default:
        setIsWarning(true);

        showToast({
          title: "Error",
          type: "error",
          description: "Invalid code. Check and try again.",
        });

        setTimeout(() => {
          setIsWarning(false);
        }, 4000);
        break;
    }

    setIsLoading(false);
  };

  const resendCode = async () => {
    setIsLoading(true);

    let response;

    if (sendingMethod === "whatsapp") {
      response = await VerifyPhoneApiWpp.createVerification(
        phoneWontPlus
      ).requestCode();
    } else {
      response = await VerifyPhoneApiSms.createVerification(
        phoneWontPlus
      ).requestCode();
    }

    switch (response.status) {
      case 200:
        showToast({
          title: "You have a new code",
          type: "success",
          description: "We will send you a code",
        });

        break;
      default:
        showToast({
          title: "Error",
          type: "error",
          description: "Please, try again",
        });

        break;
    }

    setIsLoading(false);
  };

  const backButtonHandler = () => {
    setComponent("/account-login");
  };

  return (
    <div className="create-number-verify">
      <div>
        <Header
          headerIcon="back-button"
          backButtonClick={backButtonHandler}
          title={
            <>
              Verify your <br />
              number
            </>
          }
          subTitle={
            <>
              Enter the code we’ve sent by{" "}
              {sendingMethod === "whatsapp" ? "WhatsApp" : "SMS"} to{" "}
              <span className="phone-text padding-hor-4">
                {formattedNumber || ""}
              </span>
              <span
                className="anchor-link"
                onClick={() => setComponent("/account-login")}
              >
                Change
              </span>
            </>
          }
        />

        <CodeVerification
          fieldsNumber={5}
          valuesCodesCallback={(e) => setCodeValue(e)}
          isWarning={isWarning}
          onEnterPress={addPhoneNumberSubmit}
        />
        <SubText icon={ClockIcon} altIcon="clock icon">
          This code should arrive within 30 seconds. Didn’t receive the code?{" "}
          <span className="resend-code" onClick={resendCode}>
            Resend new code
          </span>
        </SubText>
      </div>

      <Button
        disabled={isLoading || codeValue.length < 5}
        buttonStyle="primary"
        onClick={addPhoneNumberSubmit}
      >
        Continue
      </Button>
    </div>
  );
};

export default CreateNumberVerifyPage;

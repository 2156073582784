import banner01 from "assets/images/landingPageImages/banner/roses_banner_01.jpg";
import banner02 from "assets/images/landingPageImages/banner/roses_banner_02.jpg";
import banner03 from "assets/images/landingPageImages/banner/roses_banner_03.jpg";
import banner04 from "assets/images/landingPageImages/banner/roses_banner_04.jpg";
import banner05 from "assets/images/landingPageImages/banner/roses_banner_05.jpg";
import banner06 from "assets/images/landingPageImages/banner/roses_banner_06.jpg";
import sparkIconPink from "assets/icons/bannerIcons/spark-icon-pink.svg";
import chatIconPink from "assets/icons/bannerIcons/chat-icon-pink.svg";
import cryptoIconPink from "assets/icons/bannerIcons/crypto-icon-pink.svg";
import globeIconPink from "assets/icons/bannerIcons/globe-icon-pink.svg";
import rosesTokenIconPink from "assets/icons/bannerIcons/roses-token-icon-pink.svg";
import securityIconPink from "assets/icons/bannerIcons/security-icon-pink.svg";

export const slideCarousel = [
  {
    title: "Fuel your<br><strong>creative spark</strong>",
    subtitle:
      "Harness powerful features to elevate your content, increase discoverability, and maximize your impact",
    backgroundImage: banner01,
    icon: sparkIconPink,
  },
  {
    title: "Expand your<br><strong>global influence</strong>",
    subtitle:
      "Use auto-translation chat for seamless interaction with fans worldwide, breaking language barriers to enhance your global reach",
    backgroundImage: banner02,
    icon: chatIconPink,
  },
  {
    title: "Trust and<br><strong>transparency</strong>",
    subtitle:
      "Enjoy a secure, private environment where you retain full control over your content and who sees it, ensuring peace of mind",
    backgroundImage: banner03,
    icon: securityIconPink,
  },
  {
    title: "Share<br><strong>your world</strong>",
    subtitle:
      "Highlight a part of yourself, showcasing your true essence without limitations",
    backgroundImage: banner04,
    icon: globeIconPink,
  },
  {
    title: "Cherished our<br><strong>tokens of devotion</strong>",
    subtitle:
      "Treasure the experience of receiving Roses, elevating each interaction with tokens of admiration",
    backgroundImage: banner05,
    icon: rosesTokenIconPink,
  },
  {
    title: "Earn more<br><strong>with crypto</strong>",
    subtitle:
      "Dive into crypto earnings to amplify your possibilities and maximize your creative earnings",
    backgroundImage: banner06,
    icon: cryptoIconPink,
  },
];

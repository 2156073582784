export const startInterval = (
  setTimer: (time: string) => void,
  initialSeconds: number = 0
): NodeJS.Timeout => {
  let seconds = initialSeconds;
  setTimer(formatTime(seconds));

  const interval = setInterval(() => {
    seconds++;
    setTimer(formatTime(seconds));
  }, 1000);

  return interval;
};

const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${String(remainingSeconds).padStart(2, "0")}`;
};

export const stopInterval = (
  intervalId: NodeJS.Timeout,
  setTimer: (time: string) => void
) => {
  clearInterval(intervalId);
  setTimer("0:00");
};

import fetchApi from "../fetchApi";

const postEmailReceivedVerify = async (bodyToken: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/verify-email`,
    method: "POST",
    data: {
      token: bodyToken,
    },
  });
};

export default postEmailReceivedVerify;

import fetchApi from "../fetchApi";

const deleteUserInterests = async (token: string, interests: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/interests/${interests}`,
    method: "DELETE",
    token,
  });
};

export default deleteUserInterests;

import React from "react";
import InitialImage from "assets/images/353021aa9d1256ec3d20d9d33e3a10e2.jpeg";
import Button from "components/Buttons/Button";
import RosesLogo from "assets/icons/roses-default.svg";
import SubText from "components/Texts/SubText";
import "./styles.scss";
import { useUserInfo } from "../../hooks/userInfo";
import { useNavigate } from "react-router-dom";
import postCompleteCreatorAccount from "../../api/creatorApplications/postCompleteCreatorAccount";

const WelcomeUser: React.FC = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const navigate = useNavigate();

  const handleConfirm = async () => {
    const response = await postCompleteCreatorAccount(userInfo.access_token);

    switch (response.status) {
      case 201:
        setUserInfo("account_type", "CREATOR");
        navigate("/app");
        break;
    }
  };

  return (
    <div className="welcome-user">
      <div
        style={{ backgroundImage: `url(${InitialImage})` }}
        className="welcome-roses"
      />

      <div className="bottom-text-button-container">
        <img className="roses-logo" src={RosesLogo} alt="Initial Steplogo" />
        <h1 className="confirmation">Welcome to Roses!</h1>
        <SubText>
          Your membership application has been accepted. Complete your account
          setup to start exploring Roses and connecting with other members.
        </SubText>

        <Button buttonStyle="primary" onClick={handleConfirm}>
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default WelcomeUser;

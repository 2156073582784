import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { startInterval, stopInterval } from "../../../utils/timer";
import { thumbnailGenerator } from "../../../utils/thumbnailGenerator";
import Loading from "components/Loading";

interface OpenedCameraProps {
  setMediaToShow: React.Dispatch<React.SetStateAction<ICapturedMedia | null>>;
  setCapturedMedia: React.Dispatch<React.SetStateAction<[] | ICapturedMedia[]>>;
  setScreenShowing: React.Dispatch<
    React.SetStateAction<"library" | "preview-captured" | "camera" | "">
  >;
  showButtonGallery: boolean;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  mediaRecorderRef: React.MutableRefObject<MediaRecorder | null>;
  stream: MediaStream | null;
}

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

const OpenedCamera: React.FC<OpenedCameraProps> = (props) => {
  const {
    setMediaToShow,
    setCapturedMedia,
    setScreenShowing,
    showButtonGallery,
    setFile,
    videoRef,
    canvasRef,
    mediaRecorderRef,
    stream,
  } = props;
  let holdTimeout: NodeJS.Timeout;
  let recordingInterval: NodeJS.Timeout;

  useEffect(() => {
    if (stream) {
      setLoadingCamera(false);
    }
  }, [stream]);

  const [videoRecordingTime, setVideoRecordingTime] = useState("0:00");
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [loadingCamera, setLoadingCamera] = useState(true);
  const [isHolding, setIsHolding] = useState(false);
  const recordingButtonRef = useRef<HTMLButtonElement>(null);

  const startRecording = () => {
    if (stream) {
      try {
        const options = { mimeType: "video/webm; codecs=vp8" };

        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          options.mimeType = "video/webm";
        }

        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;
        const localChunks: Blob[] = [];
        mediaRecorder.start();
        setIsRecording(true);
        recordingInterval = startInterval(setVideoRecordingTime);

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            localChunks.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          if (localChunks.length > 0) {
            const blob = new Blob(localChunks, { type: "video/webm" });

            const videoURL = URL.createObjectURL(blob);
            setMediaToShow({ media: videoURL, type: "video" });
            setRecordedChunks([]);

            thumbnailGenerator(videoURL).then((thumbnail) => {
              setCapturedMedia((prev) => [
                ...prev,
                { media: videoURL, thumbnail, type: "video" },
              ]);
            });
            setIsRecording(false);

            stopInterval(recordingInterval, setVideoRecordingTime);
            setScreenShowing("preview-captured");
          }
        };

        mediaRecorder.onerror = (error) => {
          console.error("Error: ", error);
        };
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context) {
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        const { width: visibleWidth, height: visibleHeight } =
          video.getBoundingClientRect();

        const aspectRatioVideo = videoWidth / videoHeight;
        const aspectRatioContainer = visibleWidth / visibleHeight;

        let cropX = 0,
          cropY = 0,
          cropWidth = videoWidth,
          cropHeight = videoHeight;

        if (aspectRatioVideo > aspectRatioContainer) {
          cropWidth = videoHeight * aspectRatioContainer;
          cropX = (videoWidth - cropWidth) / 2;
        } else {
          cropHeight = videoWidth / aspectRatioContainer;
          cropY = (videoHeight - cropHeight) / 2;
        }

        canvas.width = cropWidth;
        canvas.height = cropHeight;

        context.drawImage(
          video,
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );

        const imageDataURL = canvas.toDataURL("image/png");

        setMediaToShow({ media: imageDataURL, type: "photo" });

        setCapturedMedia((prev) => [
          ...prev,
          { media: imageDataURL, type: "photo" },
        ]);
        setScreenShowing("preview-captured");
      }
    }
  };

  const handleMouseDown = () => {
    setIsHolding(false);

    holdTimeout = setTimeout(() => {
      setIsHolding(true);
      startRecording();
    }, 1000);
  };

  const handleMouseUp = () => {
    clearTimeout(holdTimeout);
    if (isHolding && isRecording && mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      stopInterval(recordingInterval, setVideoRecordingTime);
      setIsHolding(false);
    } else if (!isHolding) {
      capturePhoto();
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(holdTimeout);
    if (isHolding && isRecording && mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      stopInterval(recordingInterval, setVideoRecordingTime);
      setIsHolding(false);
    }
  };

  return (
    <section className="opened-camera-component">
      <video className="opened-camera-component__video " ref={videoRef} />
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

      <div className={`opened-camera-component__button-container`}>
        {isHolding ? (
          <p className="button-container__timer">{videoRecordingTime}</p>
        ) : (
          false
        )}
        {loadingCamera ? (
          <Loading />
        ) : (
          <div
            className={`button-container__button-wrapper ${
              isHolding ? "video-recording" : ""
            }`}
          >
            <button
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
              className={`button-container__recording-button ${
                isHolding ? "video-recording" : ""
              }`}
              ref={recordingButtonRef}
            ></button>
          </div>
        )}
      </div>

      {showButtonGallery ? (
        <figure
          className="opened-camera-component__multi-images"
          onClick={() => setScreenShowing("preview-captured")}
        >
          <span
            className="icon icon-bulk-images
						icon-white icon-lg padding-16"
          />
        </figure>
      ) : (
        false
      )}
    </section>
  );
};

export default OpenedCamera;

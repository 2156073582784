import fetchApi from "../fetchApi";

const postChangeUnverifiedEmail = async (
  token: string,
  data: {
    newEmail: string;
    password: string;
  }
) => {
  return await fetchApi({
    service: "user",
    endPoint: `/change-unverified-email`,
    method: "POST",
    data,
    token,
  });
};

export default postChangeUnverifiedEmail;

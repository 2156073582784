import React, { ReactNode } from "react";
import "./styles.scss";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import Logo from "assets/icons/logos/logo-fill-pink.svg";

interface IChatMessageSystem {
  title?: string;
  text?: string;
  messageText?: string;
  specialText?: {
    text?: string;
    link?: string;
  };
  amount?: number;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}

const ChatMessageSendRoses: React.FC<IChatMessageSystem> = (props) => {
  const {
    title = "Order confirmation",
    text = "You receive a present",
    messageText = "",
    specialText,
    children,
    className,
    onClick,
  } = props;
  return (
    <div
      className={`chat-send-roses margin-bottom-16 padding-16 ${
        className || ""
      }`}
    >
      <ButtonIcon
        buttonStyle={"transparent"}
        className="chat-send-roses__btn margin-right-16 padding-8"
      >
        <img src={Logo} alt="" className="chat-send-roses__logo" />
      </ButtonIcon>

      <div className="chat-send-roses__content margin-right-16">
        {title && <h2 className="chat-send-roses__title no-margin">{title}</h2>}

        {text && (
          <p className="chat-send-roses__text no-margin">
            {text}{" "}
            {specialText && (
              <span className="chat-send-roses__text--special no-margin">
                {specialText.text}
              </span>
            )}
          </p>
        )}

        {messageText && (
          <p className="chat-send-roses__text no-margin">{messageText}</p>
        )}

        {children && children}
      </div>

      {onClick && (
        <span
          className="chat-send-roses__icon icon-chevron-right"
          onClick={onClick}
        ></span>
      )}
    </div>
  );
};
export default ChatMessageSendRoses;

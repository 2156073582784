import React, { useEffect, useState } from "react";

import "./styles.scss";

import InputText from "../../Inputs/InputText";
import ButtonIcon from "../../Buttons/ButtonIcon";
import ImageCircleBorder from "../../ImageCircleBorder";

import { useIconContext } from "../../../contexts/iconNavContext";
import { useAvailableRoses } from "../../../contexts/availableRosesContext";

import FilterIcon from "assets/icons/iconfilter.svg";
import ProfileIcon from "assets/icons/profile-icon-gray.svg";

import { thousandsFormat } from "../../../utils/thousandsFormat";
import { useSearchCatalog } from "hooks/useSearchCatalog";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import verifyToken from "utils/verifyToken";
import Button from "components/Buttons/Button";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";
import { useNavigate } from "react-router-dom";
import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";
import { useModal } from "hooks/useModal";

interface HeaderHomePageProps {
  hasANewMessage: boolean;
  profileAvatar: string;
  className?: string;
  backButtonClick?: React.MouseEventHandler<HTMLImageElement>;
  clickProfileAvatar?: (a: any) => void;
  clickChatList?: (a?: any) => void;
  favoritesClick?: (a?: any) => void;
  logoClick?: (a?: any) => void;
  clickOnGrid?: () => void;
}

const HeaderHomePage: React.FC<HeaderHomePageProps> = ({
  hasANewMessage,
  profileAvatar,
  className,
  backButtonClick,
  clickProfileAvatar,
  clickChatList,
  favoritesClick,
  logoClick,
  clickOnGrid,
}) => {
  const { handleButtonClick, getIconClass } = useIconContext();
  const { availableRoses } = useAvailableRoses();
  const availableRosesFormatted = thousandsFormat(availableRoses);
  const { searchValue, setSearchValue } = useSearchCatalog();
  const [showButtonsAuth, setButtonsAuth] = useState(false);
  const checkAuth = useAuthCheckOpenModal();
  const navigate = useNavigate();
  const { setComponent, component } = useLoginSignUpNavigation();

  const { setOpenUseModal, setModalContent, openUseModal } = useModal();

  useEffect(() => {
    if (!verifyToken()) {
      setButtonsAuth(true);
    } else {
      setButtonsAuth(false);
    }
  }, [openUseModal]);

  const { setFeedScreen, setTertiaryScreen, setMainScreen } =
    useDeskNavigation();

  const clickOnSearch = () => {
    setSearchValue("");
  };

  const searchCreator = () => {
    setMainScreen("/catalog");
    setFeedScreen("");
    setTertiaryScreen("");
  };

  const createAccountHandler = () => {
    navigate("/login");
    setComponent("/create-account");
  };

  const loginHandler = () => {
    if (!checkAuth()) return;
  };

  return (
    <header id="header-home-page" className={className}>
      {/* MOBILE */}
      <div className="header-mobile-container ">
        <div className="icon-container">
          <div
            className={`logo-text-header ${logoClick ? "cursor" : ""}`}
            onClick={logoClick}
          >
            <span className="icon icon-roses-default icon-md" />
            <p>
              Roses<sup>™</sup>
              <span className="beta">beta</span>
            </p>
          </div>
          {showButtonsAuth ? (
            <div className="login-signup-buttons-container">
              {/* <Button buttonStyle="tertiary" onClick={createAccountHandler}>
                Create Account
              </Button> */}
              <Button buttonStyle="primary" onClick={loginHandler}>
                Login
              </Button>
            </div>
          ) : (
            <div className="actions-container">
              <div className="roses-and-golden-roses">
                <p className="roses-text">{availableRosesFormatted}</p>
                <span className="vertical-separator"> | </span>

                <p className="golden-roses-text">5</p>
              </div>
              <ImageCircleBorder
                onClick={clickProfileAvatar}
                centerImageAlt=""
                centerImage={profileAvatar ? profileAvatar : ProfileIcon}
                noSpaceBetweenBorder
                size={40}
              />
            </div>
          )}
        </div>
      </div>

      {/* DESKTOP */}
      <div className="header-wide-container">
        <div
          className={`logo-container ${logoClick ? "cursor" : ""}`}
          onClick={logoClick}
        >
          <span className="icon icon-roses-default icon-lg" />
          <h1 className="no-margin">
            Roses<sup>™</sup>
            <span className="beta">beta</span>
          </h1>
        </div>
        <div className="div-container">
          <div className="center-content">
            <div className="left-buttons-container">
              <ButtonIcon
                icon="icon-grid"
                buttonStyle="tertiary"
                iconClass={getIconClass("grid")}
                className="header-navigate-btn"
                onClick={() => {
                  handleButtonClick("grid");
                  clickOnGrid?.();
                }}
              />
              <ButtonIcon
                icon="icon-heart-outline"
                buttonStyle="tertiary"
                iconClass={getIconClass("heart-outline")}
                className="header-navigate-btn"
                onClick={() => {
                  handleButtonClick("heart-outline");
                  favoritesClick?.();
                }}
              />
            </div>
            <InputText
              value={searchValue}
              searchInput
              onClick={clickOnSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchCreator();
                }
              }}
              onChangeInput={(e) => {
                setSearchValue(e);
              }}
              placeholder="Search"
              inputStyle="input-text-search"
              iconRight={FilterIcon}
              inputType="text"
            />
            <div className="right-buttons-container">
              <div className="new-message-container">
                <ButtonIcon
                  icon="icon-messages"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("messages")}
                  className="header-navigate-btn"
                  onClick={() => {
                    handleButtonClick("messages");
                    clickChatList?.();
                  }}
                />
                {hasANewMessage && <span className="sphere-icon"></span>}
              </div>
            </div>
          </div>
          {showButtonsAuth ? (
            <div className="login-signup-buttons-container">
              <Button buttonStyle="tertiary" onClick={createAccountHandler}>
                Create Account
              </Button>
              <Button buttonStyle="primary" onClick={loginHandler}>
                Login
              </Button>
            </div>
          ) : (
            <div className="right-buttons-container-profile">
              <div className="roses-and-golden-roses">
                <p className="roses-text">{availableRosesFormatted}</p>
                <span className="vertical-separator"> | </span>
                <p className="golden-roses-text">5</p>
              </div>
              <ImageCircleBorder
                onClick={clickProfileAvatar}
                centerImageAlt=""
                centerImage={profileAvatar ? profileAvatar : ProfileIcon}
                noSpaceBetweenBorder
                noBorder
                hasPadding
                size={40}
              />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderHomePage;

import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import FadeCarousel from "components/Carousels/FadeCarousel";
import Header from "components/Headers/Header";
import Girl18 from "assets/images/18+_girl.jpg";
import Button from "components/Buttons/Button";
import { useModal } from "hooks/useModal";
import { slideCarousel } from "mocks/slideCarousel";
import { useUserInfo } from "hooks/userInfo";
import Paragraph from "components/Texts/Paragraph";
import SubText from "components/Texts/SubText";
import ClockIconGray from "assets/icons/clock-icon-gray.svg";
import postEmailSendVerify from "api/verifyEmail/postEmailSendVerify";
import InputText from "components/Inputs/InputText";
import postChangeUnverifiedEmail from "api/verifyEmail/postChangeUnverifiedEmail";
import { useToast } from "hooks/useToast";
import LoadingPage from "components/LoadingPage";
import { useNavigate } from "react-router-dom";
import { startInterval, stopInterval } from "utils/timer";

interface VerifyEmailModalProps {
  className?: string;
  navigateToApp: () => void;
  screenToShow:
    | "change-email"
    | "resend-email-link"
    | "email-sended-successfully";
}

const VerifyEmailModal: React.FC<VerifyEmailModalProps> = (props) => {
  const { className, navigateToApp, screenToShow } = props;

  const { userInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [restrictEmail, setRestrictEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [timeToResendLink, setTimeToResendLink] = useState(0);

  const { openUseModal, setOpenUseModal } = useModal();
  const [showTimer, setShowTimer] = useState(false);

  const { showToast } = useToast();

  const [screenShowing, setScreenShowing] = useState<
    "change-email" | "resend-email-link" | "email-sended-successfully"
  >("resend-email-link");

  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (timeToResendLink === 0) {
      return;
    }

    const timer = setInterval(() => {
      setTimeToResendLink((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeToResendLink]);

  useEffect(() => {
    if (!openUseModal || isLoading) return;
    const handleClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOpenUseModal(false);
      }
    };

    setTimeout(() => {
      document.addEventListener("click", handleClick);
    }, 0);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [openUseModal, isLoading]);

  useEffect(() => {
    setScreenShowing(screenToShow);
  }, [screenToShow]);

  useEffect(() => {
    if (!userInfo?.user_email) return;

    const [name, domain] = userInfo.user_email.split("@");
    if (!name || !domain) return;

    const halfLength = Math.floor(name.length / 2);
    const start = name.substring(0, Math.ceil(halfLength / 2));
    const end = name.substring(name.length - Math.floor(halfLength / 2));
    const maskedName = `${start}${"*".repeat(
      name.length - start.length - end.length
    )}${end}@${domain}`;

    setRestrictEmail(maskedName);
  }, [userInfo]);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const sendEmailVerifyAgain = async () => {
    setIsLoading(true);
    setShowTimer(true);
    try {
      const request = await postEmailSendVerify(userInfo.access_token);

      switch (request.status) {
        case 200:
          setScreenShowing("email-sended-successfully");
          setTimeToResendLink(30);

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendNewEmailVerify = async () => {
    if (!isValidEmail(newEmail)) {
      showToast({
        description: "Check your email and try again",
        title: "Invalid email",
        type: "warning",
      });
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 4000);
      return;
    }

    if (!userPassword || userPassword.length < 8) {
      showToast({
        description: "Your password should be at least 8 characters long",
        title: "Invalid characters number",
        type: "warning",
      });
      setPasswordInputWarning(true);

      setTimeout(() => setPasswordInputWarning(false), 5000);
      return;
    }

    setIsLoading(true);

    try {
      const request = await postChangeUnverifiedEmail(userInfo.access_token, {
        newEmail,
        password: userPassword,
      });

      switch (request.status) {
        case 200:
          setScreenShowing("email-sended-successfully");

          setTimeToResendLink(30);

          break;

        case 409:
          showToast({
            description: "This is not a valid email",
            title: "Invalid Email",
            type: "warning",
          });
          setPasswordInputWarning(true);

          setTimeout(() => setPasswordInputWarning(false), 5000);
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const changeEmail = () => {
    setScreenShowing("change-email");
  };

  return (
    <div id="verify_email_modal" ref={modalRef}>
      <div className="verify_email_modal__content">
        <div className="content__carousel-wrapper">
          <FadeCarousel
            slides={slideCarousel}
            duration={4000}
            borderRadius={[24, 0, 0, 24]}
            showGradient={false}
            showText={false}
          />
        </div>
        <div className="content__form-wrapper">
          {isLoading ? (
            <LoadingPage />
          ) : screenShowing === "resend-email-link" ? (
            <>
              <div>
                <Header
                  title="Verify your email"
                  marginTop={100}
                  titleClass="header"
                  headerIcon="roses-logo"
                  subTitle={
                    <>
                      Click the link in your email{" "}
                      <span className="highlighted-text">{restrictEmail}</span>{" "}
                      to verify your account.
                    </>
                  }
                />

                <SubText icon={ClockIconGray}>
                  This text should arrive within 30 seconds
                </SubText>
              </div>
              <div className="content__buttons-container margin-vert-24">
                <Button
                  disabled={isLoading}
                  buttonStyle="quaternary"
                  onClick={changeEmail}
                >
                  Send to different email
                </Button>

                <Button
                  disabled={isLoading}
                  buttonStyle="primary"
                  onClick={sendEmailVerifyAgain}
                >
                  Send again
                </Button>
              </div>
            </>
          ) : screenShowing === "change-email" ? (
            <>
              <div className="form-wrapper__header">
                <Header
                  title="Change your email"
                  marginTop={100}
                  titleClass="header"
                  headerIcon="roses-logo"
                  subTitle={"Add a new email for verification"}
                />

                {/* <SubText icon={ClockIconGray}>
                  This text should arrive within 30 seconds
                </SubText> */}

                <InputText
                  inputType="email"
                  onChangeInput={setNewEmail}
                  value={newEmail}
                  label={"New Email"}
                  placeholder="example@email.com"
                  isWarning={userEmailWarning}
                />

                <InputText
                  label="Password already in use"
                  inputType="password"
                  value={userPassword}
                  placeholder="Password"
                  onChangeInput={(e) => setUserPassword(e)}
                  isWarning={passwordInputWarning}
                  className="no-margin"
                />
              </div>
              <div className="content__buttons-container margin-vert-24">
                <Button
                  disabled={isLoading}
                  buttonStyle="primary"
                  onClick={sendNewEmailVerify}
                >
                  Send email verification
                </Button>
              </div>
            </>
          ) : screenShowing === "email-sended-successfully" ? (
            <>
              <div className="form-wrapper__header">
                <Header
                  title="Email has been updated successfully"
                  marginTop={100}
                  titleClass="header"
                  headerIcon="roses-logo"
                  subTitle={
                    "Check your email inbox and click on the link we sent you"
                  }
                />

                <SubText icon={ClockIconGray}>
                  This text should arrive within 30 seconds
                </SubText>
              </div>
              <div className="content__buttons-container margin-vert-24">
                <Button
                  disabled={isLoading || timeToResendLink !== 0}
                  buttonStyle="quaternary"
                  onClick={sendEmailVerifyAgain}
                >
                  {timeToResendLink !== 0
                    ? `${timeToResendLink}s`
                    : "Resend Link"}
                </Button>

                <Button
                  disabled={isLoading}
                  buttonStyle="secondary"
                  onClick={navigateToApp}
                >
                  Enjoy the App!
                </Button>
              </div>
            </>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailModal;

import fetchApi from "../fetchApi";

export type IDeletePostsLike = {
  postId: string
};

const deletePostsLike = async (token: string, data: IDeletePostsLike) => {
  const { postId } = data;

  return await fetchApi({
    service: "posts",
    method: "DELETE",
    endPoint: `/like/${postId}`,
    token,
  });
};

export default deletePostsLike;

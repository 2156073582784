import { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import "./styles.scss";
import Button from "components/Buttons/Button";
import { useUserInfo } from "../../hooks/userInfo";
import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";
import { useNavigate } from "react-router-dom";

const CreateEmailVerifyPage: React.FC = () => {
  const [restrictEmail, setRestrictEmail] = useState("");
  const { setComponent } = useLoginSignUpNavigation();
  const { userInfo } = useUserInfo();

  const navigate = useNavigate();

  useEffect(() => {
    const domain = userInfo.user_email.split("@")[1];
    const name = userInfo.user_email.split("@")[0];

    const len = name.length;
    const halfLength = Math.floor(len / 2);

    const start = name.substring(0, Math.ceil(halfLength / 2));
    const end = name.substring(len - Math.floor(halfLength / 2));
    const middle = "*".repeat(len - start.length - end.length);

    const maskedName = start + middle + end + "@" + domain;

    setRestrictEmail(maskedName);
  }, []);

  const skipButtonHandler = () => {
    navigate("/app");
  };

  return (
    <div className="create-email-verify margin-hor-16">
      <main className="create-email-verify__main">
        <Header
          backButtonClick={() => setComponent("/create-account")}
          marginTop={0}
          headerIcon="roses-logo"
          title={
            <>
              Verify your <br />
              Email
            </>
          }
          subTitle={
            <>
              Click the link in your email{" "}
              <span className="highlighted-text">{restrictEmail}</span> to
              verify your account.
              <span
                className="anchor-link"
                onClick={() => setComponent("/create-account")}
              >
                Change email
              </span>
            </>
          }
        />
      </main>
      <Button
        className="margin-bottom-40"
        buttonStyle="quaternary"
        onClick={skipButtonHandler}
      >
        Skip for Now
      </Button>
    </div>
  );
};

export default CreateEmailVerifyPage;

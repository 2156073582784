import { useModal } from "../../../../hooks/useModal";
import React from "react";
import CloseIconGray from "assets/icons/closeIcons/close-icon-gray.svg";
import "../chat-modal-global-style.scss";

interface ImageMessageModalProps {
  url: string;
}

const ImageMessageModal: React.FC<ImageMessageModalProps> = (props) => {
  const { url } = props;

  const { setOpenUseModal } = useModal();

  return (
    <div className="modal-preview-file">
      <div className="header-modal-preview">
        <img
          src={CloseIconGray}
          alt="close icon"
          onClick={() => setOpenUseModal(false)}
        />
      </div>
      <div className="preview-file-container">
        <img src={url} alt={`preview-image`} className="file-modal-content" />
      </div>
    </div>
  );
};

export default ImageMessageModal;

import Header from "components/Headers/Header";

import "./styles.scss";
import Button from "components/Buttons/Button";
import { useLoginSignUpNavigation } from "../../hooks/useLoginSignUpNavigation";
import { useToast } from "hooks/useToast";

const AllowNotificationPage: React.FC = () => {
  const { setComponent } = useLoginSignUpNavigation();
  const { showToast } = useToast();

  const enableNotification = async () => {
    let permission = await Notification.requestPermission();

    if (permission === "granted") {
      setComponent("/gender-identification");
    } else {
      showToast({
        description: "Permission denied",
        title: "Permission denied",
        type: "error",
      });
    }
  };

  const cancelEnableNotification = () => {
    setComponent("/gender-identification");
  };

  return (
    <div className="allow-notification">
      <Header
        headerIcon="bell"
        backButtonClick={() => setComponent("/allow-notification")}
        title={
          <>
            Allow <br />
            notification
          </>
        }
        subTitle={"We’ll let your know when you get new matches and messages"}
      />

      <Button buttonStyle="secondary" onClick={enableNotification}>
        Allow Notifications
      </Button>
      <Button buttonStyle="tertiary" onClick={cancelEnableNotification}>
        Not Now
      </Button>
    </div>
  );
};

export default AllowNotificationPage;

import fetchApi from "../fetchApi";

const getMatch = async (token: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/match`,
    method: "GET",
    token,
  });
};

export default getMatch;

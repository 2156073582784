import React, { useEffect, useRef, useState, forwardRef } from "react";
import "./styles.scss";
import playCircleWhite from "assets/icons/play-circle-white.svg";
import pausePinkIcon from "assets/icons/pause-pink-icon.svg";

interface IAudio {
  audioSrc: string;
  duration?: number;
  className?: string;
  onPlay?: () => void;
}

const AudioWaveform = forwardRef<HTMLDivElement, IAudio>((props, ref) => {
  const { audioSrc, duration, className, onPlay } = props;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [audioBuffer, setAudioBuffer] = useState<Float32Array | null>(null);
  const [isReadingAudio, setIsReadingAudio] = useState(false);
  const [pausedBar, setPausedBar] = useState<boolean | null>(false); // Armazena a última barra pausada

  useEffect(() => {
    const context = new window.AudioContext();
    setAudioContext(context);

    fetch(audioSrc)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
      .then((buffer) => {
        setAudioBuffer(buffer.getChannelData(0)); // get dates from left channel
      });

    return () => {
      context.close();
    };
  }, [audioSrc]);

  useEffect(() => {
    if (!audioBuffer || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    const barCount = 36; // Number Bars
    const step = Math.ceil(audioBuffer.length / barCount); // Number of Step

    if (canvasCtx) {
      canvasCtx.clearRect(0, 0, width, height); // Clear canvas

      const barWidth = 2;
      const spaceWidth = 2;
      const maxBarHeight = 24;
      const minBarHeight = 2;
      const halfHeight = height / 2;

      // Calcule a barra atual apenas se o áudio estiver tocando.
      const currentBar =
        isReadingAudio || pausedBar
          ? Math.floor(
              ((audioRef.current?.currentTime || 0) / (duration || 1)) *
                barCount
            )
          : -1; // Nenhuma barra é colorida se não estiver tocando.

      for (let i = 0; i < barCount; i++) {
        let min = 1.0;
        let max = -1.0;

        // Calcule min e max para cada barra
        for (let j = 0; j < step; j++) {
          const datum = audioBuffer[i * step + j];
          if (datum < min) min = datum;
          if (datum > max) max = datum;
        }

        // Calcule posições x e y
        const x = i * (barWidth + spaceWidth); // Espaço entre as barras
        const yMin = (1 + min) * halfHeight;
        const yMax = (1 + max) * halfHeight;

        // Calcule altura da barra
        const barHeight = Math.max(
          minBarHeight,
          Math.min(maxBarHeight, yMax - yMin)
        );

        // Determine a cor da barra.
        canvasCtx.fillStyle = i <= currentBar ? "#EE4E87" : "#757575";
        canvasCtx.fillRect(x, halfHeight - barHeight / 2, barWidth, barHeight);
      }
    }
  }, [audioBuffer, audioRef.current?.currentTime]);

  const formatSecondsToTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs.toString().padStart(2, "0")}`;
  };

  const [durationAudio, setDurationAudio] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [displayedTime, setDisplayedTime] = useState<string>(
    formatSecondsToTime(duration ?? 0)
  );

  useEffect(() => {
    const audio = audioRef.current;

    if (audio && duration) {
      setDurationAudio(duration);

      audio.ontimeupdate = () => {
        if (audio.duration > 0) {
          setCurrentTime(audio.currentTime);
          setDisplayedTime(formatSecondsToTime(audio.currentTime));
        }
      };

      audio.onended = () => {
        setIsReadingAudio(false);
        setPausedBar(false);
        setCurrentTime(0);
        setDisplayedTime(formatSecondsToTime(audio.currentTime));
      };
    }
  }, [duration]);

  const playAudioHandler = () => {
    const audio = audioRef.current;

    if (audio) {
      if (isReadingAudio) {
        audio.pause();
        setPausedBar(true);
        setIsReadingAudio(false);
      } else {
        audio.play();
        setIsReadingAudio(true);
        setPausedBar(false);
      }
    }
  };

  const handleDivClick = () => {
    if (onPlay) {
      onPlay();
    }
    playAudioHandler();
  };

  return (
    <div
      ref={ref}
      className={`audio-wave ${className ? className : ""}`}
      onClick={handleDivClick}
    >
      <p className="audio-size-paragraph no-margin">{displayedTime}</p>
      <canvas ref={canvasRef} width="142" height="24" />
      <audio
        ref={audioRef}
        src={audioSrc}
        controls
        style={{ display: "none" }}
      />
      <button className="play-pause-button" onClick={playAudioHandler}>
        <img
          src={isReadingAudio ? pausePinkIcon : playCircleWhite}
          alt={isReadingAudio ? "pause icon pink" : "play icon pink"}
        />
      </button>
    </div>
  );
});

export default AudioWaveform;

import fetchApi from "../fetchApi";

const getPackages = async (token: string) => {
  return await fetchApi({
    service: "packages",
    method: "GET",
    endPoint: ``,
    token,
  });
};

export default getPackages;

import React, { ReactNode } from "react";
import "./styles.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quintenary"
    | "iconButton"
    | "sexternary"
    | "septenary";
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const Button: React.FC<ButtonProps> = (props) => {
  const { buttonStyle, children, className, onClick, ...rest } = props;
  return (
    <button
      id="button"
      className={`${buttonStyle}${
        buttonStyle === "quintenary" ? " background-blur-gray " : ""
      } ${className ?? ""}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;

import { useState } from "react";

import "./styles.scss";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";

const UserSettingsMyOffersPage: React.FC = () => {
  const [chosenType, setChosenType] = useState("");
  const { setPrincipalScreen } = useDeskNavigation();

  return (
    <div className="user-settings">
      <div>
        <PageTitle
          title={"My Offers"}
          subtitle={"Settings"}
          onBackClick={() => setPrincipalScreen("/user-settings")}
          className="margin-hor-16"
        />
      </div>

      <div className="notifications-container">{"code here"}</div>
    </div>
  );
};

export default UserSettingsMyOffersPage;

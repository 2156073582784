import { useState } from "react";
import "./styles.scss";
import LockIcon from "assets/icons/lock-icon-gray.svg";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import ChangePassword from "../../api/user/changePassword";
import { useToast } from "hooks/useToast";
const UserSettingsChangePasswordPage: React.FC = () => {
  const [userCurrentPassword, setUserCurrentPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userNewPasswordConfirm, setUserNewPasswordConfirm] = useState("");
  const { showToast } = useToast();
  const [newPasswordWarning, setNewPasswordWarning] = useState(false);
  const [confirmNewPasswordWarning, setConfirmNewPasswordWarning] =
    useState(false);
  const [currentPasswordWarning, setCurrentPasswordWarning] = useState(false);

  const { userInfo } = useUserInfo();
  const { setPrincipalScreen } = useDeskNavigation();
  const resetPasswordSubmit = async () => {
    if (!userCurrentPassword) {
      showToast({
        description: "Check current password, please try again",
        title: "Reset password",
        type: "password",
      });

      setCurrentPasswordWarning(true);

      setTimeout(() => {
        setCurrentPasswordWarning(false);
      }, 3000);
    }
    if (!userNewPassword) {
      showToast({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "password",
      });

      setNewPasswordWarning(true);
      setTimeout(() => {
        setNewPasswordWarning(false);
      }, 3000);
    }
    if (!userNewPasswordConfirm) {
      showToast({
        description: "Check your credentials and, please, try again",
        title: "Reset password",
        type: "password",
      });

      setConfirmNewPasswordWarning(true);

      setTimeout(() => {
        setConfirmNewPasswordWarning(false);
      }, 3000);
    }
    if (
      userNewPassword === userNewPasswordConfirm &&
      userNewPassword.length >= 8
    ) {
      if (
        userNewPassword !== "" &&
        userNewPasswordConfirm !== "" &&
        userCurrentPassword
      ) {
        const update = await ChangePassword(userInfo.access_token, {
          oldPassword: userCurrentPassword,
          newPassword: userNewPassword,
        });

        if (update?.status === 200) {
          showToast({
            description: "Success changing passwords",
            title: "Reset password",
            type: "success",
          });
        } else {
          showToast({
            description:
              "Server error changing password or incorrect passwords, please try again later",
            title: "Fail reset password",
            type: "info",
          });
        }
      }
    } else {
      showToast({
        description:
          "Your password should be at least 8 characters long and equal to.",
        title: "Reset password",
        type: "password",
      });

      setNewPasswordWarning(true);
      setConfirmNewPasswordWarning(true);

      setTimeout(() => {
        setNewPasswordWarning(false);
        setConfirmNewPasswordWarning(false);
      }, 3000);
    }
  };

  const cancelSubmit = () => {
    setPrincipalScreen("/user-settings-account");
  };

  return (
    <div className="user-settings-change-password">
      <PageTitle
        title={"Password & security"}
        subtitle={"Account Security"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
        className="margin-hor-16"
      />
      <div className="change-password-container margin-hor-24">
        <SubText className="margin-vert-16">
          Your password should be at least 8 characters long
        </SubText>
        <InputText
          value={userCurrentPassword}
          placeholder="Current Password"
          onChangeInput={(e) => setUserCurrentPassword(e)}
          inputType="password"
          isWarning={currentPasswordWarning}
        />
        <div className="separator" />
        <div className="password-container">
          <InputText
            value={userNewPassword}
            placeholder="New Password"
            onChangeInput={(e) => setUserNewPassword(e)}
            inputType="password"
            isWarning={newPasswordWarning}
          />
          <InputText
            value={userNewPasswordConfirm}
            placeholder="Confirm New Password"
            onChangeInput={(e) => setUserNewPasswordConfirm(e)}
            inputType="password"
            isWarning={confirmNewPasswordWarning}
          />
        </div>
        <SubText
          icon={LockIcon}
          altIcon="clock icon"
          className="margin-vert-16"
        >
          Never share your credentials with anyone.
        </SubText>{" "}
        <div className="buttons-container margin-bottom-24">
          <Button
            buttonStyle="primary"
            onClick={resetPasswordSubmit}
            className="margin-bottom-16"
          >
            Reset Password
          </Button>
          <Button buttonStyle="quaternary" onClick={cancelSubmit}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
export default UserSettingsChangePasswordPage;

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Virtual } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import "./styles.scss";

import NewProfile from "components/Cards/NewProfile";
import PageTitle from "components/PageTitles";
import SendRosesModal from "components/Chat/ChatModals/SendRosesModal";

import { useChat } from "contexts/openedChatContext";
import { useDeskNavigation, Params } from "hooks/useDeskNavigation";
import { useLoginSignUpNavigation } from "hooks/useLoginSignUpNavigation";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useUserInfo } from "hooks/userInfo";
import { useWebSocket } from "contexts/webSocketContext";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useAvailableRoses } from "contexts/availableRosesContext";

import { CreatorDetailsContext } from "contexts/creatorDetails";
import { FavoritesCatalogContext } from "contexts/favoritesCatalogTrigger";
import { FavoritesListContext } from "contexts/favoritesListTrigger";
import { MomentsTriggerContext } from "contexts/momentTrigger";
import { ToggleChatOpenContext } from "contexts/toggleChatOpen";

import CreateChatContact from "api/postCreateChatContact";
import deleteRemoveFromFavorites from "api/favorite/deleteRemoveFromFavorites";
import getCreatorSettingsGoalProgress from "api/creatorSettings/getCreatorSettingsGoalProgress";
import getCreatorSettingsGoalUser from "api/creatorSettings/getCreatorSettingsGoalUser";
import getUserPreferences from "api/userPreferences/getUserPreferences";
import getUsersListAll, {
  GetUsersListAllQueryParams,
} from "api/user/getUsersListAll";
import patchUserPreferences, {
  PatchUserPreferencesBodyDataRequest,
} from "api/userPreferences/patchUserPreferences";
import postAddToFavorite from "api/favorite/postAddToFavorite";
import postCreatorSettingsGoalDonate from "api/creatorSettings/postCreatorSettingsGoalDonate";

import { Chat } from "types/chatInfo";
import "swiper/css";
import "swiper/css/pagination";
import { localStorageClear } from "utils/localStorageClear";

import { IGoal } from "types/goal";
import { catalogCreatorsContext } from "contexts/catalogCreatorsContext";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";
import { ICatalogResponse, PhotosProfile } from "types/catalog";
import verifyToken from "utils/verifyToken";

const ProducerInteractionPage: React.FC = () => {
  const {
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    componentFeed,
    params,
  } = useDeskNavigation();
  const { userInfo } = useUserInfo();
  const { setActiveChatId, activeChatId } = useChat();
  const { getChats } = useWebSocket();
  const { windowSize } = useWindowWidthSize();
  const { isChatPageOpen, setIsChatPageOpen } = useContext(
    ToggleChatOpenContext
  );
  const { setFavoritesListTrigger } = useContext(FavoritesListContext);
  const { setMomentTrigger } = useContext(MomentsTriggerContext);
  const { setCreatorDetails, creatorDetails } = useContext(
    CreatorDetailsContext
  );

  const [chats, setChats] = useState<Chat[]>([]);
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>(
    {} as ICatalogResponse
  );
  const [userIdSelected, setUserIdSelected] = useState<string>("");
  const [photoProducer, setPhotoProducer] = useState<PhotosProfile[] | []>([]);
  const [photoSelect, setPhotoSelect] = useState("");
  const [videoPublic, setVideoPublic] = useState<string | null>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeChatIdState, setActiveChatIdState] = useState("");
  const [pageParams, setPageParams] = useState<Params>({} as Params);
  const [goal, setGoal] = useState<IGoal | undefined>(undefined);
  const [goalProgress, setGoalProgress] = useState<string | undefined>("0%");
  const [avatarProfile, setAvatarProfile] = useState("");
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);
  const { catalogList } = useContext(catalogCreatorsContext);
  const { setOpenUseModal, setModalContent } = useModal();
  const { showToast } = useToast();

  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();

  const checkAuth = useAuthCheckOpenModal();
  const pastTokenStorage = localStorage.getItem("user-info-past-token");
  const [photosUrl, setPhotosUrl] = useState<any[]>();
  // Set unlocked chats
  const getListChats = useCallback(async () => {
    getChats({}, (res: Chat[]) => {
      const openedChatsIds: string[] | [] = res?.map(
        (chat: Chat) => chat?.chatParticipants[0]?.user?.userId
      );
      setOpenedChatUserIds(openedChatsIds);
      setChats(res);
    });
  }, [getChats, userIdSelected]);

  useEffect(() => {
    if (creatorDetails) {
      setUserIdSelected(creatorDetails.userId);
    }
  }, [creatorDetails]);

  useEffect(() => {
    getListChats();
  }, [getChats, getListChats]);

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setPageParams(params);
    }
  }, [params]);

  useEffect(() => {
    if (isChatPageOpen) {
      sendMessageClick();
    }
  }, [userIdSelected]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params]);

  useEffect(() => {
    const producerDetail = async () => {
      if (creatorDetails) {
        setIsProfileFavorite(creatorDetails?.isFavorite);
        setProducerDetails(creatorDetails);
        setPhotosUrl(creatorDetails.profile.photos.map((photo) => photo.url));
        setPhotoProducer(creatorDetails.profile.photos);
        setAvatarProfile(
          creatorDetails.profile.photos[0].thumbnailUrl ||
            creatorDetails.profile.photos[0].mediumUrl ||
            creatorDetails.profile.photos[0].url
        );

        if (creatorDetails.profile.cover) {
          setVideoPublic(creatorDetails.profile.cover?.url);
          setPhotoSelect(creatorDetails.profile.cover?.url);
        } else {
          setVideoPublic(null);
          setPhotoSelect(creatorDetails.profile.photos[0]?.url);
        }
        setCurrentIndex(0);
      }
    };
    if (verifyToken()) goalUser();
    producerDetail();
  }, [userIdSelected, pastTokenStorage]);

  // useEffect(() => {
  //   getCatalogData();
  // }, [userInfo.access_token]);

  const backPageHandler = () => {
    if (componentFeed) {
      setPrincipalScreen("");
    } else {
      setPrincipalScreen("");
      setSecondaryScreen("");
      setTertiaryScreen("");
      setIsChatPageOpen(false);
    }
  };

  const handlerFeedButtonClick = () => {
    if (!checkAuth()) return;
    const creatorId = userIdSelected ? userIdSelected : pageParams.userId;
    const chatId = activeChatIdState
      ? activeChatIdState
      : (activeChatId as string);

    setFeedScreen("/feed", {
      userId: creatorId,
      chatId,
      isLocked: JSON.stringify(openedChatUserIds?.includes(creatorId)),
    });
  };

  const clickLikeHandler = async () => {
    if (!checkAuth()) return;

    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(
        userInfo.access_token,
        producerDetails.userId
      );
      switch (response.status) {
        case 201:
        case 204:
          setFavoritesListTrigger((prev) => !prev);
          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });

          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        producerDetails.userId
      );

      switch (response.status) {
        case 204:
        case null:
          setFavoritesListTrigger((prev) => !prev);

          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again 0000",
            type: "error",
          });

          break;
      }
    }

    setMomentTrigger((prev) => !prev);
  };

  const sendMessageClick = async () => {
    const creatorId = userIdSelected ? userIdSelected : pageParams.userId;

    if (creatorId) {
      const isOpenedChat = openedChatUserIds?.includes(creatorId);
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          setActiveChatId(response.res.chatId);
          setActiveChatIdState(response.res.chatId);
          break;

        default:
          break;
      }
      setSecondaryScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId: creatorId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };

  const sendMessageHandler = () => {
    if (!checkAuth()) return;

    if (userInfo.account_type === "CREATOR") return;
    setIsChatPageOpen(true);
    sendMessageClick();
  };

  /*  const getCatalogData = async () => {
    try {
       const response = await getUserPreferences(userInfo.access_token);
      const userPreferences: PatchUserPreferencesBodyDataRequest = response.res;

      const prepareParams: Partial<GetUsersListAllQueryParams> = {
        ageMax: userPreferences?.preferedAgeMax,
        ageMin: userPreferences?.preferedAgeMin,
        ethnicity: userPreferences?.preferedEthnicities,
        eyeColor: userPreferences?.preferedEyeColors,
        hairColor: userPreferences?.preferedHairColors,
        heightMax: userPreferences?.preferedHeightMax,
        heightMin: userPreferences?.preferedHeightMin,
        languages: userPreferences?.preferedLanguages,
        nationality: userPreferences?.preferedNationalities,
        weightMax: userPreferences?.preferedWeightMax,
        weightMin: userPreferences?.preferedWeightMin,
        take: 100,
        // cursor:""
      };

      Object.keys(prepareParams).forEach(
        (key) =>
          prepareParams[key as keyof GetUsersListAllQueryParams] ===
            undefined &&
          delete prepareParams[key as keyof GetUsersListAllQueryParams]
      );

      const listCatalogRequest = await getUsersListAll(
        userInfo.access_token,
        prepareParams
      );

      if (listCatalogRequest?.status === 200) {
        setCatalogData(listCatalogRequest.res);
      }
      if (catalogList) {
        setCatalogData(catalogList);
      }
    } catch (error) {
      console.error(error);
    }
  }; */

  const fetchGoalProgress = async (goalId: string) => {
    try {
      const goalProgressResponse = await getCreatorSettingsGoalProgress(
        userInfo.access_token,
        goalId
      );

      if (goalProgressResponse.status !== 200) throw new Error();

      const percentageProgress =
        goalProgressResponse?.res?.progressPercentage ?? "0%";

      setGoalProgress(() =>
        percentageProgress > 100 ? "100%" : `${percentageProgress}%`
      );

      return true;
    } catch (error) {
      setGoalProgress(undefined);
      return false;
    }
  };

  const goalUser = async () => {
    try {
      if (!userIdSelected) throw new Error();

      const goalUserResponse = await getCreatorSettingsGoalUser(
        userInfo.access_token,
        userIdSelected
      );

      if (goalUserResponse.status !== 200) throw new Error();

      setGoal(goalUserResponse.res);
      await fetchGoalProgress(goalUserResponse.res.goalId);
    } catch (error: unknown) {
      setGoal(undefined);
      setGoalProgress(undefined);

      if (typeof error === "object" && error !== null && "response" in error) {
        const err = error as { response?: { status?: number } };
        return {
          res: null,
          status: err.response?.status || 500,
        };
      }
      return { res: null, status: 500 };
    }
  };

  const donateToCreator = async (amount: number) => {
    try {
      if (!checkAuth() || availableRoses <= 0 || !goal) throw new Error();

      const donate = await postCreatorSettingsGoalDonate({
        token: userInfo.access_token,
        amount,
        goalId: goal?.goalId,
      });

      await fetchGoalProgress(donate.res.goalId);

      return donate;
    } catch (error) {
      showToast({
        type: "error",
        title: "Donation failed",
        description:
          "An error occurred while processing your donation. Please try again.",
      });
      return false;
    }
  };

  const openModal = () => {
    setOpenUseModal(true);
    setModalContent(
      <SendRosesModal
        title="Send Roses"
        description={
          <>
            Capture Your Love's Heart: <br />
            Send <b>Roses</b> Now!
          </>
        }
        showLogo
        showSendMessage
        typeModal="send"
        maxValue={availableRoses}
        onClose={() => setOpenUseModal(false)}
        rosesMessageCallback={async (roses) => {
          const res = await donateToCreator(roses);
          if (res) {
            updateSubtractAvailableRoses(roses);
          }
          setOpenUseModal(false);
        }}
        className="padding-16"
      />
    );
  };
  return (
    <div id="producer-interaction">
      <PageTitle
        onBackClick={backPageHandler}
        hasCloseIcon={windowSize >= 768}
        className="padding-left-16"
        isProducer
      />

      {/* <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={false}
        pagination={false}
        grabCursor={true}
        onSlideChange={(swiper: SwiperClass) => {
          const activeProfile = catalogList?.[swiper.activeIndex];

          if (activeProfile) {
            setUserIdSelected(activeProfile.userId);
            setCreatorDetails(activeProfile);
          }
        }}
        loop={false}
      >
        {catalogList?.map((profile, index) => (
          <SwiperSlide key={index}>
            <NewProfile
              openFeed={handlerFeedButtonClick}
              goal={goal}
              goalProgress={goalProgress}
              // isFeedOpen={Boolean(componentFeed)}
              isIA={true}
              age={producerDetails?.profile?.age}
              // isVerified
              isVip={producerDetails?.isVip}
              idProfile={userIdSelected}
              name={producerDetails?.displayname}
              principalPhoto={photoSelect}
              profilePhoto={avatarProfile}
              // profileDetails={["Brazilian", "5’4” / 1,67m", "56kg / 123lb"]}
              // rating={producerDetails.profile.rating || Math.floor(Math.random() * 6)}
              heartButtonClick={clickLikeHandler}
              sendMessageClick={sendMessageHandler}
              openModal={openModal}
              buttonStyle={
                isProfileFavorite ? "transparentActive" : "transparent"
              }
              icon={
                isProfileFavorite
                  ? "icon-heart-solid"
                  : "icon-heart-outline icon-gray-light"
              }
              iconSecondary={"icon-messages icon-gray-light"}
              photos={photoProducer}
              changeNext={() => {
                setCurrentIndex((prevIndex) => {
                  if (prevIndex === 0) {
                    if (photoSelect?.endsWith(".mp4")) {
                      setPhotoSelect(photoProducer[prevIndex]?.url);
                    } else {
                      setPhotoSelect(photoProducer[prevIndex + 1]?.url);
                    }
                    return 1;
                  }

                  const nextIndex = prevIndex + 1;
                  if (nextIndex < photoProducer.length) {
                    setPhotoSelect(photoProducer[prevIndex]?.url || "");
                    return nextIndex;
                  }

                  setPhotoSelect(
                    photoProducer[photoProducer.length - 1]?.url || ""
                  );
                  return prevIndex;
                });
              }}
              changePrevious={() => {
                setCurrentIndex((prevIndex) => {
                  if (prevIndex > 0) {
                    setPhotoSelect(photoProducer[prevIndex - 1]?.url || "");
                    return prevIndex - 1;
                  }

                  if (prevIndex === 0 && videoPublic) {
                    setPhotoSelect(videoPublic);
                    return -1;
                  }

                  if (prevIndex === -1 && videoPublic) {
                    setPhotoSelect(videoPublic);

                    return -1;
                  }

                  return prevIndex - 1;
                });
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper> */}
      <NewProfile
        isFeedOpen={Boolean(componentFeed)}
        openFeed={handlerFeedButtonClick}
        isIA={true}
        age={producerDetails?.profile?.age}
        // isVerified
        isVip={producerDetails?.isVip}
        idProfile={userIdSelected}
        name={producerDetails?.displayname}
        principalPhoto={photoSelect}
        profilePhoto={avatarProfile}
        // profileDetails={["Brazilian", "5’4” / 1,67m", "56kg / 123lb"]}
        // rating={producerDetails.profile.rating || Math.floor(Math.random() * 6)}
        heartButtonClick={clickLikeHandler}
        sendMessageClick={sendMessageHandler}
        buttonStyle={isProfileFavorite ? "transparentActive" : "transparent"}
        icon={
          isProfileFavorite
            ? "icon-heart-solid"
            : "icon-heart-outline icon-gray-light"
        }
        iconSecondary={"icon-messages icon-gray-light"}
        photos={photoProducer}
        changeNext={() => {
          setCurrentIndex((prevIndex) => {
            if (prevIndex === 0 && photoSelect?.endsWith(".mp4")) {
              setPhotoSelect(photoProducer[prevIndex]?.url);
              return 1;
            }
            if (prevIndex === 0 && !photoSelect?.endsWith(".mp4")) {
              setPhotoSelect(photoProducer[prevIndex + 1]?.url);
              return 1;
            }

            const nextIndex = prevIndex + 1;
            if (nextIndex < photoProducer.length) {
              setPhotoSelect(photoProducer[prevIndex]?.url || "");
              return nextIndex;
            }

            setPhotoSelect(photoProducer[photoProducer.length - 1]?.url || "");
            return prevIndex;
          });
        }}
        changePrevious={() => {
          setCurrentIndex((prevIndex) => {
            if (prevIndex === 0 && videoPublic?.endsWith(".mp4")) {
              setPhotoSelect(videoPublic);
              return 0;
            }
            if (prevIndex > 0) {
              const previousIndex = prevIndex - 1;
              setPhotoSelect(photoProducer[previousIndex]?.url || "");
              return previousIndex;
            }
            return prevIndex;
          });
        }}
      />

      {/* <div className="list-items-details margin-hor-24">
        {Object.entries(detailsMock).map(([detail, value], index) => (
          <ListItemSecondary
            key={index}
            showLabel
            labelContent={
              detail === "rosesToConnect"
                ? "Roses to Connect"
                : detail === "rosesToMeet"
                ? "Roses to Meet"
                : detail === "hairColor"
                ? "Hair Color"
                : detail.charAt(0).toUpperCase() + detail.slice(1)
            }
            showValue
            valueContent={value || ""}
            showSeparator={Object.entries(detailsMock).length > index + 1}
          />
        ))}
      </div>

      <div className="photo-album padding-hor-24">
        {cardsLibrary.map((card, index) => (
          <CardsMediaLibrary
            key={index}
            image={card.img}
            altImage={card.alt}
            rosesPrice={card.price}
            unlockClick={() => {}}
            isBlurred={parseFloat(card.price) > 0}
          />
        ))}
      </div>

      <div className="reviews-wrapper">
        <div className="header-review-wrapper">
          <img src={LockIcon} alt="lock icon" />
          <p>REVIEWS</p>
          <p>
            Begin interaction <br /> to see user's reviews
          </p>
        </div>

        <ListItemSecondary
          showLabel
          labelContent="Connect & Chat"
          showValue
          valueContent={detailsMock.rosesToConnect}
          showSeparator
        />
        <ListItemSecondary
          showLabel
          labelContent="Virtual Meetup"
          showValue
          valueContent={detailsMock.rosesToMeet}
        />
        <div className="footer-interaction-divider margin-vert-24" />
        <div className="buttons-interaction-container">
          <Button buttonStyle="primary" onClick={sendMessageHandler}>
            Send Message
          </Button>
          <ButtonIcon
            onClick={clickLikeHandler}
            buttonStyle={
              isProfileFavorite ? "heart-pink-gradient" : "heart-white-gradient"
            }
          />
        </div>
      </div>
 */}
    </div>
  );
};

export default ProducerInteractionPage;

import React, { useRef, useState } from "react";
import "./styles.scss";
import ImageCircleBorder from "components/ImageCircleBorder";
import Button from "components/Buttons/Button";
import { formatTimeFromNowToDate } from "utils/formatTimeFromNowToDate";
import RosesIconPink from "assets/icons/logos/logo-fill-pink.svg";

interface MediaModalCardProps {
  className?: string;
  media: ICreatorMediaInfo;
  clickInMediaCallBack: (a: boolean) => void;
  clickProfileHandlerCallback: (userId: string) => void;
  unlockMediaCallback: (a: any) => void;
}

export type ICreatorMediaInfo = {
  creatorName: string;
  type: "photo" | "video";
  mediaIsAbleToShow: boolean;
  media: string;
  creatorAvatar: string;
  creatorId: string;
  isPaidContent: boolean;
  unlockPrice: number;
};

const MediaModalCard: React.FC<MediaModalCardProps> = (props) => {
  const {
    className,
    unlockMediaCallback,
    media,
    clickInMediaCallBack,
    clickProfileHandlerCallback,
  } = props;
  const videoMediaModalRef = useRef<HTMLVideoElement>(null);

  const mediaStop = () => {
    clickInMediaCallBack(true);
  };

  const mediaContinue = () => {
    clickInMediaCallBack(false);
  };

  return (
    <section id="media-card" className={className ?? ""}>
      <div
        className="media-card__media-wrapper"
        onMouseDown={mediaStop}
        onMouseUp={mediaContinue}
        onMouseLeave={mediaContinue}
        onTouchStart={mediaStop}
        onTouchEnd={mediaContinue}
      >
        {media.type === "photo" || !media.mediaIsAbleToShow ? (
          <img
            src={media.media}
            alt="media media"
            className="media-wrapper__media-photo"
          />
        ) : (
          <div className="media-wrapper__media-video">
            <video
              key={media.media}
              ref={videoMediaModalRef}
              autoPlay
              playsInline
              disablePictureInPicture
              controls={false}
              preload="auto"
              className="media-video__video"
              src={media.media}
            ></video>
          </div>
        )}
      </div>
      <header className="media-details__creator-media-details">
        <ImageCircleBorder
          centerImage={media.creatorAvatar}
          centerImageAlt={`${media.creatorName} avatar`}
          size={40}
          onClick={() => clickProfileHandlerCallback(media.creatorId)}
        />

        <div className="creator-media-details__names-container">
          <p className="no-margin profile-name">{media.creatorName}</p>
          <p className="no-margin profile-subtext">{media.creatorName}</p>
        </div>
      </header>
      {!media.isPaidContent ? (
        <div className="locked-info-container">
          <div className="locked-info-container__content">
            <img src={RosesIconPink} alt="lock icon" />
            <h3>{media.unlockPrice}</h3>
            <p>
              Step into the extraordinary — this reveal is yours and yours
              alone. Ready to see what’s been waiting for you?
            </p>
            <Button
              buttonStyle="quintenary"
              className="margin-top-16"
              onClick={unlockMediaCallback}
            >
              Unlock
            </Button>
          </div>
        </div>
      ) : (
        false
      )}
    </section>
  );
};

export default MediaModalCard;

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import photoDefault from "assets/images/roses-photo-default.png";
import { ICatalogResponse } from "../../types/catalog";
import { IMoments, IMomentsUnseen } from "../../types/moments";
import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useModal } from "../../hooks/useModal";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import { MomentsTriggerContext } from "../../contexts/momentTrigger";
import { FavoritesCatalogContext } from "../../contexts/favoritesCatalogTrigger";
import { useIconContext } from "../../contexts/iconNavContext";
import { useChat } from "../../contexts/openedChatContext";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import MomentsCircles from "components/Moments/MomentsCircles";
import getUsersListAll, {
  GetUsersListAllQueryParams,
} from "../../api/user/getUsersListAll";
import getMomentsUnseen from "../../api/moments/getMomentsUnseen";
import getMomentSeen from "../../api/moments/getMomentsSeen";
import AddMediaPage from "../AddMediaPage";
import { FavoritesListContext } from "../../contexts/favoritesListTrigger";
import { useSearchCatalog } from "hooks/useSearchCatalog";
import getUserMoments from "api/moments/getUserMoments";
import getUserPreferences from "api/userPreferences/getUserPreferences";
import { PatchUserPreferencesBodyDataRequest } from "api/userPreferences/patchUserPreferences";
import { useMediaModal } from "hooks/useMediaModal";
import getPublicCreators from "api/publicCreators/getPublicCreators";
import { CreatorDetailsContext } from "contexts/creatorDetails";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";
import verifyToken from "utils/verifyToken";
import { catalogCreatorsContext } from "contexts/catalogCreatorsContext";

const CatalogPage: React.FC = () => {
  // Contexts
  const { userInfo } = useUserInfo();
  const { setContentMomentMediaModal, setOpenUseMediaMomentModal } =
    useMediaModal();
  const { handleButtonClick, getIconClass, activeIcon } = useIconContext();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setPrincipalScreen, setSecondaryScreen, setTertiaryScreen } =
    useDeskNavigation();
  const { favoritesCatalogTrigger, setFavoritesCatalogTrigger } = useContext(
    FavoritesCatalogContext
  );
  const { favoritesListTrigger } = useContext(FavoritesListContext);
  const { momentTrigger } = useContext(MomentsTriggerContext);
  const { isChatPageOpen } = useContext(ToggleChatOpenContext);
  const { activeChatId } = useChat();

  // Local States
  const [classWidth, setClassWidth] = useState("mobile-width");
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [counters, setCounters] = useState<string[]>([]); // MOCK
  const [filterType, setFilterType] = useState<
    "all" | "vip" | "favorites" | "guys" | "top-creators" | "girls"
  >("all");
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);
  const [catalogFilteredData, setCatalogFilteredData] = useState<
    ICatalogResponse[] | []
  >([]);
  const [momentsUnseen, setMomentsUnseen] = useState<IMomentsUnseen[] | []>([]);
  const [momentsSeen, setMomentsSeen] = useState<IMomentsUnseen[] | []>([]);
  const [activatedButton, setActivatedButton] = useState(0);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [creatorSelfMoments, setCreatorSelfMoments] = useState<IMoments[] | []>(
    []
  );
  const { setCreatorDetails } = useContext(CreatorDetailsContext);

  const { searchValue } = useSearchCatalog();
  const checkAuth = useAuthCheckOpenModal();

  const { setCatalogList } = useContext(catalogCreatorsContext);

  useLayoutEffect(() => {
    const filteredCatalogData = catalogData
      .filter((profileCard) => {
        switch (filterType) {
          case "top-creators":
            return profileCard.isVip;
          case "girls":
            return (
              profileCard?.profile?.gender?.toLowerCase() === "woman" ||
              profileCard?.profile?.gender === null
            );
          case "guys":
            return profileCard?.profile?.gender?.toLowerCase() === "man";
          default:
            return profileCard;
        }
      })
      .sort((a, b) => (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0));

    const search = filteredCatalogData.filter((catalog) =>
      catalog.displayname.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    setCatalogFilteredData(search);
  }, [catalogData, filterType, searchValue]);

  useEffect(() => {
    getCatalogData(userInfo.access_token);
  }, [userInfo.access_token, favoritesListTrigger]);

  useEffect(() => {
    if (!verifyToken()) return;

    momentsSeenRequest();
    momentsUnseenRequest();
    getSelfMoments();
  }, [momentTrigger]);

  useEffect(() => {
    if (activeIcon === "grid") {
      buttonGroupClickHandler("all");
      // the next line is necessary to activate the useEffect
      setActivatedButton(4);
      // >>
      setTimeout(() => {
        setActivatedButton(1);
      }, 100);
    }
  }, [handleButtonClick]);

  useEffect(() => {
    if (favoritesCatalogTrigger) {
      setCatalogFilteredData(
        catalogData
          .filter((profile) => profile.isFavorite)
          .sort((a, b) => (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0))
      );
    } else {
      const filteredCatalogData = catalogData
        .filter((profileCard) => {
          switch (filterType) {
            case "top-creators":
              return profileCard.isVip;
            case "girls":
              return (
                profileCard?.profile?.gender?.toLowerCase() === "woman" ||
                profileCard?.profile?.gender === null
              );
            case "guys":
              return profileCard?.profile?.gender?.toLowerCase() === "man";
            default:
              return profileCard;
          }
        })
        .sort((a, b) => (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0));
      setCatalogFilteredData(filteredCatalogData);
    }
  }, [catalogData, favoritesCatalogTrigger, userInfo.access_token, filterType]);

  useEffect(() => {
    const generateRandomNumber = (index: number) => {
      const randomNum = Math.floor(Math.random() * 1799) + 200 + index * 100;
      return randomNum;
    };

    const formatCounter = (num: number) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
      }
      return num.toString();
    };

    const randomCounters = catalogData.map((_, index) => {
      const randomCounter = generateRandomNumber(index);
      return formatCounter(randomCounter);
    });

    setCounters(randomCounters);
  }, [catalogData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          let size = Math.floor(width + 48);

          if (size < 560) {
            setClassWidth("mobile-width");
          } else if (size >= 560 && size < 760) {
            setClassWidth("mobile-width-plus");
          } else if (size >= 760 && size < 1024) {
            setClassWidth("super-mobile-width");
          } else if (size >= 1024 && size < 1140) {
            setClassWidth("tablet-width");
          } else if (size >= 1140 && size < 1600) {
            setClassWidth("widescreen-width");
          } else {
            setClassWidth("hyper-widescreen-width");
          }
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  // const openLoginModal = () => {
  //   getCatalogDataWithoutLogin();
  //   setModalContent(
  //     <SignInSignUpModal navigate={navigate} setComponent={setComponent} />
  //   );
  //   setOpenUseModal(true);
  //   localStorageClear();
  // };

  const getSelfMoments = async () => {
    try {
      const request = await getUserMoments(
        userInfo.access_token,
        userInfo.user_id
      );
      switch (request.status) {
        case 200:
          const moments: IMoments[] = request.res;

          setCreatorSelfMoments(moments);
          break;
        case 401:
          // if (!checkAuth()) return;
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const catalogHandlerCallback = async (profile: ICatalogResponse) => {
    setCreatorDetails(profile);
    if (isChatPageOpen && verifyToken()) {
      setTimeout(() => {
        setSecondaryScreen("/personal-chat", {
          chatId: activeChatId ?? "",
          userId: profile.userId,
        });
      }, 0);
    } else {
      setSecondaryScreen("");
    }

    setTertiaryScreen("");

    setPrincipalScreen("/interaction", { userId: profile.userId });
  };

  const momentsSeenRequest = async () => {
    if (!verifyToken()) return;

    try {
      const resMomentsSeen = await getMomentSeen(userInfo.access_token, 10);

      switch (resMomentsSeen.status) {
        case 200:
          setMomentsSeen(resMomentsSeen.res);
          break;
        case 401:
          // if (! checkAuth()) return;

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const momentsUnseenRequest = async () => {
    try {
      const resMomentsUnseen = await getMomentsUnseen(
        userInfo.access_token,
        10
      );

      switch (resMomentsUnseen.status) {
        case 200:
          setMomentsUnseen(resMomentsUnseen.res);
          break;
        case 401:
          // if (! checkAuth()) return;

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCatalogDataWithoutLogin = async () => {
    try {
      const publicCreators = await getPublicCreators({ take: 100 });

      switch (publicCreators.status) {
        case 200:
          // setCatalogList(publicCreators.res);
          setCatalogData(publicCreators.res);

          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCatalogData = async (userToken: string) => {
    if (!verifyToken()) {
      getCatalogDataWithoutLogin();
    } else {
      try {
        const response = await getUserPreferences(userToken);
        const userPreferences: PatchUserPreferencesBodyDataRequest =
          response.res;

        switch (response.status) {
          case 200:
            const prepareParams: Partial<GetUsersListAllQueryParams> = {
              ageMax: userPreferences?.preferedAgeMax,
              ageMin: userPreferences?.preferedAgeMin,
              ethnicity: userPreferences?.preferedEthnicities,
              eyeColor: userPreferences?.preferedEyeColors,
              hairColor: userPreferences?.preferedHairColors,
              heightMax: userPreferences?.preferedHeightMax,
              heightMin: userPreferences?.preferedHeightMin,
              languages: userPreferences?.preferedLanguages,
              nationality: userPreferences?.preferedNationalities,
              weightMax: userPreferences?.preferedWeightMax,
              weightMin: userPreferences?.preferedWeightMin,
              take: 100,
              // cursor:""
            };

            Object.keys(prepareParams).forEach(
              (key) =>
                prepareParams[key as keyof GetUsersListAllQueryParams] ===
                  undefined &&
                delete prepareParams[key as keyof GetUsersListAllQueryParams]
            );

            const listCatalogRequest = await getUsersListAll(
              userToken,
              prepareParams
            );

            if (listCatalogRequest?.status === 200) {
              // setCatalogList(listCatalogRequest.res);
              setCatalogData(listCatalogRequest.res);

              if (activeIcon === "heart-outline") {
                const favorites = listCatalogRequest.res
                  .filter((profile: ICatalogResponse) => profile.isFavorite)
                  .sort(
                    (a: ICatalogResponse, b: ICatalogResponse) =>
                      (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0)
                  );

                setCatalogFilteredData(favorites);
              } else {
                setCatalogFilteredData(listCatalogRequest.res);
              }
            }

            break;

          case 401:
            break;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const buttonGroupClickHandler = (
    filter: "all" | "vip" | "favorites" | "guys" | "top-creators" | "girls"
  ) => {
    setFavoritesCatalogTrigger(false);
    setFilterType(filter);
    handleButtonClick("grid");
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "All",
      clickAction: () => buttonGroupClickHandler("all"),
      id: 1,
      textRight: catalogData.length.toString(),
    },
    {
      text: "Girls",
      clickAction: () => buttonGroupClickHandler("girls"),
      id: 2,
      textRight: catalogData
        .filter(
          (catalog) =>
            catalog.profile.gender === "WOMAN" ||
            catalog.profile.gender === null
        )
        .length.toString(),
    },
    {
      text: "Guys",
      clickAction: () => buttonGroupClickHandler("guys"),
      id: 3,
      textRight: catalogData
        .filter((catalog) => catalog.profile.gender === "MAN")
        .length.toString(),
    },
    {
      text: "Top Creators",
      clickAction: () => buttonGroupClickHandler("top-creators"),
      id: 4,
      textRight: catalogData
        .filter((catalog) => catalog.isVip)
        .length.toString(),
    },
  ];

  const addMomentHandler = () => {
    if (!checkAuth()) return;

    setModalContent(<AddMediaPage previousPage="home" />);
    setOpenUseModal(true);
  };

  const clickMoment = (userId: string, index: number) => {
    if (!checkAuth()) return;

    setContentMomentMediaModal({
      indexClicked: index,
      userIdToStarted: userId,
      momentStyle: true,
      allUnseenUserIds: momentsUnseen.map((moment) => moment.userId),
      allSeenUserIds: momentsSeen.map((moment) => moment.userId),
    });

    setOpenUseMediaMomentModal(true);
  };

  const classNamesCatalog = ["catalog-page", classWidth].join(" ").trim();

  return (
    <div className="catalog-page-wrapper">
      <div className="actions-wrapper">
        <MomentsCircles
          showAddMoment={userInfo.account_type === "CREATOR"}
          // showAddMoment
          clickMoment={clickMoment}
          addMomentClick={addMomentHandler}
          momentsDetails={{ momentsSeen, momentsUnseen }}
          className="add-moments-container__moments padding-left-24"
          creatorSelfMoments={creatorSelfMoments}
        />
        <ButtonNavigation
          buttons={buttonsGroup}
          className="button-navigation-catalog"
          activatedButton={activatedButton}
        />
      </div>

      <div ref={divRef} className={classNamesCatalog}>
        {catalogFilteredData?.map((profileCard, index) => {
          return (
            <div
              onClick={() => {
                if (catalogHandlerCallback) {
                  catalogHandlerCallback(profileCard);
                }
              }}
              //className={`profile-card ${index % 7 === 0 ? "grid-2" : ""} ${
              className={`profile-card ${index % 7 === 0 ? "" : ""} ${
                !isHovered ? "remove-linear-gradient" : ""
              }`}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                src={
                  hoveredIndex === index
                    ? profileCard?.profile.photos[1]?.url
                    : profileCard?.profile.photos[0]?.url || photoDefault
                }
                alt="catalog gallery"
                className="catalog-image"
                loading="lazy"
              />

              <div className="profile-title">
                <div className="user-info">
                  <div className="profile_dots">
                    <div className="right_dots">
                      {true && (
                        <span className="profile__icon icon icon-burn icon-md icon-white"></span>
                      )}
                      {true && (
                        <div className="ia-icon">
                          <span className="profile-ia-box">AI</span>
                        </div>
                      )}
                    </div>

                    <div className="statistics-info left_dots">
                      <span className="icon icon-heart-solid icon-sm icon-white"></span>
                      <p className="counter">12.3k</p>
                    </div>
                  </div>
                  <div className="profile-names">
                    <div className="user-title">
                      <p className="profile-info small no-margin">
                        {profileCard?.displayname}
                      </p>
                      {/*   {profileCard?.isVip && (
                        <span className="profile__icon icon icon-burn"></span>
                      )}
                      {profileCard.profile?.isAI && (
                        <span className="profile-ia-box">AI</span>
                      )} */}
                    </div>
                    {!profileCard?.profile.occupation ? (
                      <div className="user-stats">
                        <p className="counter">
                          {profileCard?.profile.age} years
                        </p>
                        {/* <div className="followers-counter">
                          <span className="icon icon-heart-solid icon-sm icon-gray-light"></span>
                          <p className="counter">{counters[index]}</p>
                        </div> */}
                      </div>
                    ) : (
                      <div className="user-stats">
                        <p className="counter">
                          {profileCard?.profile.age} |{" "}
                          {profileCard?.profile.occupation}
                        </p>
                        {/*    <div className="followers-counter">
                          <span className="icon icon-heart-solid icon-sm icon-gray-light"></span>
                          <p className="counter">{counters[index]}</p>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CatalogPage;

import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";

import CoverImage from "components/CoverImage";
import FeedCards from "components/Cards/FeedCards";
import Loading from "components/Loading";
import PageTitle from "components/PageTitles";

import { useUserInfo } from "hooks/userInfo";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useUnlockContent } from "hooks/useUnlockContent";
import { useToast } from "hooks/useToast";

import { useIconContext } from "contexts/iconNavContext";

import deletePosts from "api/posts/deletePosts";
import getPostsById from "api/posts/getPostsById";
import getPostsUnseen from "api/posts/getPostsUnseen";
import getPostsSeen from "api/posts/getPostsSeen";
import postPostsView from "api/posts/postPostsView";

import CoverPhoto from "assets/images/0542b1d078ce808d00317ebe68b3d139-full 2.png";
import logo from "assets/icons/logos/logo-fill-pink.svg";

const FeedGlobal: React.FC = () => {
  const { setMainScreen, setFeedScreen } = useDeskNavigation();
  const { handleButtonClick } = useIconContext();
  const { unlockContentSubmit } = useUnlockContent();

  const { userInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();
  const { showToast } = useToast();

  const divRef = useRef<HTMLDivElement | null>(null);
  const feedCardsContainer = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [diffPosts, setDiffPosts] = useState(0);
  const [hasViewUnseen, setHasViewUnseen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fallbackText, setFallbackText] = useState({
    text1: "You don't favorite a CREATOR yet",
    text2: "Favorite CREATORS to never miss a post!",
  });

  const [creatorPosts, setCreatorPosts] = useState<any[]>([]);

  const options = {
    root: divRef.current,
    rootMargin: "0px",
    threshold: 1.0,
  };

  const handlerDeletePost = useCallback(
    async (postId: string) => {
      try {
        await deletePosts(userInfo.access_token, { postId });
        setCreatorPosts((prevPosts) =>
          prevPosts.filter((post) => post.postId !== postId)
        );
      } catch (error) {
        showToast({
          type: "error",
          title: "Oops! Something went wrong",
          description:
            "Couldn't delete the post. Please try again in a few moments.",
        });
      }
    },
    [userInfo.access_token]
  );

  const handlerUnlockPost = useCallback(
    async (postId: string, price: number) => {
      try {
        await new Promise<void>((resolve, reject) => {
          unlockContentSubmit(postId, price, "feed", () => {
            resolve();
          });
        });

        const { res } = await getPostsById(userInfo.access_token, { postId });

        setCreatorPosts((prevPosts) => {
          return prevPosts.map((post) => {
            if (post.postId === postId) {
              return { ...post, ...res };
            }
            return post;
          });
        });
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, Try again",
          type: "error",
        });
      }
    },
    [unlockContentSubmit, userInfo.access_token]
  );

  useEffect(() => {
    const userPosts = async () => {
      try {
        const { res } = await getPostsUnseen(userInfo.access_token);

        if (res.posts.length < 10) {
          const { res: seenRes } = await getPostsSeen(userInfo.access_token, {
            take: 10 - res.posts.length,
          });
          setCreatorPosts((prevMessages) => [
            ...prevMessages,
            ...res.posts,
            ...seenRes.posts,
          ]);
        } else {
          setCreatorPosts(res.posts);
        }
      } catch (error) {
        setFallbackText({
          text1: "Oops! Something went wrong",
          text2: "We're working on it. Try again soon!",
        });
      } finally {
        setIsLoaded(!isLoaded);
      }
    };
    userPosts();
  }, [userInfo.access_token, userInfo.user_id]);

  useEffect(() => {
    if (itemsRef.current.length === 0) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const beholder = entry.target;

          const postId = beholder.id;

          const cards = creatorPosts.find(
            (feedCard) => feedCard.postId === postId
          );
          if (cards && !cards.hasSeen && userInfo.user_id !== cards.userId) {
            markFeedCardAsRead(postId);
          }

          observer.unobserve(entry.target);
        }
      });
    }, options);

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => observer.disconnect();
  }, [creatorPosts, setCreatorPosts]);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const cardsContainer = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = cardsContainer;

    const isAtTop = scrollTop === 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    const loadCards = async (
      cursor: number | undefined,
      direction: "top" | "bottom"
    ) => {
      if (isLoading || cursor === undefined) return;

      setIsLoading(true);

      const cardsParams = {
        take: direction === "top" ? 0 : 10,
        cursor,
      };

      try {
        if (!hasViewUnseen) {
          const { res } = await getPostsUnseen(
            userInfo.access_token,
            cardsParams
          );

          if (res.posts.length < 10) {
            setDiffPosts(10 - res.posts.length);
            setHasViewUnseen(true);
          }

          setCreatorPosts((prevMessages) =>
            direction === "top"
              ? [...res.posts, ...prevMessages]
              : [...prevMessages, ...res.posts]
          );

          if (res.posts.length < 10) {
            const { res: seenRes } = await getPostsSeen(userInfo.access_token, {
              take: 10 - res.posts.length,
              cursor,
            });

            setCreatorPosts((prevMessages) => [
              ...prevMessages,
              ...seenRes.posts,
            ]);
          }
        } else {
          const { res: seenRes } = await getPostsSeen(userInfo.access_token, {
            take: diffPosts !== 0 ? diffPosts : 10,
            cursor,
          });

          setCreatorPosts((prevMessages) => [
            ...prevMessages,
            ...seenRes.posts,
          ]);
        }
      } catch (error) {
        setFallbackText({
          text1: "Oops! Something went wrong",
          text2: "We're working on it. Try again soon!",
        });
      } finally {
        setDiffPosts(0);
        setIsLoading(false);
      }
    };

    if (isAtBottom && creatorPosts.length > 0) {
      const lastMessageId = creatorPosts.at(-1)?.postId;
      loadCards(lastMessageId, "bottom");
    }
  };

  const markFeedCardAsRead = async (id: string) => {
    try {
      await postPostsView(userInfo.access_token, { postId: id });
      return true;
    } catch (error) {
      return false;
    }
  };

  const closeFeed = () => {
    setFeedScreen("");
    setMainScreen("/catalog");
    handleButtonClick("grid");
  };

  return (
    <div ref={divRef} className="feed-global" onScroll={handleScroll}>
      <PageTitle
        onBackClick={closeFeed}
        className="feed-page__page-title margin-hor-16"
        hasCloseIcon={windowSize >= 768}
      />
      <CoverImage
        creatorInfo={{
          creatorCoverImage: CoverPhoto,
          creatorName: "",
        }}
      />

      <main ref={feedCardsContainer} className="feed-global-cards">
        {!isLoaded ? (
          <Loading className="feed-global-loading" />
        ) : (
          <>
            {creatorPosts.length > 0 ? (
              creatorPosts.map((post, index) => {
                return (
                  <FeedCards
                    key={post.postId}
                    post={post}
                    className="margin-bottom-40"
                    onDelete={() => handlerDeletePost(post.postId)}
                    onUnlockedPost={handlerUnlockPost}
                    ref={(el) => {
                      if (el) {
                        itemsRef.current[index] = el;
                      }
                    }}
                  />
                );
              })
            ) : (
              <div className="feed-global-fallback">
                <img className="feed-global-logo" src={logo} alt="Logo Roses" />
                <h2 className="feed-global-no-post">
                  {fallbackText.text1}
                  <span className="feed-global-no-post-support-text">
                    {fallbackText.text2}
                  </span>
                </h2>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default FeedGlobal;

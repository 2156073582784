import React, { useContext, useEffect, useState } from "react";

import "./styles.scss";

import Button from "components/Buttons/Button";
import ButtonIcon from "components/Buttons/ButtonIcon";
import Category from "components/Category";
import ImageCircleBorder from "components/ImageCircleBorder";
import ImageMessageModal from "components/Chat/ChatModals/ImageMessageModal";

import ChevronLeft from "assets/icons/navigationIcons/arrow-left-gray.svg";
import ChevronRight from "assets/icons/navigationIcons/bx-chevron-right.svg";
import dollar from "assets/icons/dollar-white.svg";
import photoDefault from "assets/images/roses-photo-default.png";
import roses from "assets/icons/logos/logo-fill-pink.svg";

import { useAvailableRoses } from "contexts/availableRosesContext";
import { useCanPurchasedMedia } from "hooks/useCanPurchasedMedia";
import { useDeskNavigation } from "hooks/useDeskNavigation";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useUserInfo } from "hooks/userInfo";

import { CreatorDetailsContext } from "contexts/creatorDetails";

import { IGoal } from "types/goal";

import { textCapitalize } from "utils/textCapitalize";
import { thousandsFormat } from "utils/thousandsFormat";
import useAuthCheckOpenModal from "components/AuthCheckOpenModal.tsx";

interface ProfileDetailsProps {
  idProfile: string;
  name: string;
  age: string | number;
  isVip: boolean;
  isIA: boolean;
  isFeedOpen?: boolean;
  momentStyle?: boolean;
  buttonStyle:
    | "primary"
    | "secondary"
    | "tertiary"
    | "inverse"
    | "transparent"
    | "transparentActive";
  icon: string;
  iconSecondary: string;
  profilePhoto?: string;
  principalPhoto: string;
  photos: any[];
  slideIndex?: number;
  goal?: IGoal;
  goalProgress?: string;
  changeNext?: (a: any) => void;
  changePrevious?: (a: any) => void;
  sendMessageClick: (a: any) => void;
  heartButtonClick: (a: any) => any;
  openModal?: () => void;
  openFeed?: () => void;
}
interface UserProfileDetails {
  gender: string;
  bio: string;
  height: number;
  location: {
    place: string | null;
    region: string | null;
    country: string | null;
  };
  ethnicities: string[];
  nationality: string;
  languages: string[];
  zodiac: string;
  bodyStyle: string;
  interests: string[];
}
interface EssentialCard {
  icon: string;
  gender: string;
  info: string;
}
interface UserProfileDetails {
  gender: string;
  bio: string;
  height: number;
  location: {
    place: string | null;
    region: string | null;
    country: string | null;
  };
  ethnicities: string[];
  nationality: string;
  languages: string[];
  zodiac: string;
  bodyStyle: string;
  interests: string[];
}
interface EssentialCard {
  icon: string;
  gender: string;
  info: string;
}

const NewProfile: React.FC<ProfileDetailsProps> = (props) => {
  const {
    idProfile,
    name,
    age,
    isVip,
    isIA,
    isFeedOpen,
    momentStyle,
    buttonStyle,
    icon,
    iconSecondary,
    profilePhoto,
    principalPhoto,
    photos,
    slideIndex,
    goal,
    goalProgress,
    changeNext,
    changePrevious,
    heartButtonClick,
    sendMessageClick,
    openModal,
    openFeed,
  } = props;

  const { userInfo } = useUserInfo();
  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setFeedScreen, params } = useDeskNavigation();
  const { userId } = params;
  const { canPurchase } = useCanPurchasedMedia();
  const { showToast } = useToast();
  const { creatorDetails } = useContext(CreatorDetailsContext);

  const [userProfileDetails, setUserProfileDetails] =
    useState<UserProfileDetails | null>(null);
  const [essentialCards, setEssentialCards] = useState<EssentialCard[]>([]);
  const [videoPublic, setVideoPublic] = useState<string | null>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [userInterests, setUserInterests] = useState<string[]>();
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const checkAuth = useAuthCheckOpenModal();

  useEffect(() => {
    getProfile();
  }, [userInfo.access_token, creatorDetails]);

  const getProfile = async () => {
    try {
      if (creatorDetails) {
        if (creatorDetails.profile.cover) {
          setVideoPublic(creatorDetails.profile.cover.url);
          setHasVideo(true);
        } else {
          setHasVideo(false);
        }

        setUserInterests(creatorDetails?.profile?.interests);
        const cards: EssentialCard[] = [
          {
            icon: "icon-gender",
            gender: "Gender",
            info: creatorDetails?.profile?.gender || "Not informed",
          },
          {
            icon: "icon-earth",
            gender: "Nationality",
            info:
              textCapitalize(creatorDetails?.profile?.nationality) ||
              "Not informed",
          },
          /*       {
            icon: "icon-ethnicity",
            gender: "Ethnicity",
            info:
              creatorDetails?.profile?.ethnicities.length > 0
                ? textCapitalize(
                    creatorDetails?.profile?.ethnicities[0]?.replace("_", " ")
                  )
                : "Not informed",
          }, */
          {
            icon: "icon-measurement",
            gender: "Height",
            info: String(creatorDetails?.profile?.height) || "Not informed",
          },
          {
            icon: "icon-gym",
            gender: "Body Style",
            info: creatorDetails?.profile?.bodyStyle
              ? textCapitalize(
                  creatorDetails?.profile?.bodyStyle.replace("_", " ")
                )
              : "Not informed",
          },
          {
            icon: "icon-zodiac",
            gender: "Zodiac",
            info:
              textCapitalize(creatorDetails?.profile?.zodiac) || "Not informed",
          },
          {
            icon: "icon-language",
            gender: "Languages",
            info:
              creatorDetails?.profile?.languages.length > 0
                ? creatorDetails?.profile?.languages
                    .map(
                      (lang: string) =>
                        lang.charAt(0).toUpperCase() +
                        lang.slice(1).toLowerCase()
                    )
                    .join(", ")
                : "Not informed",
          },
        ];

        setEssentialCards(cards);

        setUserProfileDetails(creatorDetails?.profile as UserProfileDetails);
      }
    } catch (error) {
      console.error("Occurs a problem:", error);
    }
  };

  const imageMessageModalHandler = (url: string) => {
    setModalContent(<ImageMessageModal url={url} />);
    setOpenUseModal(true);
  };

  const handlerFeedButtonClick = () => {
    if (!checkAuth()) return;
    setFeedScreen("/feed", { userId });
  };

  return (
    <div className="profile-slide">
      <div id="profile" className={`${momentStyle ? "profile-moments" : ""}`}>
        <div className="profile_dots">
          {/*  <img className="icon-dot" src={dot} alt="" /> */}
        </div>
        <div
          className={`profile-banner ${
            !momentStyle ? "linear-gradient" : "profile-banner-moments"
          }`}
        >
          {!momentStyle ? (
            <div className="img-change-buttons">
              {!isLoaded && <div className="placeholder-photos"></div>}
              {hasVideo ? (
                <>
                  {!(
                    principalPhoto.slice(-3) === "mp4" ||
                    (principalPhoto === photos?.[0]?.url &&
                      principalPhoto.slice(-3) === "mp4")
                  ) && (
                    <div
                      className="img-change-button-left"
                      onClick={changePrevious}
                    >
                      <div
                        className="button-icon left-br "
                        style={{ color: "white" }}
                      >
                        <span
                          className=" icon-chevron-left icon-white"
                          style={{ color: "white" }}
                        ></span>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!(
                    principalPhoto.slice(-3) === "mp4" ||
                    (principalPhoto === photos?.[0]?.url &&
                      principalPhoto.slice(-3) !== "mp4")
                  ) && (
                    <div
                      className="img-change-button-left"
                      onClick={changePrevious}
                    >
                      <div
                        className="button-icon left-br "
                        style={{ color: "white" }}
                      >
                        <span
                          className=" icon-chevron-left icon-white"
                          style={{ color: "white" }}
                        ></span>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="img-change-button-right" onClick={changeNext}>
                {principalPhoto !== photos?.[photos.length - 1]?.url && (
                  <div
                    className="button-icon right-br"
                    style={{ color: "white" }}
                  >
                    <span
                      className=" icon-chevron-right icon-white"
                      style={{ color: "white" }}
                    ></span>
                  </div>
                )}
              </div>

              {principalPhoto.slice(-3) === "mp4" && (
                <video
                  src={principalPhoto}
                  loop
                  muted
                  playsInline
                  disablePictureInPicture
                  autoPlay={true}
                  controls={false}
                  preload="auto"
                  onCanPlay={() => setIsLoaded(true)}
                  className="photo-video"
                />
              )}
              {principalPhoto.slice(-3) === "gif" && (
                <video
                  src={principalPhoto}
                  autoPlay={true}
                  controls={false}
                  preload="auto"
                  loop
                  muted
                  playsInline
                  disablePictureInPicture
                  onCanPlay={() => setIsLoaded(true)}
                  className="photo-video"
                />
              )}
              {principalPhoto.slice(-3) !== "mp4" &&
                principalPhoto !== null && (
                  <img
                    src={principalPhoto}
                    alt="profile"
                    onLoad={() => setIsLoaded(true)}
                    className="photo-video"
                  />
                )}
            </div>
          ) : (
            false
          )}

          {/* {isVip && (
    <div className="vip-tag">
      <div className="background " />
      <p className="small-p no-margin">VIP</p>
    </div>
  )} */}
          <div className="profile_dots">
            <div className="right_dots">
              {isVip && (
                <span className="profile__icon icon icon-burn icon-md icon-white"></span>
              )}
              {isIA && (
                <div className="ia-icon">
                  <span className="profile-ia-box">AI</span>
                </div>
              )}
            </div>

            <div className="statistics-info left_dots">
              <span className="icon icon-heart-solid icon-sm icon-white"></span>
              <p className="counter">12.3k</p>
            </div>
          </div>

          <div
            className={`profile-title ${
              momentStyle ? "profile-title__moments" : ""
            }`}
          >
            <div className="user-info">
              <ImageCircleBorder
                centerImageAlt="profile avatar"
                centerImage={profilePhoto}
                size={56}
                noSpaceBetweenBorder
                onClick={handlerFeedButtonClick}
              />

              <div className="user-title">
                <p className="profile-info no-margin">{name}</p>
              </div>
              {creatorDetails?.profile?.occupation ? (
                <p className="counter">
                  {age} | {creatorDetails?.profile?.occupation}{" "}
                </p>
              ) : (
                <p className="counter">{age} years</p>
              )}
              {/* <Rating rating={rating} isVip={isVip} /> */}

              {/* Commented old layout */}
              {/*        <div className="profile-statistics">
                <div className="statistics-info">
                  <span className="icon icon-video icon-sm icon-gray"></span>
                  <p className="counter">5</p>
                </div>

                <div className="statistics-info">
                  <span className="icon icon-photo icon-sm icon-gray"></span>
                  <p className="counter">237</p>
                </div>

                <div className="statistics-info">
                  <span className="icon icon-heart-solid icon-sm icon-gray"></span>
                  <p className="counter">12.3k</p>
                </div>
              </div> */}
            </div>
            <div className="profile-banner-buttons">
              <ButtonIcon
                onClick={heartButtonClick}
                buttonStyle={buttonStyle}
                icon={icon}
              />

              <Button buttonStyle="septenary" onClick={openFeed}>
                VIEW FEED
              </Button>

              <ButtonIcon
                onClick={sendMessageClick}
                buttonStyle={"transparent"}
                icon={iconSecondary}
              />
            </div>
          </div>
        </div>
        {!momentStyle ? (
          <div className="profile-bio">
            {creatorDetails?.profile.bio && (
              <div>
                <label>About Me</label>
                <p className="small-p no-margin margin-top-16">
                  {creatorDetails?.profile.bio
                    ? creatorDetails?.profile.bio
                    : "Not informed"}
                </p>
                {/* <div className="category-wrapper">
   {userInfo.map((info, index) => (
     <Category key={index} children={info} />
   ))}
 </div> */}
              </div>
            )}
            {essentialCards.length > 0 &&
              essentialCards.map(
                (value) => value.info !== "Not informed" && value.info !== null
              ) && (
                <div>
                  <label>Essentials</label>

                  <div className="essentials-list margin-top-16">
                    {essentialCards.map((card, index) =>
                      card.info !== "Not informed" && card.info !== "null" ? (
                        <div className="essential-card" key={index}>
                          <span
                            className={`icon ${card.icon} icon-md icon-gray icon-round`}
                          ></span>
                          <div className="essential-wrapper">
                            <label className="essential-label">
                              {card.gender}
                            </label>
                            <p className="essential-info no-margin">
                              {Array.isArray(card.info)
                                ? card.info.join(", ")
                                : card.info}
                            </p>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              )}

            {/* <div className="category-wrapper">
    {userLanguage.map((info, index) => (
      <Category key={index} children={info} />
    ))}
  </div> */}
            {!momentStyle ? (
              <>
                {photos.length > 0 && (
                  <div>
                    <label>Media</label>
                    <div className="media margin-top-16">
                      <div
                        className="media_scroll"
                        onTouchStart={(e) => e.stopPropagation()}
                        onMouseDown={(e) => e.stopPropagation()}
                        onTouchMove={(e) => e.stopPropagation()}
                        onWheel={(e) => e.stopPropagation()}
                      >
                        <div className="media-photo">
                          {photos?.map((photo, index) => (
                            <img
                              key={index}
                              src={photo.url || photoDefault}
                              alt="profile"
                              loading="lazy"
                              onClick={() =>
                                imageMessageModalHandler(
                                  photo.url || photoDefault
                                )
                              }
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              false
            )}

            {goal && (
              <div>
                <label>My Goals</label>
                <div className="my-goals margin-top-16">
                  <div>
                    <p className="my-goals_meta no-margin white-p bold-p">
                      <img
                        src={roses}
                        alt="Logo Roses"
                        className="my-goals_logo margin-right-4"
                      />
                      {thousandsFormat(Number(goal?.target))}
                    </p>
                    <p className="description">{goal?.title}</p>
                    <div className="line_progress">
                      <div
                        className="line_progress_complete"
                        style={{ width: goalProgress }}
                      >
                        {/*   <div className="line_indicator">
            <div className="line_indicator_second"></div>
          </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div
                      className="button"
                      onClick={() => {
                        if (availableRoses > 0) openModal?.();
                        else
                          showToast({
                            type: "info",
                            title: "Unable to complete your donate",
                            description: "You don't have roses",
                          });
                      }}
                    >
                      <img src={dollar} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userInterests && userInterests.length > 0 ? (
              <div>
                <label>Interests</label>
                <div className="category-wrapper  margin-top-16">
                  {userInterests?.map((info, index) => (
                    <Category
                      key={index}
                      children={textCapitalize(info).replace("_", " ")}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default NewProfile;

import { useEffect } from "react";
import { UserInfoRequest } from "types/userInfoRequest";

const DB_NAME = "UserProfileDB";
const STORE_NAME = "profile";
const DB_VERSION = 1;
export function useUserProfileDB() {
  useEffect(() => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: "userId" });
      }
    };

    request.onerror = () => console.error("Erro ao abrir IndexedDB");
    request.onsuccess = () => console.log("IndexedDB pronto!");
  }, []);

  const saveUserProfile = (profile: UserInfoRequest): Promise<string> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const transaction = db.transaction(STORE_NAME, "readwrite");
        const store = transaction.objectStore(STORE_NAME);
        const putRequest = store.put(profile);

        putRequest.onsuccess = () => resolve("Perfil salvo com sucesso!");
        putRequest.onerror = () => reject("Erro ao salvar perfil.");
      };

      request.onerror = () => reject("Erro ao abrir o banco.");
    });
  };

  const getUserProfile = (
    userId: string
  ): Promise<UserInfoRequest | undefined> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const transaction = db.transaction(STORE_NAME, "readonly");
        const store = transaction.objectStore(STORE_NAME);
        const getRequest = store.get(userId);

        getRequest.onsuccess = () =>
          resolve(getRequest.result as UserInfoRequest | undefined);
        getRequest.onerror = () => reject("Erro ao buscar perfil.");
      };

      request.onerror = () => reject("Erro ao abrir o banco.");
    });
  };

  const deleteUserProfile = (userId: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const transaction = db.transaction(STORE_NAME, "readwrite");
        const store = transaction.objectStore(STORE_NAME);
        const deleteRequest = store.delete(userId);

        deleteRequest.onsuccess = () => resolve("Perfil excluído com sucesso!");
        deleteRequest.onerror = () => reject("Erro ao excluir perfil.");
      };

      request.onerror = () => reject("Erro ao abrir o banco.");
    });
  };

  const patchUserProfile = (
    userId: string,
    updates: Partial<UserInfoRequest>
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);

      request.onsuccess = (event) => {
        const db = (event.target as IDBOpenDBRequest).result;
        const transaction = db.transaction(STORE_NAME, "readwrite");
        const store = transaction.objectStore(STORE_NAME);
        const getRequest = store.get(userId);

        getRequest.onsuccess = () => {
          const existingProfile = getRequest.result as
            | UserInfoRequest
            | undefined;

          if (!existingProfile) {
            reject("Perfil não encontrado.");
            return;
          }

          const updatedProfile = { ...existingProfile, ...updates };
          const putRequest = store.put(updatedProfile);

          putRequest.onsuccess = () =>
            resolve("Perfil atualizado com sucesso!");
          putRequest.onerror = () => reject("Erro ao atualizar perfil.");
        };

        getRequest.onerror = () => reject("Erro ao buscar perfil.");
      };

      request.onerror = () => reject("Erro ao abrir o banco.");
    });
  };

  return {
    saveUserProfile,
    getUserProfile,
    deleteUserProfile,
    patchUserProfile,
  };
}

import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";
import lupeIconGradient from "assets/icons/icon-lupe-gradient.svg";
import patchUserPreferences from "../../../api/userPreferences/patchUserPreferences";
import { useUserInfo } from "../../../hooks/userInfo";
import InputCheckbox from "../../Inputs/InputCheckbox";
import { useModal } from "../../../hooks/useModal";
import { useToast } from "hooks/useToast";

interface ModalInitialPreferencesProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalInitialPreferences: React.FC<ModalInitialPreferencesProps> = ({
  isOpen = false,
  setIsOpen,
}) => {
  const { userInfo } = useUserInfo();
  const { setOpenUseModal } = useModal();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);

  const genderConnect = [
    { inputLabel: "Woman", inputValue: "woman", groupName: "genderConnect" },
    { inputLabel: "Man", inputValue: "man", groupName: "genderConnect" },
    {
      inputLabel: "Non-Binary",
      inputValue: "non_binary",
      groupName: "genderConnect",
    },
    { inputLabel: "Trans", inputValue: "trans", groupName: "genderConnect" },
  ];

  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [selectGenresTrigger, setSelectGenresTrigger] = useState(false);

  useEffect(() => {
    if (selectedGenres.length === 4) {
      addGenderSubmit();
    }
  }, [selectGenresTrigger]);

  const handleSkip = () => {
    setSelectedGenres(["woman", "man", "non_binary", "trans"]);
    setSelectGenresTrigger((prev) => !prev);
  };

  const addGenderSubmit = async () => {
    if (!selectedGenres.length) {
      showToast({
        description: "Select one or more genres or click skip",
        title: "Empty genres",
        type: "info",
      });
    } else {
      setLoading(true);

      try {
        const createdUserData = selectedGenres?.map((gender) =>
          gender === "non_binary" ? "NONBINARY" : gender.toUpperCase()
        );

        const response = await patchUserPreferences(userInfo.access_token, {
          preferedGenders: createdUserData,
        });
        switch (response.status) {
          case 200:
            setOpenUseModal(false);
            break;
          default:
            showToast({
              description: "Please try again",
              title: "Unexpected error",
              type: "error",
            });

            break;
        }
      } catch (error) {
        console.error(error);
        showToast({
          description: "Please try again",
          title: "Unexpected error",
          type: "error",
        });
      }
    }

    setLoading(false);
  };

  return (
    <>
      {isOpen && <div className="modal-overlay"></div>}
      <dialog
        id="modal-initial-preferences"
        className="padding-40"
        open={isOpen}
      >
        <div className="modal-container">
          <div className="modal-icon-wrapper">
            <img src={lupeIconGradient} alt="Lupe Icon" />
            <h6 className="no-margin-top">CHOOSE PREFERENCES</h6>
          </div>

          <InputCheckbox
            setInputsValues={setSelectedGenres}
            elements={genderConnect}
            inputValues={selectedGenres}
          />
          <div className="buttons-wrapper">
            <Button
              buttonStyle="primary"
              onClick={addGenderSubmit}
              children={"Apply Preferences"}
            />
            <Button
              buttonStyle="quaternary"
              onClick={handleSkip}
              children={"Skip"}
            />
          </div>
        </div>
      </dialog>
    </>
  );
};

export default ModalInitialPreferences;

import { useState, useEffect } from "react";

interface IImageWithSize {
  src: string;
  alt?: string;
  className?: string;
}

const ImageWithSize = (props: IImageWithSize) => {
  const { src, alt, className, ...rest } = props;

  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setSize({ width: img.naturalWidth, height: img.naturalHeight });
    };
  }, [src]);

  return (
    <img
      src={src}
      width={size.width}
      height={size.height}
      style={{ maxWidth: "100%", height: "auto" }}
      alt={alt}
      className={`${className ?? ""}`}
      loading="lazy"
      {...rest}
    />
  );
};

export default ImageWithSize;

import React, { ReactNode } from "react";
import "./styles.scss";
import ProfileIcon from "assets/icons/profile-icon-gray.svg";

interface PageTitleProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  avatarUrl?: string;
  chatLocked?: boolean;
  isChat?: boolean;
  isProducer?: boolean;
  isOnLine?: boolean;
  hasCloseIcon?: boolean;
  hasAMomentToSee?: boolean;
  hasButtonNavigation?: boolean;
  backgroundTransparent?: boolean;
  buttonFeed?: boolean;
  className?: string;
  avatarClick?: (a: any) => void;
  buttonFeedClick?: (a: any) => void;
  mediaButtonClick?: (a: any) => void;
  onBackClick?: () => void;
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subtitle,
  avatarUrl,
  chatLocked,
  isChat,
  isProducer,
  isOnLine,
  hasCloseIcon = true,
  hasAMomentToSee,
  hasButtonNavigation = true,
  backgroundTransparent,
  buttonFeed,
  className,
  avatarClick,
  buttonFeedClick,
  mediaButtonClick,
  onBackClick,
}) => {
  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  const classPageTitle = [
    "page-title-container",
    backgroundTransparent ? "background-transparent" : "",
    className ?? "",
  ]
    .join(" ")
    .trim();

  return (
    <div className={classPageTitle}>
      {hasButtonNavigation && !isProducer && (
        <div className="back-button " onClick={handleBackClick}>
          {hasCloseIcon ? (
            <span className="icon icon-close icon-md icon-gray-light"></span>
          ) : (
            <span className="icon icon-chevron-left icon-md icon-gray-light"></span>
          )}
        </div>
      )}

      {hasButtonNavigation && isProducer && (
        <div className="back-button margin-left-16" onClick={handleBackClick}>
          {hasCloseIcon ? (
            <span className="icon icon-close icon-md icon-gray-light"></span>
          ) : (
            <span className="icon icon-chevron-left icon-md icon-gray-light"></span>
          )}
        </div>
      )}

      {isChat && avatarClick && (
        <div
          onClick={avatarClick}
          className={`chat-icon margin-right-8 ${
            hasAMomentToSee ? "new-moment-available" : "moment-was-seen"
          }`}
        >
          <img
            className="chat-icon-img padding-4"
            src={avatarUrl || ProfileIcon}
            alt="chat icon"
          />
        </div>
      )}
      <div className="text-wrapper">
        <div className={`title ${isChat ? "chat-title" : ""}`}>{title}</div>
        {subtitle && (
          <div className="subtitle-wrapper">
            {/* TODO */}
            {/* {isChat && (
              <div
                className={`chat-icon-status ${
                  isOnLine ? "interest-online" : "interest-offline"
                }`}
              />
            )} */}
            {isChat && (
              <div
                className={`chat-icon-status
                  interest-online
              `}
              />
            )}

            <div className="subtitle">{subtitle}</div>
          </div>
        )}
      </div>
      {/* TODO */}
      {/* {isChat && !chatLocked && ( */}
      {isChat && (
        <div className="open-extra">
          <button className="open-extra-button" onClick={mediaButtonClick}>
            Media
          </button>
        </div>
      )}
      {buttonFeed && (
        <div className="open-extra">
          <button className="open-extra-button" onClick={buttonFeedClick}>
            Feed
          </button>
        </div>
      )}
    </div>
  );
};

export default PageTitle;

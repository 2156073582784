import { useModal } from "../../../../hooks/useModal";
import React from "react";
import CloseIconGray from "assets/icons/closeIcons/close-icon-gray.svg";
import "../chat-modal-global-style.scss";
import SlideFooterPreview from "../../../Carousels/SliderFooterPreview";
import ChatTextarea from "../../../TextAreas/ChatTextArea";

interface PreviewPackModalProps {
  packSources: (string | ArrayBuffer)[];
  removeFile: (index: number) => void;
  uploadFileCallBack: (file: File[], content: string) => Promise<void>;
  files: File[];
}

const PreviewPackModal: React.FC<PreviewPackModalProps> = (props) => {
  const { packSources, removeFile, uploadFileCallBack, files } = props;
  const { setOpenUseModal } = useModal();

  return (
    <>
      <img
        src={CloseIconGray}
        alt="close icon slide"
        onClick={() => setOpenUseModal(false)}
      />

      <SlideFooterPreview
        fileMaxWidth={600}
        hasArrows={false}
        removeFile={removeFile}
        slides={packSources.map((file) => {
          return {
            fileType: file.toString().includes("data:video/")
              ? "video"
              : "image",
            src: file as string,
          };
        })}
      />
      <div className="pack-modal-preview__textarea">
        <ChatTextarea
          hasEmptyMessage
          onSendMessage={(e) => {
            uploadFileCallBack(files, e);
          }}
        />
      </div>
    </>
  );
};

export default PreviewPackModal;

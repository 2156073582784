import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useModal } from "../../../../hooks/useModal";
import CloseIconGray from "assets/icons/closeIcons/close-icon-gray.svg";
import "../chat-modal-global-style.scss";
import ChatTextarea from "../../../TextAreas/ChatTextArea";

interface PreviewMediaModalProps {
  images: (string | ArrayBuffer)[];
  fileType: "video" | "image";
  uploadFileCallBack: (
    file: File[],
    content: string,
    thumbnail: string
  ) => Promise<void>;
  files: File[];
}

const PreviewMediaModal: React.FC<PreviewMediaModalProps> = (props) => {
  const { images, uploadFileCallBack, files } = props;
  const [thumbnail, setThumbnail] = useState("");

  const { setOpenUseModal } = useModal();

  return (
    <div className="modal-preview-file">
      <div className="header-modal-preview">
        <img
          src={CloseIconGray}
          alt="close icon"
          onClick={() => setOpenUseModal(false)}
        />
      </div>

      {images.map((imageSrc, index) => (
        <div key={index} className="preview-file-container">
          <img
            src={imageSrc as string}
            alt={`Preview ${index}`}
            className="file-modal-content"
          />
        </div>
      ))}

      <div className="modal-preview-container">
        <ChatTextarea
          autoFocus
          isButtonSendDisabled={false}
          hasEmptyMessage
          onSendMessage={(e) => uploadFileCallBack(files, e, thumbnail)}
          className="modal-preview-file__textarea"
        />
      </div>
    </div>
  );
};

export default PreviewMediaModal;

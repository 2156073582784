import fetchApi from "../fetchApi";

export type IPostPostsUnseen = {
  userId: string;
  take?: number;
  cursor?: number;
};

const getPostsUserById = async (token: string, data: IPostPostsUnseen) => {
  const { userId } = data;

  const queryParams = new URLSearchParams();

  if (data.take) queryParams.append("take", String(data.take));
  if (data.cursor) queryParams.append("cursor", String(data.cursor));

  const queryString = queryParams.toString();
  const endpoint = queryString ? `?${queryString}` : "";

  return await fetchApi({
    service: "posts",
    method: "GET",
    endPoint: `/user/${userId}${endpoint}`,
    token,
  });
};

export default getPostsUserById;
import React, { useRef, useState, useEffect } from "react";

import "./styles.scss";

import { useModal } from "../../../hooks/useModal";
import { usePublishContent } from "../../../hooks/usePublishContent";

import rosesIcon from "assets/icons/logos/logo-fill-pink.svg";

interface ChatButtonProps {
  sendRoses: () => void;
  sendPack: (files: File[]) => void;
  sendAudio: () => void;
  sendMedia: (files: File[]) => void;
  openCamera: () => void;
  isButtonsDisabled?: boolean;
  hasButtonExpansion?: boolean;
  parentFocus?: boolean;
  parentHasContent?: boolean;
  className?: string;
}

const ChatButton: React.FC<ChatButtonProps> = (props) => {
  const {
    sendRoses,
    sendPack,
    sendAudio,
    sendMedia,
    openCamera,
    isButtonsDisabled,
    hasButtonExpansion = true,
    parentFocus,
    parentHasContent,
    className,
  } = props;

  const fileInputRefFront = useRef<HTMLInputElement>(null);
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const { setOpenUseModal, setModalContent } = useModal();
  const { setCreatorContent, clearPublishContent, creatorContent } =
    usePublishContent();

  const [isListButtonShow, setIsListButtonShow] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [fileType, setFileType] = useState("");

  const buttonsConfig = [
    {
      id: 0,
      supTextButton: "Roses",
      icon: "roses",
      alt: "roses logo gray",
      click: () => sendRoses(),
    },
    // Disabled indefinitely - 28/10/2024
    // {
    //   id: 1,
    //   supTextButton: "Pack",
    //   icon: PackIconGray,
    //   alt: "pack icon gray",
    //   click: () => {
    //     setFileType("pack");
    //     fileHandler();
    //     setIsListButtonShow(!isListButtonShow);
    //   },
    // },
    {
      id: 2,
      supTextButton: "Audio",
      icon: "mic",
      alt: "mic icon gray",
      click: () => {
        sendAudio();
        setIsListButtonShow(!isListButtonShow);
      },
    },
    // {
    //   id: 3,
    //   supTextButton: "Media",
    //   icon: MultiPhotoGray,
    //   alt: "camera icon gray",
    //   click: () => {
    //     setFileType("media");
    //     fileHandler();
    //     setIsListButtonShow(!isListButtonShow);
    //   },
    // },
    {
      id: 4,
      supTextButton: "Camera",
      icon: "camera",
      alt: "photo icon gray",
      click: () => {
        openCamera();
        setIsListButtonShow(!isListButtonShow);
      },
    },
  ];

  const handleClickOutside = (event: MouseEvent) => {
    if (
      filterContainerRef.current &&
      !filterContainerRef.current.contains(event.target as Node)
    ) {
      setIsListButtonShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList) {
      const filesArray = Array.from(fileList);

      switch (fileType) {
        case "media":
          sendMedia(filesArray);
          break;
        case "pack":
          sendPack(filesArray);
          break;
      }

      setIsListButtonShow(false);
      event.target.value = "";
    }
  };

  const fileHandler = () => {
    setTimeout(() => {
      fileInputRefFront.current?.click();
    }, 0);
  };

  useEffect(() => {
    if (isListButtonShow) {
      setIsHidden(false);
    }
  }, [isListButtonShow]);

  return (
    <div
      className={`chat-button-component ${className ?? ""}`}
      onClick={() => setIsListButtonShow(false)}
    >
      <input
        type="file"
        name="upload-photo-front"
        ref={fileInputRefFront}
        accept={"video/*, .jpg,.jpeg,.png"}
        multiple={fileType === "pack"}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      {isListButtonShow && hasButtonExpansion && (
        <div
          ref={filterContainerRef}
          className="filter-container padding-top-16 show-buttons"
        >
          <div className="filter-list">
            {buttonsConfig.map((button, index) => {
              return (
                <button
                  key={index}
                  className="filter-item padding-hor-16"
                  onClick={button.click}
                >
                  {button.icon !== "roses" ? (
                    <span className={`icon icon-md icon-${button.icon}`}></span>
                  ) : (
                    <img
                      className="filter-image"
                      src={rosesIcon}
                      alt={button.alt}
                    />
                  )}

                  <p className="filter-txt">{button.supTextButton}</p>
                </button>
              );
            })}
          </div>
          <button
            className="chat-button chat-button-close"
            onClick={() => setIsListButtonShow(false)}
          >
            <span className="icon icon-md icon-close"></span>
            <span>Select</span>
            <span
              data-icon
              className="icon icon-md icon-chevron-top rotate-image"
            ></span>
          </button>
        </div>
      )}
      <button
        disabled={isButtonsDisabled}
        onClick={(e) => {
          e.stopPropagation();
          setIsListButtonShow(!isListButtonShow);
        }}
        className={`chat-button ${
          parentFocus || parentHasContent ? "chat-button-active" : ""
        }`}
      >
        <span className="icon icon-md icon-photo"></span>
        <span className="chat-button-text">Select</span>
        <span data-icon className="icon icon-md icon-chevron-top"></span>
      </button>
    </div>
  );
};

export default ChatButton;

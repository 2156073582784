import React, { useEffect, useState } from "react";
import "./styles.scss";

import PageTitle from "components/PageTitles";
import InputText from "components/Inputs/InputText";
import Button from "components/Buttons/Button";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";

import postCreatorSettingsGoal from "../../api/creatorSettings/postCreatorSettingsGoal";
import getCreatorSettingsGoalHistory from "../../api/creatorSettings/getCreatorSettingsGoalHistory";

import { IGoal } from "../../types/goal";

const UserSettingsGoals: React.FC = ({ className }: { className?: string }) => {
  const { setPrincipalScreen } = useDeskNavigation();

  const { userInfo } = useUserInfo();
  const token = userInfo.access_token;

  useEffect(() => {
    getListGoals();
  }, []);

  const getListGoals = async () => {
    try {
      const { res } = await getCreatorSettingsGoalHistory(token);
      setListGoals(res);
    } catch (error) {
      console.error("Erro ao criar meta:", error);
    }
  };

  const postCreateGoals = async () => {
    try {
      const res = await postCreatorSettingsGoal({
        token,
        title,
        target: Number(target),
      });
      await getListGoals();
    } catch (error) {
      console.error("Erro ao criar meta:", error);
    }
  };

  const [title, setTitle] = useState("");
  const [target, setTarget] = useState<string | number>(0);
  const [listGoals, setListGoals] = useState<IGoal[]>([]);

  return (
    <div className={`user-settings-goals ${className ? className : ""}`}>
      <PageTitle
        title={"My Goals"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
        className="margin-hor-16"
      />

      <div className="goals">
        <InputText
          label="Title: "
          value={title}
          inputType="text"
          placeholder="Buy a house"
          textTransform="none"
          className="no-margin"
          onChangeInput={(e) => setTitle(e)}
        />

        <InputText
          label="Target: "
          value={String(target)}
          inputType="text"
          placeholder="Buy a house"
          textTransform="none"
          className="no-margin"
          onChangeInput={(e) => setTarget(parseFloat(e))}
        />

        <Button
          buttonStyle={"primary"}
          className="margin-top-8"
          onClick={postCreateGoals}
        >
          Create Goals
        </Button>
      </div>

      <div className="margin-hor-24 margin-top-24">
        <h2 className="goals__history-title margin-bottom-16 no-margin">
          Goals History:
        </h2>
        {listGoals?.map((goal) => {
          const { goalId, isActive, target, title, currentAmount } = goal;
          return (
            <div
              key={goalId}
              className="goals__history padding-top-8"
              onClick={() => {
                setPrincipalScreen("/user-settings-edit-goal", {
                  goal: JSON.stringify(goal),
                });
              }}
            >
              <div className="goals__top">
                <h3 className="goals__history-title no-margin">{title}</h3>
                <span
                  className={`
                  goals__history-title 
                  goals__history-title--${isActive ? "active" : "inactive"}
                  `}
                >
                  {isActive ? "Active" : "Inactive"}
                </span>
              </div>
              <div className="goals__bottom">
                <p className="goals__objective">Objective: {target}</p>
                <p className="goals__amount">Amount: {currentAmount}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserSettingsGoals;

import fetchApi from "../fetchApi";

export interface PatchUserPreferencesBodyDataRequest {
  preferedGenders?: string[];
  preferedAgeMin?: number;
  preferedAgeMax?: number;
  preferedHeightMax?: number;
  preferedHeightMin?: number;
  preferedWeightMax?: number;
  preferedWeightMin?: number;
  preferedEyeColors?: string[];
  preferedHairColors?: string[];
  preferedNationalities?: string[];
  preferedEthnicities?: string[];
  preferedLanguages?: string[];
}

const patchUserPreferences = async (
  token: string,
  data: Partial<PatchUserPreferencesBodyDataRequest>
) => {
  return await fetchApi({
    service: "user",
    endPoint: `/preferences`,
    method: "PATCH",
    data,
    token,
  });
};

export default patchUserPreferences;

import React, { useState } from "react";
import "./styles.scss";

interface ToggleUnitProps {
  className?: string;
  toggleInitialState?: boolean;
  toggleCallBack: (event: boolean) => void;
}

const ToggleUnit: React.FC<ToggleUnitProps> = ({
  className,
  toggleInitialState = true,
  toggleCallBack,
}) => {
  const [isCm, setIsCm] = useState(toggleInitialState);

  const handleToggle = (newState: boolean) => {
    setIsCm(newState);
    toggleCallBack(newState);
  };

  return (
    <div className={`toggle-container ${className ?? ""}`}>
      <div className="toggle-wrapper" onClick={() => handleToggle(!isCm)}>
        <button className={`toggle-button ${isCm ? "right" : "left"}`}>
          <span className="toggle-text">{isCm ? "in" : "cm"}</span>
        </button>
      </div>
    </div>
  );
};

export default ToggleUnit;

import Header from "components/Headers/Header";
import "./styles.scss";
import Button from "components/Buttons/Button";
import PhotoGallery from "components/PhotoGallery";
import { useEffect, useState } from "react";
import { useUserInfo } from "../../hooks/userInfo";
import { textCapitalize } from "../../utils/textCapitalize";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import patchCreatorApplicationDetails from "../../api/creatorApplications/patchCreatorApplicationDetails";
import ListItemLink from "components/Lists/ListItemLink";
import InputText from "components/Inputs/InputText";
import { useToast } from "hooks/useToast";

const UserInformation: React.FC = () => {
  const [editInfo, setEditInfo] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [instagramProfile, setInstagramProfile] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [firstInfo, setFirstInfo] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState([] as any[]);
  const { showToast } = useToast();

  const { setUserInfo, userInfo } = useUserInfo();

  const { setPrincipalScreen } = useDeskNavigation();

  useEffect(() => {
    setDisplayName(userInfo.display_name);
    setPhoneNumber(
      userInfo.user_phone || userInfo.user_phone === "null"
        ? ""
        : userInfo.user_phone
    );
    setAccountType(textCapitalize(userInfo.account_type) || "");
    setInstagramProfile(userInfo.instagram);
  }, [
    userInfo.account_type,
    userInfo.display_name,
    userInfo.instagram,
    userInfo.user_phone,
  ]);

  const confirmEditionSubmit = async () => {
    setIsLoading(true);

    const data = {
      displayName,
      instagramUser: instagramProfile,
    };

    const patchInfoResponse = await patchCreatorApplicationDetails(
      userInfo.access_token,
      data
    );

    switch (patchInfoResponse?.status) {
      case 200:
        setUserInfo("instagram", instagramProfile);
        setUserInfo("display_name", displayName);

        showToast({
          title: "Save successfully!",
          description: "Your information has been updated successfully!",
          type: "success",
        });

        break;

      default:
        showToast({
          title: "We have a problem",
          description:
            "There was a problem with updating your information. Please try again",
          type: "success",
        });

        break;
    }

    setEditInfo(false);
    setIsLoading(false);
  };

  const handleGoBack = () => {
    setPrincipalScreen("/wait-list-application");
  };

  const handleCancel = () => {
    setEditInfo(false);
    setDisplayName(firstInfo.displayName);
    setInstagramProfile(firstInfo.instagramProfile);
  };

  const handleEdit = () => {
    setEditInfo(true);
    setFirstInfo({
      displayName,
      instagramProfile,
      phoneNumber,
    });
  };

  return (
    <div className={`user-information margin-hor-16`}>
      <Header
        headerIcon="back-button"
        marginTop={24}
        backButtonClick={handleGoBack}
        title="My information"
        subTitle={`We continually review our waiting list and will send you
					a push notification if there are any changes to your application
					status.`}
      />
      <main className="user-information__main">
        <ListItemLink
          highlightText="Display name"
          hasSeparator
          rightNodeContent={
            editInfo ? (
              <InputText
                inputType="text"
                value={displayName}
                onChangeInput={setDisplayName}
              />
            ) : (
              <p className="node-item-right-text">{displayName}</p>
            )
          }
        />
        <ListItemLink
          highlightText="Phone number"
          hasSeparator
          rightNodeContent={
            editInfo ? (
              <InputText
                inputType="text"
                value={phoneNumber}
                onChangeInput={setPhoneNumber}
              />
            ) : (
              <p className="node-item-right-text">{phoneNumber ?? ""}</p>
            )
          }
        />
        <ListItemLink
          highlightText="Account type"
          hasSeparator
          rightNodeContent={
            <p className="node-item-right-text">{accountType}</p>
          }
        />
        <ListItemLink
          highlightText="Instagram"
          rightNodeContent={
            editInfo ? (
              <InputText
                inputType="text"
                value={instagramProfile}
                onChangeInput={setInstagramProfile}
              />
            ) : (
              <p className="node-item-right-text">{instagramProfile}</p>
            )
          }
        />

        <div className="photo-container">
          <PhotoGallery files={photos} />
        </div>
      </main>

      {editInfo ? (
        <div className="user-information__buttons-container margin-bottom-40">
          <Button
            buttonStyle="primary"
            onClick={confirmEditionSubmit}
            disabled={isLoading}
          >
            Save
          </Button>
          <Button buttonStyle="tertiary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          buttonStyle="tertiary"
          onClick={handleEdit}
          className="margin-bottom-40"
        >
          Edit
        </Button>
      )}
    </div>
  );
};

export default UserInformation;

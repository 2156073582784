import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputText from "components/Inputs/InputText";
import { useUserInfo } from "../../hooks/userInfo";
import SubText from "components/Texts/SubText";
import TextActionCard from "components/Cards/TextActionCard";
import Button from "components/Buttons/Button";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import patchUserSelfDetailApi from "../../api/patchUserSelfDetail";
import verifyIconGray from "assets/icons/verify-icon-gray.svg";
import info from "assets/icons/union-gray.svg";
import { useToast } from "hooks/useToast";

const UserSettingsEditProfilePage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [usernameWarning, setUsernameWarning] = useState(false);
  const [nameWarning, setNameWarning] = useState(false);

  const { showToast } = useToast();

  const openToast = (object: {
    type: any;
    title: string;
    description: string;
  }) => {
    showToast({
      title: object.title,
      type: object.type,
      description: object.description,
    });
  };

  const verifyAccountHandler = () => {};

  const sendApiUsername = async (token: any, data: any) => {
    //send to path the name and the username
    const res = await patchUserSelfDetailApi(token, data);

    // wait the answer, and show the toast or warning
    if (res.status === 200 || data.username === userInfo.legal_name) {
      setUserInfo("legal_name", data.username);
      setUsernameWarning(false);
      return 200;
    } else {
      setUsernameWarning(true);
      return 500;
    }
  };

  const sendApiDisplayName = async (token: any, data: any) => {
    //send to path the name and the username
    const res = await patchUserSelfDetailApi(token, data);

    // wait the answer, and show the toast or warning
    if (res.status === 200) {
      setUserInfo("display_name", name);
      return 200;
    }
    return 500;
  };

  const saveEditHandler = async () => {
    const token = userInfo.access_token;

    const apiResUsername = await sendApiUsername(token, {
      username: username,
    });
    const apiResName = await sendApiDisplayName(token, { displayname: name });

    if (apiResName === 200 && apiResUsername === 200) {
      return openToast({
        title: "Changes successful",
        type: "success",
        description: "changes made successfully.",
      });
      setUsernameWarning(false);
    }
  };

  const getUserSelfDetailInit = async () => {
    try {
      const res = await getUserSelfDetail(userInfo.access_token);
      setName(res.res.displayname);
      setUsername(res.res.username);
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  };

  useEffect(() => {
    getUserSelfDetailInit();
  }, []);

  return (
    <div className="user-settings-edit-profile">
      <PageTitle
        title={"Edit Profile"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        className="margin-hor-16"
        onBackClick={() =>
          setPrincipalScreen(`/user-settings-profile`, { btn: "button2" })
        }
      />
      <main className="user-settings-edit-profile__main">
        <div className="margin-bottom-48">
          <div className="user-settings-edit-profile__input-container">
            <InputText
              iconRight={verifyIconGray}
              widthIcon={24}
              label="Name"
              placeholder={
                userInfo.display_name ? userInfo.display_name : "Put your name"
              }
              inputType="text"
              value={name}
              isWarning={nameWarning}
              onChangeInput={(e) => {
                setName(e);
              }}
            />
            <InputText
              label="Username"
              placeholder={userInfo.legal_name ? username : "Put your username"}
              inputType="text"
              value={username}
              isWarning={usernameWarning}
              onChangeInput={(e) => {
                setUsername(e.toLowerCase());
                sendApiUsername(userInfo.access_token, { username: e });
              }}
            />
          </div>
          <SubText>
            In most cases, you’ll be able to change your name back to{" "}
            <span>{userInfo.legal_name}</span>.roses for another 14 days.
          </SubText>
          <section className="profile-link-container margin-vert-24">
            <p className="profile-link-container__p no-margin">
              <span>Profile</span>
              <img style={{ width: 16, height: 16 }} src={info} />
            </p>
            <p className="profile-link-container__p no-margin">
              https://roses.vip/
              <span>{username}</span>
            </p>
          </section>
          <TextActionCard
            buttonClick={verifyAccountHandler}
            principalText="Identity Verification"
            secondaryText="Let’s make sure nobody is trying to impersonate you."
            textButton="Verify Account"
          />
        </div>
        <div className="buttons-container">
          <Button
            className="margin-top-40"
            buttonStyle="primary"
            onClick={async () => {
              saveEditHandler();
            }}
          >
            Done
          </Button>
          <Button
            className="margin-top-16"
            buttonStyle="quaternary"
            onClick={async () => {
              setPrincipalScreen("/user-settings-profile");
            }}
          >
            Cancel
          </Button>
        </div>
      </main>
    </div>
  );
};

export default UserSettingsEditProfilePage;

import "./styles.scss";
import roses from "assets/icons/logos/logo-fill-pink.svg";

interface IRoseCredit {
  text: string;
  value?: string | number;
  active?: boolean;
  buttonStyle?: "gold";
  className?: string;
  onClick?: () => void;
}

const RosesCredit: React.FC<IRoseCredit> = (props) => {
  const {
    text,
    value,
    active,
    buttonStyle = "default",
    className,
    onClick,
  } = props;
  return (
    <button
      onClick={onClick}
      className={`
        roses-credit roses-credit--${buttonStyle}
        ${active ? "roses-credit--active" : ""}
        ${className ?? ""}
    `}
    >
      {/* <div className="roses-credit__iconContainer">
<span className="roses-credit__icon" />
</div> */}

      <img className="roses-credit__logo margin-bottom-4" src={roses} alt="" />
      <span
        className={`
          roses-credit__text 
          no-margin
        `}
      >
        {text}
      </span>

      {value && (
        <div className="roses-credit__values no-margin">
          {value}
          <span className="roses-credit__values--old margin-left-4">
            {value}
          </span>
        </div>
      )}

      <span className="roses-credit__discount margin-top-8">+200% bonus</span>
    </button>
  );
};
export default RosesCredit;

import React, { useState } from "react";
import "./styles.scss";

import Loading from "../../../Loading";

import { File, MessageSettings } from "../../../../types/chatInfo";

import playIcon from "assets/icons/play-circle-white.svg";

interface IChatMessageVideo {
  side: string;
  messageSettings: MessageSettings;
  file: File;
  index?: number;
  hasText?: boolean;
  className?: string;
  clickOnVideo?: (a: any) => void;
}

const ChatMessageVideo: React.FC<IChatMessageVideo> = React.memo((props) => {
  const {
    side,
    messageSettings,
    file,
    index,
    hasText,
    className,
    clickOnVideo,
  } = props;

  const { paidContent, isPaid } = messageSettings;
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    if (!isLoaded) setIsLoaded(true);
  };

  const handleClick = () => {
    if (!clickOnVideo) return;
    if (paidContent && !isPaid && side !== "right") return;
    clickOnVideo(file.url);
  };

  const videoClasses = `
    chat-message-video__file
    chat-message-video__file--${side}
    ${hasText ? "chat-message-video__file-text" : ""}
    ${index ? `file-${side}-${index + 1}` : ""}
    ${isLoaded ? "chat-file-show" : ""}
  `;

  return (
    <div className={`chat-message-video ${className || ""}`}>
      {!isLoaded && <Loading className="chat-loading" />}
      <img src={playIcon} alt="play icon" className="play-icon" />
      <video
        className={videoClasses}
        onCanPlay={handleLoad}
        onClick={handleClick}
      >
        <source src={file.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

export default ChatMessageVideo;

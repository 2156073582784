import React, { useState } from "react";
import "./styles.scss";
import FadeCarousel from "components/Carousels/FadeCarousel";
import Header from "components/Headers/Header";
import Girl18 from "assets/images/18+_girl.jpg";
import Button from "components/Buttons/Button";
import { useModal } from "hooks/useModal";

interface ExplicitContentAlertModalProps {
  className?: string;
  navigateToApp: () => void;
  navigateToLanding: () => void;
}

const ExplicitContentAlertModal: React.FC<ExplicitContentAlertModalProps> = (
  props
) => {
  const { className, navigateToApp, navigateToLanding } = props;
  const { setOpenUseModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const setStorageLegalAge = () => {
    localStorage.setItem("has_legal_age", "true");

    navigateToApp();
    setOpenUseModal(false);
  };

  const setStorageNoLegalAge = () => {
    localStorage.setItem("has_legal_age", "false");

    navigateToLanding();
    setOpenUseModal(false);
  };

  return (
    <div id="explicit-content_modal">
      <div className="explicit-content_modal__content">
        <div className="content__carousel-wrapper">
          <FadeCarousel
            slides={[
              {
                backgroundImage: Girl18,
                icon: "",
                subtitle: "",
                title: "",
              },
            ]}
            duration={4000}
            borderRadius={[24, 0, 0, 24]}
            showGradient={false}
            showText={false}
          />
        </div>
        <div className="content__form-wrapper">
          <div className="form-wrapper__texts">
            <Header
              title="Possible age restricted content!"
              marginTop={40}
              titleClass="header"
              headerIcon="roses-logo"
              subTitle={`ROSES contains content intended for certain audiences.
											It may contain sexual themes and may not be appropriate for you to watch.`}
            />
            <p className="description">
              To use ROSES, you must be at least 18 years old and the age of
              majority and legal consent under the laws of the applicable
              jurisdiction from which you are accessing this website.
            </p>
            <p className="description">
              By clicking the “CONTINUE” button, and by entering ROSES, you
              hereby (1) agree to these Terms of Use; and (2) under penalty of
              perjury, certify that you are above the age of 18 or the age of
              majority in your location, whichever is greater.
            </p>
          </div>

          <div className="content__buttons-container margin-top-24">
            <Button
              disabled={isLoading}
              buttonStyle="quaternary"
              onClick={setStorageNoLegalAge}
            >
              I’m not 18+ years old, leave now
            </Button>
            <Button buttonStyle="primary" onClick={setStorageLegalAge}>
              Continue{" "}
              <span className="icon icon-chevron-right icon-white icon-md" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExplicitContentAlertModal;

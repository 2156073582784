import "./styles.scss";

import ChevronRight from "assets/icons/navigationIcons/bx-chevron-right.svg";
import ChevronLeft from "assets/icons/navigationIcons/arrow-left-gray.svg";

interface CardsArrowsProps {
  className?: string;
  changePrevious: () => void;
  changeNext: () => void;
}

const CardsArrows: React.FC<CardsArrowsProps> = (props) => {
  const { className, changePrevious, changeNext } = props;

  return (
    <div className={`cards-arrows ${className ? className : ""}`}>
      <div className="cards-arrow-left" onClick={changePrevious}>
        <div className="cards-arrow-button-icon">
          <img src={ChevronLeft} alt="" className="cards-arrow-icon" />
        </div>
      </div>
      <div className="cards-arrow-right" onClick={changeNext}>
        <div className="cards-arrow-button-icon">
          <img src={ChevronRight} alt="" className="cards-arrow-icon" />
        </div>
      </div>
    </div>
  );
};

export default CardsArrows;

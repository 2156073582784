import "./styles.scss";
import PageTitle from "components/PageTitles";
import InfoIcon from "assets/icons/union-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useEffect, useState } from "react";
import SubText from "components/Texts/SubText";
import InputText from "components/Inputs/InputText";
import DropDown from "components/Lists/DropDown";
import { useLanguage } from "../../hooks/languageContext";
import country from "../../mocks/countryCodes.json";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import { useUserInfo } from "../../hooks/userInfo";
import PatchUserSelfDetailApi from "../../api/patchUserSelfDetail";
const languages = [
  {
    name: "🇸🇦 العربية",
    value: "ar",
  },
  {
    name: "🇩🇪 Deutsch",
    value: "de",
  },
  {
    name: "🇬🇧 English",
    value: "en",
  },
  {
    name: "🇪🇸 Español",
    value: "es-ES",
  },
  {
    name: "🇫🇷 Français",
    value: "fr",
  },
  {
    name: "🇮🇳 हिन्दी",
    value: "hi",
  },
  {
    name: "🇮🇹 Italiano",
    value: "it",
  },
  {
    name: "🇯🇵 日本語",
    value: "ja",
  },
  {
    name: "🇰🇷 한국어",
    value: "ko",
  },
  {
    name: "🇧🇷 Português (Br)",
    value: "pt-BR",
  },
  {
    name: "🇵🇹 Português (Pt)",
    value: "pt-PT",
  },
  {
    name: "🇷🇴 Română",
    value: "ro",
  },
  {
    name: "🇷🇺 Русский",
    value: "ru",
  },
  {
    name: "🇺🇦 Українська",
    value: "uk",
  },
  {
    name: "🇨🇳 中文 (简体)",
    value: "zh-CN",
  },
  {
    name: "🇹🇼 中文 (繁體)",
    value: "zh-TW",
  },
];
const UserSettingsAppLanguagePage: React.FC = () => {
  const [residence, setResidence] = useState();
  //const [language, setLanguage] = useState("English");
  const { language, setLanguage } = useLanguage();
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo, setUserInfo } = useUserInfo();

  const handleChangeLanguage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLanguage(event.target.value as any);
  };

  const patchResidence = async (name: any) => {
    const res = await PatchUserSelfDetailApi(userInfo.access_token, {
      legalResidence: name,
    });
  };

  const getUserInfo = async () => {
    const res = await getUserSelfDetail(userInfo.access_token);
    if (res.res?.legalResidence) {
      setResidence(res.res?.legalResidence);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [residence]);

  return (
    <div className="user-settings-language">
      <PageTitle
        title={"Language & Location"}
        subtitle={"Preferences"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
        className="margin-hor-16"
      />
      <div className="margin-hor-24">
        {residence && (
          <DropDown
            inputValueSet={residence}
            dropType="radio"
            label="Legal Residence"
            setValueCallBack={(e) => {
              setResidence(e[0] as any);
              patchResidence(e[0]);
            }}
            defaultValueProp={residence}
            placeholder="Select residence"
            options={country.map((country) => {
              return {
                groupName: "residence",
                inputLabel: `${country.name}`,
                inputValue: `${country.name}`,
              };
            })}
            className="margin-vert-16"
            buttonCallBack={() =>
              setPrincipalScreen("/user-settings-language-profile")
            }
          />
        )}

        {!residence && (
          <DropDown
            inputValueSet={residence}
            dropType="radio"
            label="Legal Residence"
            setValueCallBack={(e) => {
              setResidence(e[0] as any);
              patchResidence(e[0]);
            }}
            defaultValueProp={residence}
            placeholder="Select residence"
            options={country.map((country) => {
              return {
                groupName: "residence",
                inputLabel: `${country.name}`,
                inputValue: `${country.name}`,
              };
            })}
            className="margin-top-16"
            buttonCallBack={() =>
              setPrincipalScreen("/user-settings-language-profile")
            }
          />
        )}

        <SubText icon={InfoIcon} altIcon="info icon">
          If you want to change your country, please contact{" "}
          <span className="anchor-link">customer support</span>
        </SubText>

        <DropDown
          inputValueSet={
            languages.find((data) => data.value === language)?.name
          }
          dropType="radio"
          label="Default Language"
          setValueCallBack={(e) => {
            const languageValue = languages.find(
              (data) => data.name === e[0]
            )?.value;
            setLanguage(languageValue as any);
          }}
          defaultValueProp={
            languages.find((data) => data.value === language)?.name
          }
          placeholder="Select language"
          options={languages.map((language) => {
            return {
              groupName: "language",
              inputLabel: `${language.name}`,
              inputValue: `${language.name}`,
            };
          })}
          className="margin-vert-16"
          buttonCallBack={() =>
            setPrincipalScreen("/user-settings-language-profile")
          }
        />
      </div>
    </div>
  );
};

export default UserSettingsAppLanguagePage;

import React, { useState, useEffect } from "react";
import "./styles.scss";

interface FadeCarouselProps {
  slides: Slides[];
  duration: number;
  borderRadius?: [number, number, number, number];
  showText?: boolean;
  showGradient?: boolean;
}

interface Slides {
  title: string;
  subtitle: string;
  backgroundImage: string;
  icon: string;
}

const FadeCarousel: React.FC<FadeCarouselProps> = (props) => {
  const {
    slides,
    duration,
    borderRadius,
    showText = true,
    showGradient = true,
  } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [radiusValues, setRadiusValues] = useState([0, 0, 0, 0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, duration);

    return () => clearInterval(interval);
  }, [slides.length, duration]);

  useEffect(() => {
    if (borderRadius) {
      setRadiusValues(borderRadius);
    }
  }, [borderRadius]);

  const styleBorderRadius = `${radiusValues[0]}px
	 ${radiusValues[1]}px ${radiusValues[2]}px ${radiusValues[3]}px`;

  return (
    <div
      id="fade-carousel-images"
      className={"fade-carousel-images"}
      style={{ borderRadius: styleBorderRadius }}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`fade-carousel-slide ${
            index === currentIndex ? "active" : ""
          } ${showGradient ? "show-gradient-background" : ""}`}
          style={{ backgroundImage: `url(${slide.backgroundImage})` }}
        >
          {showText ? (
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${slide.backgroundImage})` }}
            >
              {slide.title && slide.subtitle && (
                <div className="text-container">
                  <img src={slide.icon} alt="Icon" className="padding-4" />
                  <h2 dangerouslySetInnerHTML={{ __html: slide.title }} />
                  <p dangerouslySetInnerHTML={{ __html: slide.subtitle }} />
                </div>
              )}
            </div>
          ) : (
            false
          )}
        </div>
      ))}
    </div>
  );
};

export default FadeCarousel;

import { forwardRef, useEffect, useRef, useState } from "react";
import "./styles.scss";

interface InputRadioProps {
  elements: InputRadioElement[];
  onChangeValueCallback: (a: string[]) => void;
  className?: string;
  defaultValueProp?: string | string[] | any[];
  iconRight?: boolean;
  actionIconRight?: (index?: number) => void;
}

interface InputRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputRadioCarousel = forwardRef<HTMLDivElement, InputRadioProps>(
  (props, ref) => {
    const {
      elements,
      onChangeValueCallback,
      className,
      defaultValueProp,
      iconRight,
      actionIconRight,
    } = props;

    const [selectedValue, setSelectedValue] = useState<string | undefined>(
      defaultValueProp
        ? Array.isArray(defaultValueProp)
          ? defaultValueProp[0]
          : defaultValueProp
        : elements[0]?.inputValue
    );

    const handleSelect = (value: any) => {
      setSelectedValue(value);
      onChangeValueCallback([value]);
    };

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (containerRef.current) {
        const selectedItem = document.getElementById(
          `text-item-${selectedValue}`
        );
        if (selectedItem) {
          containerRef.current.scrollTo({
            top:
              selectedItem.offsetTop -
              containerRef.current.clientHeight / 2 +
              selectedItem.clientHeight / 2,
            behavior: "smooth",
          });
        }
      }
    }, [selectedValue]);

    useEffect(() => {
      if (defaultValueProp) {
        setSelectedValue(
          Array.isArray(defaultValueProp)
            ? defaultValueProp[0]
            : defaultValueProp
        );
      }
    }, [defaultValueProp]);

    return (
      <div className="flex flex-col items-center overflow-hidden h-64 w-48 relative">
        <div className="flex flex-col items-center gap-2 transition-all options-container">
          {elements.map((item, index) => {
            const isSelected = item.inputValue === selectedValue;
            return (
              <div
                key={index}
                id={`text-item-${item.inputValue}`}
                className={`text-item`}
                onClick={() => handleSelect(item.inputValue)}
                style={{
                  transform: isSelected ? "scale(1.2)" : "scale(0.9)",
                  opacity: isSelected ? 1 : 0.5,
                  transition: "all 0.3s ease-in-out",
                  backgroundColor: isSelected ? "var(--charcoal)" : "",
                }}
              >
                {item.inputLabel}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);

export default InputRadioCarousel;

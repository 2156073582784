/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, ReactNode, useState } from "react";
import { ICatalogResponse } from "types/catalog";

interface CatalogListProp {
  catalogList: ICatalogResponse[] | null;
  setCatalogList: React.Dispatch<
    React.SetStateAction<ICatalogResponse[] | null>
  >;
}

interface CatalogCreatorsProviderProps {
  children: ReactNode;
}

export const catalogCreatorsContext = createContext({} as CatalogListProp);

export const catalogCreatorsProvider: React.FC<
  CatalogCreatorsProviderProps
> = ({ children }) => {
  const [catalogList, setCatalogList] = useState<ICatalogResponse[] | null>(
    null
  );

  return (
    <catalogCreatorsContext.Provider value={{ catalogList, setCatalogList }}>
      {children}
    </catalogCreatorsContext.Provider>
  );
};

import React, { forwardRef, useEffect, useState } from "react";
import "./styles.scss";
import chevronRightIcon from "assets/icons/closeIcons/close-icon-peach.svg";

interface InputRadioProps {
  elements: InputRadioElement[];
  onChangeValueCallback: (a: string[]) => void;
  className?: string;
  defaultValueProp?: string | string[] | any[];
  iconRight?: boolean;
  actionIconRight?: (index?: number) => void;
}

interface InputRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputRadio = forwardRef<HTMLDivElement, InputRadioProps>((props, ref) => {
  const {
    elements,
    onChangeValueCallback,
    className,
    defaultValueProp,
    iconRight,
    actionIconRight,
  } = props;

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    undefined
  );

  const checkValueCallback = (element: string) => {
    setSelectedValue(element);
    onChangeValueCallback([element]);
  };

  useEffect(() => {
    if (defaultValueProp) {
      setSelectedValue(
        Array.isArray(defaultValueProp) ? defaultValueProp[0] : defaultValueProp
      );
    }
  }, [defaultValueProp]);

  return (
    <div ref={ref} id="input-radio-default" className={className ?? ""}>
      {elements.map((element, index) => (
        <div className="label-input-container padding-hor-16" key={index}>
          <label
            htmlFor={`${element.inputLabel}-${index}`}
            className="container-checkbox padding-vert-16 no-margin"
          >
            {element.inputValue}
            {iconRight ? (
              <>
                <input
                  readOnly
                  id={`${element.inputLabel}-${index}`}
                  name={element.groupName}
                  value={element.inputValue}
                  type="radio"
                  onChange={() => checkValueCallback(element.inputValue)}
                  checked={selectedValue === element.inputValue}
                />
              </>
            ) : (
              <>
                <input
                  readOnly
                  id={`${element.inputLabel}-${index}`}
                  name={element.groupName}
                  value={element.inputValue}
                  type="radio"
                  onChange={() => checkValueCallback(element.inputValue)}
                  checked={selectedValue === element.inputValue}
                />
                <span className="checkmark"></span>
              </>
            )}
          </label>
          {iconRight && (
            <div
              className="rightIcon"
              onClick={() => {
                if (actionIconRight) {
                  actionIconRight(index);
                }
              }}
            >
              <img src={chevronRightIcon} alt="" />
            </div>
          )}

          {index + 1 < elements.length && <div className="separator" />}
        </div>
      ))}
    </div>
  );
});

export default InputRadio;

import fetchApi from "../fetchApi";

const getTransactionsUserById = async (
  token: string,
  transactionId: string
) => {
  return await fetchApi({
    service: "transactions",
    endPoint: `/user/${transactionId}`,
    method: "GET",
    token,
  });
};

export default getTransactionsUserById;

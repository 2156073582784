import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LogInSignUpDesk from "components/Wrappers/LoginSignUpDesk";
import AccountLoginPage from "./AccountLogin";

import getUserInfo from "api/userInfo/getUserInfo";

import { useLoginSignUpNavigation } from "../hooks/useLoginSignUpNavigation";
import { useUserInfo } from "hooks/userInfo";

import GirlWithRosesLogoEye from "assets/images/girl-opened-month.png";

const App: React.FC = () => {
  const { component } = useLoginSignUpNavigation();
  const { userInfo } = useUserInfo();

  const navigate = useNavigate();

  // useEffect(() => {
  //   const getUserInfoRequest = async () => {
  //     const response = await getUserInfo(userInfo.access_token);
  //     switch (response.status) {
  //       case 200:
  //         if (response?.res?.message?.statusCode === 401) {
  //           navigate("/login");
  //         } else {
  //           navigate("/app");
  //         }
  //         break;
  //       case 401:
  //         navigate("/login");
  //         break;
  //       default:
  //         navigate("/login");
  //         break;
  //     }
  //   };

  //   getUserInfoRequest();
  // }, [navigate, userInfo.access_token]);

  return <LogInSignUpDesk component={component || <AccountLoginPage />} />;
};

export default App;

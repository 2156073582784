import "./styles.scss";
import PageTitle from "components/PageTitles";
import Button from "components/Buttons/Button";
import { useEffect, useState } from "react";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputRadio from "components/Inputs/InputRadio";
import { useUserInfo } from "../../hooks/userInfo";
import MapRoute from "components/MapRoute";
import { SearchBox } from "@mapbox/search-js-react";
import getBlockedLocations from "api/user/getBlockedLocations";
import postBlockedLocations from "api/user/postBlockedLocations";
import deleteBlockedLocations from "api/user/deleteBlockedLocations";
import { useDialog } from "hooks/useDialog";
import { useToast } from "hooks/useToast";
const SearchBoxComponent = SearchBox as React.ComponentType<any>;
interface ListDetails {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
  latitude?: number;
  longitude?: number;
  locationHistoryId?: string;
}

const UserSettingsBlockedLocationPage: React.FC = () => {
  const { setDialogContent, setOpenDialog } = useDialog();
  const { setPrincipalScreen } = useDeskNavigation();
  const [residence, setResidence] = useState<ListDetails>();
  const [locationList, setLocationList] = useState<ListDetails[]>([]);
  const [location, setLocation] = useState<string>();
  const { userInfo } = useUserInfo();
  const mapboxToken = process.env.REACT_APP_MAPBOX_API_KEY;
  const [idToRemove, setIdToRemove] = useState<number>();
  const { showToast } = useToast();

  const removeLocation = async (e: any) => {
    locationList?.map(async (item, index) => {
      if (index === idToRemove) {
        if (item.locationHistoryId) {
          const response = await deleteBlockedLocations(
            userInfo.access_token,
            item.locationHistoryId
          );

          const res = await requestLocationHistory();

          if (res === 200) {
            setOpenDialog(false);

            showToast({
              description: `Location was successfully removed`,
              title: "Location removed",
              type: "success",
            });
          } else if (res === 500) {
            showToast({
              description: `Unable to remove location, please try again later`,
              title: "Location was not removed",
              type: "error",
            });
          }
        }
      }
    });
  };

  const updateLocation = async () => {
    if (residence?.place && residence.country) {
      const isDuplicate = locationList.some(
        (item) =>
          item.place?.toLowerCase() === residence.place?.toLowerCase() &&
          item.country?.toLowerCase() === residence.country?.toLowerCase()
      );

      if (isDuplicate) {
        setOpenDialog(false);
        return;
      }

      const res = await postBlockedLocations(userInfo.access_token, residence);

      if (res.status === 201) {
        await requestLocationHistory();
        setOpenDialog(false);
        showToast({
          description: `Location ${residence.place}, ${residence.country} blocked`,
          title: "Location was successfully removed",
          type: "success",
        });

        return;
      } else {
        showToast({
          description: `Unable to block location ${residence.place}, ${residence.country}, try again later`,
          title: "Error ",
          type: "error",
        });

        return;
      }
    }
  };

  const openDialog = async (action: "add" | "remove") => {
    if (action === "add") {
      blockLocationDialog();
    }

    if (action === "remove") {
      unlockLocationDialog();
    }
  };

  const requestLocationHistory = async () => {
    const response = await getBlockedLocations(userInfo.access_token);

    if (response.res.length > 0) {
      const index = response.res.length - 1;

      const currentLocation = `${response.res[index].location.place}, ${response.res[index].location.country}`;
      setLocation(currentLocation);
      setResidence(response.res[index].location);
      const history = response.res.map((item: any) => ({
        ...item.location,
        locationHistoryId: item.blockedLocationId,
      }));

      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => ({
            ...item,
          }))
      );

      return 200;
    } else {
      setLocationList([]);
    }
    return 500;
  };

  useEffect(() => {
    requestLocationHistory();
  }, [userInfo]);

  useEffect(() => {
    const searchIcon = document.querySelector('[class*="--SearchIcon"]');
    const actionIcon = document.querySelector('[class*="--ActionIcon"]');
    const searchBox = document.querySelector("mapbox-search-box");

    if (searchIcon) {
      const icon = searchIcon as HTMLElement;

      icon.style.width = "24px";
      icon.style.margin = "14px 16px";
      icon.style.left = "0";
    }

    if (actionIcon) {
      const action = actionIcon as HTMLElement;

      action.style.width = "24px";
      action.style.margin = "14px 16px";
      action.style.right = "0";
    }

    if (searchBox) {
      const element = searchBox as HTMLElement;
      element.style.width = "90%";
    }
  }, []);

  const blockLocationDialog = () => {
    setDialogContent({
      iconHeader: "icon-blocked",
      iconColor: "peach",
      title: "Add location to blocked list",
      text: `Are you sure you want to add this location
			 to your blocked list? This will restrict access.`,
      buttons: [
        {
          text: "Yes, Add location",
          buttonStyle: "primary",
          click: () => updateLocation(),
        },
        {
          text: "Cancel",
          buttonStyle: "quaternary",
          click: () => setOpenDialog(false),
        },
      ],
    });

    setOpenDialog(true);
  };

  const unlockLocationDialog = () => {
    setDialogContent({
      iconHeader: "icon-check",
      iconColor: "green",
      title: "Remove Location from Blocked List",
      text: `Are you sure you want to remove this location from your blocked
			list? This will restore access`,
      buttons: [
        {
          text: "Yes, Remove location",
          buttonStyle: "primary",
          click: () => removeLocation(idToRemove),
        },
        {
          text: "Cancel",
          buttonStyle: "quaternary",
          click: () => setOpenDialog(false),
        },
      ],
    });

    setOpenDialog(true);
  };

  return (
    <div className="user-settings-blocked-location">
      <div className="header">
        <PageTitle
          hasCloseIcon={false}
          title={"Block Location"}
          subtitle={"Account Privacy"}
          className="margin-hor-16"
          onBackClick={() =>
            setPrincipalScreen("/user-settings-privacy", { btn: "button3" })
          }
        />
      </div>
      <div className="body">
        <div className="map-container">
          {mapboxToken && (
            <div className="class-search margin-top-16 margin-hor-16">
              <SearchBoxComponent
                accessToken={mapboxToken}
                options={{
                  language: "pt",
                  types: "place",
                }}
                placeholder="Select Location"
                theme={{
                  variables: {
                    colorBackground: "var(--dawn)",
                    borderRadius: "30px",
                    colorText: "white",
                    colorBackgroundActive: "var(--charcoal)",
                    colorBackgroundHover: "var(--charcoal)",
                  },
                }}
                value={location}
                onChange={async (e: any) => {
                  if (e === "") {
                  }
                  //setLocation(e);
                  // const filteredSuggestions = await fetchFilteredSuggestions(e);
                }}
                onRetrieve={(sug: any) => {
                  let dataMock: ListDetails = {
                    place: sug.features[0].properties.context.place?.name,
                    region: sug.features[0].properties.context.region?.name,
                    country: sug.features[0].properties.context.country?.name,
                    country_code:
                      sug.features[0].properties.context.country?.country_code,
                    country_code_alpha_3:
                      sug.features[0].properties.context.country
                        ?.country_code_alpha_3,

                    latitude: sug.features[0].properties.coordinates.latitude,
                    longitude: sug.features[0].properties.coordinates.longitude,
                  };
                  if (
                    dataMock.place &&
                    dataMock.country_code &&
                    dataMock.region &&
                    dataMock.latitude &&
                    dataMock.longitude
                  ) {
                    setLocation(`${dataMock.place}, ${dataMock.country}`);
                    setResidence(dataMock);
                  } else {
                    setLocation(``);
                    //setResidence(``);
                  }
                }}
              />
            </div>
          )}
          {residence?.latitude && residence?.longitude && (
            <MapRoute
              originCoordinates={{
                long: residence?.longitude,
                lat: residence?.latitude,
              }}
            />
          )}
        </div>

        <div className="div__content_scroll margin-hor-24">
          <InputRadio
            elements={
              locationList
                ? locationList.map((item: any) => {
                    return {
                      groupName: "country",
                      inputLabel: `${item.place}, ${item.country}`,
                      inputValue: `${item.place}, ${item.country}`,
                    };
                  })
                : []
            }
            onChangeValueCallback={(e) => {
              locationList?.map((item: ListDetails | any, index: number) => {
                const beforeComma = e[0].split(",")[0].trim();
                if (item.place === beforeComma) {
                  setResidence(item);
                }
              });

              setLocation(e[0]);
            }}
            defaultValueProp={location}
            iconRight={true}
            actionIconRight={(e) => {
              setIdToRemove(e);
              openDialog("remove");
            }}
          />
        </div>
      </div>
      <div className="footer">
        <div className="container-button">
          <Button
            children={"ADD LOCATION"}
            buttonStyle={"primary"}
            onClick={() => openDialog("add")}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsBlockedLocationPage;

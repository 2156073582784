import React, { ReactNode } from "react";
import "./styles.scss";

interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonStyle?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "inverse"
    | "transparent"
    | "transparent-pink"
    | "transparent-green"
    | "transparent-dawn"
    | "transparentActive";
  icon?: string;
  iconClass?: string;
  className?: string;
  children?: ReactNode;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseLeave?: () => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
  const {
    buttonStyle = "primary",
    icon,
    iconClass,
    className,
    children,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    onClick,
  } = props;

  return (
    <button
      id="button-icon"
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={`button-icon button-icon--${buttonStyle} ${className ?? ""}`}
    >
      {icon && (
        <span className={`icon ${icon} icon-md ${iconClass ?? ""}`}></span>
      )}
      {children && children}
    </button>
  );
};

export default ButtonIcon;

import React from "react";
import "./styles.scss";
import ProfileIcon from "assets/icons/profile-icon-gray.svg";

interface ImageCircleBorderProps {
  className?: string;
  centerImage: string | null | undefined;
  centerImageAlt: string;
  onClick?: (a: any) => void;
  hasPinkBorder?: boolean;
  hasPadding?: boolean;
  size?: 40 | 48 | 56;
  noBorder?: boolean;
  noSpaceBetweenBorder?: boolean;
}

const ImageCircleBorder: React.FC<ImageCircleBorderProps> = (props) => {
  const {
    className,
    centerImage,
    centerImageAlt,
    onClick,
    hasPinkBorder,
    size = 48,
    noSpaceBetweenBorder,
    noBorder,
    hasPadding,
  } = props;

  const imageCircleBorderClasses = [onClick ? "clickable" : "", className ?? ""]
    .join(" ")
    .trim();

  const imageSize = noBorder ? size : size - 2;

  const imageClass = [
    "image-circle-border__center-image",
    hasPinkBorder ? "pink-border" : "transparent-border",
    noBorder ? "no-border" : "",
    noSpaceBetweenBorder ? "no-space-between-border" : "",
    hasPadding ? "padding-border" : "",
  ]
    .join(" ")
    .trim();

  return (
    <figure
      id="image-circle-border"
      className={imageCircleBorderClasses}
      onClick={onClick}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <img
        src={centerImage ?? ProfileIcon}
        alt={centerImageAlt}
        loading="eager"
        style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
        className={imageClass}
      />
    </figure>
  );
};

export default ImageCircleBorder;

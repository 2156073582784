import getUserSelfDetail from "../api/getUserSelfDetail";
import postBuyRoses from "../api/rosesTransactions/postBuyRoses";

import { useAvailableRoses } from "../contexts/availableRosesContext";
import { useUserInfo } from "../hooks/userInfo";

const useBuyRoses = (packageId?: string) => {
  const { updateSetAvailableRoses } = useAvailableRoses();
  const { userInfo } = useUserInfo();

  const buyRoses = async () => {
    if (!packageId) return;
    const request = await postBuyRoses(userInfo.access_token, packageId);

    if (request.status === 201) {
      setTimeout(async () => {
        const { res } = await getUserSelfDetail(userInfo.access_token);
        updateSetAvailableRoses(res.quantityRoses);
      }, 1000);

      return true;
    }

    return false;
  };

  return { buyRoses };
};

export { useBuyRoses };

import React, { ReactNode } from "react";
import "./styles.scss";

import FadeCarousel from "../../Carousels/FadeCarousel";
import { slideCarousel } from "mocks/slideCarousel";

interface LogInSignUpDeskProps {
  component: ReactNode;
}

const LogInSignUpDesk: React.FC<LogInSignUpDeskProps> = (props) => {
  const { component } = props;

  return (
    <div id="log-in-sign-up-desk">
      <main className="log-in-desk-type">
        <div className="image-login-wrapper">
          <FadeCarousel slides={slideCarousel} duration={4000} />
        </div>
        <div className="account-login-sign-up-page-wrapper padding-hor-24">
          {component}
        </div>
      </main>
    </div>
  );
};

export default LogInSignUpDesk;

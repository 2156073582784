import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import "./styles.scss";

import FeedCards from "components/Cards/FeedCards";
import CoverImage from "components/CoverImage";
import PageTitle from "components/PageTitles";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import ButtonIcon from "components/Buttons/ButtonIcon";
import Button from "components/Buttons/Button";
import ImageCircleBorder from "components/ImageCircleBorder";
import Loading from "components/Loading";

import CoverPhoto from "assets/images/0542b1d078ce808d00317ebe68b3d139-full 2.png";
import ProfileIcon from "assets/icons/profile-icon-gray.svg";
import logo from "assets/icons/logos/logo-fill-pink.svg";

import { useDeskNavigation, Params } from "hooks/useDeskNavigation";
import { useUserInfo } from "hooks/userInfo";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";
import { useUnlockContent } from "hooks/useUnlockContent";
import { useToast } from "hooks/useToast";

import { useChat } from "contexts/openedChatContext";
import { useWebSocket } from "contexts/webSocketContext";
import { MomentsTriggerContext } from "contexts/momentTrigger";
import { FavoritesListContext } from "contexts/favoritesListTrigger";
import { ToggleChatOpenContext } from "contexts/toggleChatOpen";
import { CreatorDetailsContext } from "contexts/creatorDetails";

import CreateChatContact from "api/postCreateChatContact";
import postAddToFavorite from "api/favorite/postAddToFavorite";
import deleteRemoveFromFavorites from "api/favorite/deleteRemoveFromFavorites";
import getPublicDetails from "api/publicDetails/getProfileDetails";
import getPostsUserById from "api/posts/getPostsUserById";
import deletePosts from "api/posts/deletePosts";
import postPostsView from "api/posts/postPostsView";
import getPostsById from "api/posts/getPostsById";

import { Chat } from "types/chatInfo";
import { IUserPublicInfo } from "types/userInfo";

type CreatorDetails = {
  creatorName: string;
  creatorAge: number | string;
  isVip: boolean;
};

const FeedPage: React.FC = () => {
  const {
    setSecondaryScreen,
    componentFeed,
    params,
    setFeedScreen,
    componentPrincipal,
    componentSecondary,
  } = useDeskNavigation();

  const { userId } = params;
  const { getChats } = useWebSocket();
  const { userInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();

  const { setMomentTrigger } = useContext(MomentsTriggerContext);
  const { setFavoritesListTrigger } = useContext(FavoritesListContext);
  const { isChatPageOpen, setIsChatPageOpen } = useContext(
    ToggleChatOpenContext
  );
  const { creatorDetails, setCreatorDetails } = useContext(
    CreatorDetailsContext
  );
  const { unlockContentSubmit } = useUnlockContent();

  const { activeChatId, setActiveChatId } = useChat();
  const { showToast } = useToast();

  const divRef = useRef<HTMLDivElement | null>(null);
  const feedCardsContainer = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  const [windowWidthSize, setWindowWidthSize] = useState(0);
  const [classWidth, setClassWidth] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);

  const [hasViewUnseen, setHasViewUnseen] = useState(false);

  const [diffPosts, setDiffPosts] = useState(0);
  const [isSub, setIsSub] = useState(false);
  const [postsTotal, setPostsTotal] = useState("0");
  const [photosTotal, setPhotosTotal] = useState("200");
  const [videosTotal, setVideosTotal] = useState("300");
  const [isLoaded, setIsLoaded] = useState(false);
  const [fallbackText, setFallbackText] = useState({
    text1: "You don't favorite a CREATOR yet",
    text2: "Favorite CREATORS to never miss a post!",
  });
  // const [requestsTotal, setRequestsTotal] = useState("400");

  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [creatorPosts, setCreatorPosts] = useState<any[]>([]);

  const [pageParams, setPageParams] = useState({} as Params);
  const [profileAvatar, setProfileAvatar] = useState<string | null>(null);

  const options = {
    root: divRef.current,
    rootMargin: "0px",
    threshold: 1.0,
  };

  const handlerDeletePost = useCallback(
    async (postId: string) => {
      try {
        await deletePosts(userInfo.access_token, { postId });
        setCreatorPosts((prevPosts) =>
          prevPosts.filter((post) => post.postId !== postId)
        );
      } catch (error) {
        showToast({
          type: "error",
          title: "Oops! Something went wrong",
          description:
            "Couldn't delete the post. Please try again in a few moments.",
        });
      }
    },
    [userInfo.access_token]
  );

  const handlerUnlockPost = useCallback(
    async (postId: string, price: number) => {
      try {
        await new Promise<void>((resolve, reject) => {
          unlockContentSubmit(postId, price, "feed", () => {
            resolve();
          });
        });

        const { res } = await getPostsById(userInfo.access_token, { postId });

        setCreatorPosts((prevPosts) => {
          return prevPosts.map((post) => {
            if (post.postId === postId) {
              return { ...post, ...res };
            }
            return post;
          });
        });
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, Try again",
          type: "error",
        });
      }
    },
    [unlockContentSubmit, userInfo.access_token]
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          let size = Math.floor(width + 48);
          setClassWidth(size);
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    publicDetails();
    setPageParams(params);
  }, [params]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          setWindowWidthSize(Math.floor(width + 48));
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (itemsRef.current.length === 0) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const beholder = entry.target;

          const postId = beholder.id;

          const cards = creatorPosts.find(
            (feedCard) => feedCard.postId === postId
          );
          if (cards && !cards.hasSeen && userInfo.user_id !== cards.userId) {
            markFeedCardAsRead(postId);
          }

          observer.unobserve(entry.target);
        }
      });
    }, options);

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);
      }
    });

    return () => observer.disconnect();
  }, [creatorPosts, setCreatorPosts]);

  useEffect(() => {
    const userPosts = async () => {
      try {
        const { res } = await getPostsUserById(userInfo.access_token, {
          userId,
        });
        setCreatorPosts((prevMessages) => [...prevMessages, ...res.posts]);
        setIsSub(res.posts[0]?.hasActiveSubscription);
        setPostsTotal(res.total);
      } catch (error) {
        setFallbackText({
          text1: "Oops! Something went wrong",
          text2: "We're working on it. Try again soon!",
        });
      } finally {
        setIsLoaded(!isLoaded);
      }
    };
    userPosts();
  }, [userInfo.access_token, userInfo.user_id]);

  const publicDetails = async () => {
    setIsLoading(true);
    if (
      creatorDetails &&
      typeof creatorDetails === "object" &&
      Object.keys(creatorDetails).length > 0
    ) {
      setIsProfileFavorite(creatorDetails.isFavorite);
      setProfileAvatar(
        creatorDetails?.profile?.photos[0]?.thumbnailUrl ||
          creatorDetails?.profile?.photos[0]?.mediumUrl ||
          creatorDetails?.profile?.photos[0]?.url
      );
    } else {
      try {
        const request = await getPublicDetails(
          userInfo.access_token,
          params.userId
        );
        setCreatorDetails(request.res);
        setIsProfileFavorite(request.res.isFavorite);
        setProfileAvatar(
          request.res.photos[0].thumbnailUrl ||
            request.res.profile.photos[0]?.mediumUrl ||
            request.res.profile.photos[0]?.url
        );
      } catch (error) {
        showToast({
          title: "Error",
          description:
            "We were unable to process the request. Please, Try again",
          type: "error",
        });
      }
    }
    setIsLoading(false);
  };

  const clickLikeHandler = async () => {
    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(userInfo.access_token, userId);
      switch (response.status) {
        case 201:
        case 204:
          setFavoritesListTrigger((prev) => !prev);
          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });
          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        userId
      );

      switch (response.status) {
        case 204:
        case null:
          setFavoritesListTrigger((prev) => !prev);

          break;
        case 409:
          break;

        default:
          showToast({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });
          break;
      }
    }
    setMomentTrigger((prev) => !prev);
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "Posts",
      textRight: postsTotal,
      iconLeft: "star-outline",
      clickAction: () => {},
      id: 1,
    },
    {
      text: "Photos",
      textRight: photosTotal,
      iconLeft: "photo",
      clickAction: () => {},
      id: 2,
    },
    {
      text: "Videos",
      textRight: videosTotal,
      iconLeft: "video",
      clickAction: () => {},
      id: 3,
    },
    // {
    //   text: "Requests",
    //   textRight: requestsTotal,
    //   iconLeft: "gifts",
    //   clickAction: () => {},
    //   id: 4,
    // },
  ];

  const closeFeed = () => {
    setFeedScreen("");

    if (!componentPrincipal && componentSecondary) {
      setSecondaryScreen("");
    }
  };

  const subscribeHandler = () => {
    setIsSub((prev) => !prev);
  };

  const sendMessageClick = async () => {
    if (userInfo.account_type === "CREATOR") return;
    setIsChatPageOpen(true);
    const creatorId = userId && userId !== "0" ? userId : pageParams.userId;

    if (creatorId) {
      const isOpenedChat = openedChatUserIds?.includes(creatorId);
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          setActiveChatId(response.res.chatId);
          break;

        default:
          break;
      }
      setSecondaryScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId: creatorId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const cardsContainer = event.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = cardsContainer;

    const isAtTop = scrollTop === 0;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

    const loadCards = async (
      cursor: number | undefined,
      direction: "top" | "bottom"
    ) => {
      if (isLoading || cursor === undefined) return;

      setIsLoading(true);

      const cardsParams = {
        userId,
        take: direction === "top" ? 0 : 10,
        cursor,
      };

      try {
        const { res } = await getPostsUserById(
          userInfo.access_token,
          cardsParams
        );

        if (res.posts.length > 0) {
          setCreatorPosts((prevMessages) => [...prevMessages, ...res.posts]);
        }
      } catch (error) {
        setFallbackText({
          text1: "Oops! Something went wrong",
          text2: "We're working on it. Try again soon!",
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (isAtBottom && creatorPosts.length > 0) {
      const lastMessageId = creatorPosts.at(-1)?.postId;
      loadCards(lastMessageId, "bottom");
    }
  };

  const markFeedCardAsRead = async (id: string) => {
    try {
      await postPostsView(userInfo.access_token, { postId: id });
    } catch (error) {
      return false;
    }
  };

  return (
    <div
      className={`feed-page ${
        componentPrincipal && componentSecondary
          ? "vertical-separator-left"
          : ""
      }`}
      ref={divRef}
    >
      <PageTitle
        onBackClick={closeFeed}
        className="feed-page__page-title margin-hor-16"
        hasCloseIcon={windowSize >= 768}
      />
      <CoverImage
        creatorInfo={{
          creatorCoverImage: CoverPhoto,
          creatorName: creatorDetails?.displayname || "",
        }}
      />

      <main
        ref={feedCardsContainer}
        onScroll={handleScroll}
        className={`feed-page-main padding-hor-16`}
      >
        <div
          className={`feed-page__interaction-nav ${
            windowWidthSize <= 560 ? "mobile-size" : ""
          }`}
        >
          <div
            className={`interaction-nav__profile-info ${
              windowWidthSize <= 560 ? "info-column" : ""
            }`}
          >
            <ImageCircleBorder
              centerImage={profileAvatar}
              centerImageAlt="user profile"
              size={56}
              noSpaceBetweenBorder
            />

            <div className="profile-info__name">
              <p className="profile-info__text">
                {creatorDetails?.displayname}
                {creatorDetails?.isVip && (
                  <span className="icon icon-burn icon-md margin-left-4" />
                )}
              </p>
              <p className="profile-info__text">
                {creatorDetails?.profile?.age} years
              </p>
            </div>
          </div>
          <div className="interaction-nav__interaction">
            <ButtonIcon
              key={0}
              onClick={clickLikeHandler}
              buttonStyle={
                isProfileFavorite ? "transparentActive" : "transparent"
              }
              icon={
                isProfileFavorite
                  ? "icon-heart-solid"
                  : "icon-heart-outline icon-gray-light"
              }
            />
            {[
              <ButtonIcon
                key={1}
                onClick={sendMessageClick}
                icon={"icon-messages icon-gray-light"}
                buttonStyle="transparent"
              />,
              <Button
                buttonStyle={isSub ? "tertiary" : "primary"}
                key={2}
                onClick={subscribeHandler}
              >
                {isSub ? "Subscribed" : "Subscribe $7.99"}
              </Button>,
            ][windowWidthSize <= 560 ? "reverse" : "slice"]()}
          </div>
        </div>
        <div className="buttons-navigation-feed-container">
          <ButtonNavigation buttons={buttonsGroup} className="margin-vert-16" />
        </div>
        {!isLoaded ? (
          <Loading className="feed-global-loading" />
        ) : (
          <>
            {creatorPosts.length > 0 ? (
              creatorPosts.map((post, index) => {
                return (
                  <FeedCards
                    key={post.postId}
                    post={post}
                    className="margin-bottom-40"
                    onDelete={() => handlerDeletePost(post.postId)}
                    onUnlockedPost={handlerUnlockPost}
                    ref={(el) => {
                      if (el) {
                        itemsRef.current[index] = el;
                      }
                    }}
                  />
                );
              })
            ) : (
              <div className="feed-global-fallback">
                <img className="feed-global-logo" src={logo} alt="Logo Roses" />
                <h2 className="feed-global-no-post">
                  {fallbackText.text1}
                  <span className="feed-global-no-post-support-text">
                    {fallbackText.text2}
                  </span>
                </h2>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default FeedPage;

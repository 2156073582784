export const dateFormat = (dateString: string | undefined) => {
  if (dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  }
};

export const hourFormat = (dateString: Date | string) => {
  const date = dateString instanceof Date ? dateString : new Date(dateString);
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");

  return `${hour}:${minute}`;
};

export const ageCalculate = (birthday: string | Date | undefined) => {
  if (birthday) {
    const today = new Date();
    const birth = new Date(birthday);

    let age = today.getFullYear() - birth.getFullYear();
    const actualMonth = today.getMonth();
    const actualDay = today.getDate();

    const birthMonth = birth.getMonth();
    const birthDay = birth.getDate();

    if (
      actualMonth < birthMonth ||
      (actualMonth === birthMonth && actualDay < birthDay)
    ) {
      age--;
    }

    return age;
  }
};

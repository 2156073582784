import fetchApi from "../fetchApi";

export type IPostPostsLike = {
  postId: string
};

const postPostsLike = async (token: string, data: IPostPostsLike) => {
  const { postId } = data;

  return await fetchApi({
    service: "posts",
    method: "POST",
    endPoint: `/like/${postId}`,
    token,
  });
};

export default postPostsLike;

import React, { createContext, ReactNode, useState } from "react";
import { ICatalogResponse } from "types/catalog";

interface CreatorDetailsProps {
  creatorDetails: ICatalogResponse | null;
  setCreatorDetails: React.Dispatch<
    React.SetStateAction<ICatalogResponse | null>
  >;
}

interface CreatorDetailsProviderProps {
  children: ReactNode;
}

export const CreatorDetailsContext = createContext({} as CreatorDetailsProps);

export const CreatorDetailsProvider: React.FC<CreatorDetailsProviderProps> = ({
  children,
}) => {
  const [creatorDetails, setCreatorDetails] = useState<ICatalogResponse | null>(
    null
  );

  return (
    <CreatorDetailsContext.Provider
      value={{ creatorDetails, setCreatorDetails }}
    >
      {children}
    </CreatorDetailsContext.Provider>
  );
};

import fetchApi from "../fetchApi";

const postUserInterests = async (token: string, interests: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/interests`,
    method: "POST",
    token,
    data: { name: interests },
  });
};

export default postUserInterests;

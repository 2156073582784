import React, { useEffect } from "react";
import "./styles.scss";
import PageTitle from "components/PageTitles";
import InputText from "components/Inputs/InputText";
import ListItemLink from "components/Lists/ListItemLink";
import Button from "components/Buttons/Button";
import { useWindowWidthSize } from "hooks/useWindowWidthSize";

interface ExclusiveListProps {
  addConsumerInListToSend: (id: string) => void;
  privateListBackButton: () => void;
  searchInConsumerList: (e: string) => void;
  inputSearchConsumer: string;
  filteredConsumerList: Consumer[];
  setChatIdsForSendContent: (value: React.SetStateAction<string[]>) => void;
  chatIdsForSendContent: string[];
  consumerList: Consumer[];
  shareWihAllHandler: () => void;
  finishSelectionHandler: () => void;
  chatIdToSend?: string;
}

type Consumer = {
  id: string;
  image: string;
  name: string;
};

const ExclusiveList: React.FC<ExclusiveListProps> = (props) => {
  const {
    addConsumerInListToSend,
    privateListBackButton,
    searchInConsumerList,
    inputSearchConsumer,
    filteredConsumerList,
    setChatIdsForSendContent,
    chatIdsForSendContent,
    consumerList,
    shareWihAllHandler,
    finishSelectionHandler,
    chatIdToSend,
  } = props;

  const { windowSize } = useWindowWidthSize();
  const removeAllIdsToSend = () => {
    setChatIdsForSendContent([]);
  };

  return (
    <section className="private-list">
      <PageTitle
        title={"Message"}
        subtitle={"Share privately to people"}
        onBackClick={privateListBackButton}
        hasCloseIcon={false}
        hasButtonNavigation={
          windowSize < 700 || (windowSize > 700 && !chatIdToSend)
        }
      />

      <InputText
        searchInput
        inputType="text"
        placeholder="Search"
        onChangeInput={searchInConsumerList}
        value={inputSearchConsumer}
      />
      <section className="private-list__list">
        {filteredConsumerList
          .sort((a, b) => {
            if (a.id === chatIdToSend) return -1;
            if (b.id === chatIdToSend) return 1;
            return 0;
          })
          .map((consumer, index, arr) => (
            <ListItemLink
              key={index}
              highlightText={consumer.name}
              hasSeparator={arr.length > index + 1}
              iconFill
              isImageCircle
              imageLeft={consumer.image}
              rightNodeContent={
                <div className="private-list__button-send-container">
                  <button
                    onClick={() => addConsumerInListToSend(consumer.id)}
                    className={`button-send ${
                      chatIdsForSendContent.includes(consumer.id)
                        ? "sended-button"
                        : ""
                    }`}
                  >
                    {chatIdsForSendContent.includes(consumer.id)
                      ? "Sent"
                      : "Send"}
                  </button>
                </div>
              }
            />
          ))}
      </section>

      <div className="add-media-page__buttons-container">
        {consumerList.length === chatIdsForSendContent.length &&
        chatIdsForSendContent.length !== 0 ? (
          <Button
            className="margin-bottom-16"
            buttonStyle="tertiary"
            onClick={removeAllIdsToSend}
          >
            Remove all from list
          </Button>
        ) : (
          <Button
            buttonStyle="tertiary"
            onClick={shareWihAllHandler}
            className="margin-bottom-16"
          >
            Share With All
          </Button>
        )}

        <Button buttonStyle="primary" onClick={finishSelectionHandler}>
          Done
        </Button>
      </div>
    </section>
  );
};

export default ExclusiveList;

import "./styles.scss";
import Header from "components/Headers/Header";
import Button from "components/Buttons/Button";
import SubText from "components/Texts/SubText";
import InfoIcon from "assets/icons/union-gray.svg";
import { useNavigate } from "react-router-dom";

const PageNotFound: React.FC = () => {

    const navigate = useNavigate();
    const onGoToHome = () => {
        navigate("/");
    }
    const onGoToCatalog = () => {
        navigate("/app");
    }


  return (
    <div className="page-not-found">
      <div className="content">
        <Header
          title={
            <>
              404
              <span>
                {" "}
                - Oops! Looks like you’ve stumbled into a hidden garden.
              </span>
            </>
          }
          marginTop={0}
          titleClass="header"
          headerIcon="roses-logo"
          subTitle="We couldn’t find the page you were looking for. But don’t worry, there’s plenty of content blooming here to explore."
        />
        <Button buttonStyle="primary" onClick={onGoToCatalog}>
          BROWN GARDEN
        </Button>
        <Button buttonStyle="tertiary" onClick={onGoToHome}>
          RETURN HOME
        </Button>
        <SubText icon={InfoIcon} altIcon="info icon" fontSize="12px">
          Or if you need help, feel free to reach out. Our roses are always in
          full bloom, ready to assist!
        </SubText>
      </div>
    </div>
  );
};

export default PageNotFound;

import React from "react";
import "./styles.scss";

import LogoHallowPink from "assets/icons/logos/logo-hollow-pink.svg";
import LogoFillPink from "assets/icons/logos/logo-rose-icon-pink.svg";
import LogoCleanPink from "assets/icons/logos/logo-clean-pink.svg";

interface FooterLPProps {
  className?: string;
}

const FooterLP: React.FC<FooterLPProps> = (props) => {
  const { className } = props;

  return (
    <div id="footer-landing-page" className={className ?? ""}>
      <div className="images-links-container margin-top-80">
        <div className="logos-container">
          {/* <img src={LogoHallowPink} alt="roses logo" />
          <img src={LogoFillPink} alt="roses logo" />
          <img src={LogoCleanPink} alt="roses logo" /> */}
          <span className="icon icon-roses-outline icon-lg"></span>
          <span className="icon icon-roses-default icon-lg"></span>
          <span className="icon icon-roses-solid icon-lg"></span>
        </div>
      </div>
      <div className="separator-footer margin-top-16"></div>
      <div className="links-terms">
        <a href="/terms-conditions" textkey="6/footerNavigation-04">
          Terms & Conditions
        </a>
        <span>|</span>
        <a href="/privacy-policy" textkey="6/footerNavigation-05">
          Privacy Policy
        </a>
        <span>|</span>
        <a href="/usc2257" textkey="6/footerNavigation-06">
          USC 2257
        </a>
        <span>|</span>
        <a href="mailto:support@roseselite.com" textkey="6/footerNavigation-07">
          Customer Support
        </a>

        <p textkey="6/footerCopyright" className="margin-top-8">
          Roses.vip , LLC - Copyright 2024<span>|</span>All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default FooterLP;

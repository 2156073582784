import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import {
  Autoplay,
  Navigation,
  Pagination,
  Virtual,
  EffectFade,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/scss/effect-fade";
import "./styles.scss";

import rosesLogoIconPink from "assets/icons/logos/logo-rose-icon-pink.svg";

interface Slide {
  backgroundImage: string;
}

interface SecondaryCarouselProps {
  slides: Slide[];
}

const LandingPageSecondaryCarousel: React.FC<SecondaryCarouselProps> = ({
  slides,
}) => {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [startX, setStartX] = useState<number | null>(null);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const endX = e.changedTouches[0].clientX;

    if (startX !== null) {
      const distance = endX - startX;

      if (distance > 50) {
        swiperRef.current?.slidePrev();
      } else if (distance < -50) {
        swiperRef.current?.slideNext();
      }
      setStartX(null);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const { clientX } = e;
    const slideWidth = window.innerWidth / 2;

    if (clientX > slideWidth) {
      swiperRef.current?.slideNext();
    } else {
      swiperRef.current?.slidePrev();
    }
  };

  return (
    <div id="landing-page-secondary-carousel" onClick={handleClick}>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={false}
        modules={[Pagination, Navigation, Virtual, Autoplay, EffectFade]}
        autoplay={{ delay: 5000 }}
        effect="fade"
        speed={500}
        onSlideChange={(swiper: SwiperCore) =>
          setCurrentSlide(swiper.activeIndex)
        }
        onSwiper={(swiper: SwiperCore) => {
          swiperRef.current = swiper;
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className="slide-background"
              style={{ backgroundImage: `url(${slide.backgroundImage})` }}
            >
              <div className="banner-wrapper-inner">
                <div className="text-container">
                  {/* <img src={rosesLogoIconPink} alt="Icon" className="padding-4"/> */}
                  <span className="icon icon-roses-default icon-lg"></span>
                  <h2
                    className="margin-vert-8"
                    textkey="4/featureSecondBannerTitle"
                  >
                    Creators' Hub
                  </h2>
                  <p textkey="4/featureSecondBannerDescription">
                    The ultimate exclusive experience for crafting exquisite
                    connections.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LandingPageSecondaryCarousel;

import { servicesApi } from "api/fetchApi/services-api";

export type PostBodyRequest = {
  price: number;
  content?: string;
  files: File[];
};

const postPost = async (token: string, data: PostBodyRequest) => {
  const apiRoute = `${servicesApi("posts")}`;

  const formData = new FormData();

  if (!data.files || data.files.length === 0) {
    throw new Error("Error: No files provided.");
  }

  formData.append("price", JSON.stringify(data.price));

  data.files.forEach((file) => {
    formData.append("files", file);
  });

  try {
    const response = await fetch(apiRoute, {
      method: "POST",
      body: formData,
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return { res: data, status: response.status };
  } catch (error) {
    console.error("Error in postPost:", error);
    throw error;
  }
};

export default postPost;

import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import FadeCarousel from "components/Carousels/FadeCarousel";
import { slideCarousel } from "mocks/slideCarousel";
import Header from "components/Headers/Header";
import SubText from "components/Texts/SubText";
import Button from "components/Buttons/Button";
import InputText from "components/Inputs/InputText";
import { useUserInfo } from "hooks/userInfo";
import googleIcon from "assets/icons/buttonIcons/google-icon-gray.svg";
import getAuthGoogle from "api/auth/getAuthGoogle";
import { useToast } from "hooks/useToast";
import UserAuth from "api/auth/postLogin";
import * as jwtDecode from "jwt-decode";
import { IInfoToken } from "types/infoToken";
import getCreatorStatus from "api/creatorApplications/getCreatorStatus";
import getUserInfo from "api/userInfo/getUserInfo";
import { useModal } from "hooks/useModal";
import { localStorageClear } from "utils/localStorageClear";
import { NavigateFunction } from "react-router-dom";
import { Params } from "hooks/useDeskNavigation";
import LoadingPage from "components/LoadingPage";

interface SignInSignUpModalProps {
  navigate: NavigateFunction;
  setComponent: (path: string | React.ReactNode, params?: Params) => void;
}

const SignInSignUpModal: React.FC<SignInSignUpModalProps> = (props) => {
  const { navigate, setComponent } = props;

  const { showToast } = useToast();
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();

  const [emailInputWarning, setEmailInputWarning] = useState(false);
  const [passwordInputWarning, setPasswordInputWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();
  const [pageToShow, setPageToShow] = useState<"login" | "terms" | "policy">(
    "login"
  );
  const modalRef = useRef<HTMLDivElement | null>(null);

  const alertToast = (description: string, title: string, type: any) => {
    showToast({
      description: description,
      title: title,
      type: type,
    });
  };

  useEffect(() => {
    if (!openUseModal) return;
    const handleClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOpenUseModal(false);
      }
    };

    setTimeout(() => {
      document.addEventListener("click", handleClick);
    }, 0);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [openUseModal]);

  const loginSubmit = async (
    method: "interAuth" | "oauthGoogle" | "authFingerPrint"
  ) => {
    if (method === "oauthGoogle") {
      try {
        const responseAuth = getAuthGoogle();
      } catch (error) {
        console.error(error);
      }
    } else if (method === "interAuth") {
      if (!userEmail && userPassword) {
        setEmailInputWarning(true);
        showToast({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });

        setTimeout(() => {
          setEmailInputWarning(false);
        }, 2000);

        return;
      }

      if (!userPassword && userEmail) {
        setPasswordInputWarning(true);

        showToast({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setTimeout(() => {
          setPasswordInputWarning(false);
        }, 4000);

        return;
      }

      if (!userPassword && !userEmail) {
        setPasswordInputWarning(true);

        showToast({
          description: "You need to add your password to continue",
          title: "Password is empty",
          type: "info",
        });

        setTimeout(() => {
          setPasswordInputWarning(false);
        }, 4000);

        setEmailInputWarning(true);
        showToast({
          description: "You need to add your email to continue",
          title: "Email is empty",
          type: "info",
        });

        setTimeout(() => {
          setEmailInputWarning(false);
        }, 2000);

        return;
      }
      try {
        const prepareData = {
          email: userEmail,
          password: userPassword,
        };

        const responseAuth = await UserAuth(prepareData);
        const access_token = responseAuth.res.access_token;
        setComponent(<LoadingPage />);
        setIsLoading(true);

        tokenTreatment(responseAuth?.status, access_token);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const tokenTreatment = async (status: number | null, token: string) => {
    try {
      switch (status) {
        case 200:
          const userInfoToken = (await jwtDecode.jwtDecode(
            token
          )) as IInfoToken;
          localStorageClear();

          setUserInfo("access_token", token);
          setUserInfo("display_name", userInfoToken?.validUser.displayname);
          setUserInfo("account_type", userInfoToken?.validUser.role);
          setUserInfo("user_phone", userInfoToken?.validUser.phone);
          setUserInfo("user_email", userInfoToken?.validUser.email);
          setUserInfo("user_id", userInfoToken?.validUser.userId);

          const responseStatus = await getCreatorStatus(token);
          getUserInfoRequest(token);

          if (responseStatus?.status === 200) {
            setUserInfo("referral_code", responseStatus?.res.referralCode);
            setUserInfo(
              "creator_application_status",
              responseStatus?.res.status
            );
            setUserInfo(
              "creator_application_id",
              responseStatus?.res.creatorApplicationId
            );
          }

          if (
            responseStatus?.res?.status === "APPROVED" &&
            userInfoToken?.validUser.role.toLocaleLowerCase() === "consumer"
          ) {
            navigate("/login");
            setComponent("/welcome-user");
            setOpenUseModal(false);
          } else {
            navigate("/app");
            setOpenUseModal(false);
            setIsLoading(false);
          }

          break;
        case 401:
          // setComponent(<AccountLoginPage />);///////////////////////////////////////
          alertToast(
            "Check your credentials and, please, try again",
            "Error",
            "error"
          );

          setPasswordInputWarning(true);
          setEmailInputWarning(true);
          setTimeout(() => {
            setPasswordInputWarning(false);
            setEmailInputWarning(false);
          }, 4000);

          break;

        default:
          alertToast(
            "We had a problem making the request. Please try again",
            "Sorry",
            "error"
          );
          break;
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const getUserInfoRequest = async (token: string) => {
    try {
      const response = await getUserInfo(token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
          } else {
            setUserInfo(
              "user_profile_avatar",
              response.res?.photos[0]?.thumbnailUrl
            );
          }
          break;
        case 401:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      loginSubmit("interAuth");
    }
  };

  return (
    <div id="sign-in-sign-up-modal" ref={modalRef}>
      <div className="sign-in-sign-up-modal__content">
        <div className="content__carousel-wrapper">
          <FadeCarousel
            slides={slideCarousel}
            duration={4000}
            borderRadius={[24, 0, 0, 24]}
            showGradient={false}
            showText={false}
          />
        </div>
        <div className="content__form-wrapper">
          <Header
            title="Welcome!"
            marginTop={40}
            titleClass="header"
            headerIcon="roses-logo"
            subTitle="For your security, please log in to continue accessing our platform."
          />
          <div className="form-wrapper__inputs">
            <InputText
              value={userEmail.toLowerCase()}
              inputType="email"
              placeholder="Email"
              textTransform="none"
              onChangeInput={(e) => setUserEmail(e)}
              isWarning={emailInputWarning}
              className="no-margin"
            />
            <InputText
              inputType="password"
              value={userPassword}
              placeholder="Password"
              onChangeInput={(e) => setUserPassword(e)}
              onKeyDown={onKeyDown}
              isWarning={passwordInputWarning}
              className="no-margin"
            />
            <Button
              disabled={isLoading}
              buttonStyle="tertiary"
              onClick={() => loginSubmit("interAuth")}
            >
              Login
            </Button>
            <div className="oauth-buttons">
              {/* <Button
              disabled={hasError || isLoading}
              buttonStyle="iconButton"
              onClick={loginSubmit}
            >
              <img src={twitterIcon} alt="twitter icon" />
            </Button> */}
              <Button
                onClick={() => loginSubmit("oauthGoogle")}
                buttonStyle="sexternary"
              >
                <img src={googleIcon} alt="google icon" />
              </Button>
              {/* <Button
              disabled={hasError || isLoading}
              buttonStyle="iconButton"
              onClick={()=>loginSubmit("authFingerPrint")}
            >
              <img src={digitalIcon} alt="finger print icon" />
            </Button> */}
            </div>
          </div>

          <div className="content__buttons-container margin-top-24">
            <Button
              disabled={isLoading}
              buttonStyle="quaternary"
              onClick={() => {
                navigate("/login");
                setComponent("/reset-password");
                setOpenUseModal(false);
              }}
            >
              Forgot Password
            </Button>
            <Button
              disabled={isLoading}
              buttonStyle="primary"
              onClick={() => {
                navigate("/login");
                setComponent("/create-account");
                setOpenUseModal(false);
              }}

              // onClick={() => setComponent("/create-account")}/////////////////
            >
              Create account
            </Button>

            <SubText>
              By tapping any of the actions above, you acknowledge that you are
              over the age of 18 and agree to our{" "}
              <span
                onClick={() => setPageToShow("terms")}
                className="buttons-container__link-privacy-policy"
              >
                Terms of Services
              </span>
              Terms of Services. Learn how we process your data in our{" "}
              <span
                onClick={() => setPageToShow("policy")}
                className="buttons-container__link-privacy-policy"
              >
                Privacy Policy
              </span>
              .
            </SubText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInSignUpModal;

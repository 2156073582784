import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useAvailableRoses } from "../../contexts/availableRosesContext";
import Button from "components/Buttons/Button";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "components/Buttons/ButtonNavigation";
import InputText from "components/Inputs/InputText";
import PageTitle from "components/PageTitles";
import RosesDashboard from "components/Wallet/RosesDashboard";
import SendRosesModal from "components/Chat/ChatModals/SendRosesModal";
import ListItemExtract from "components/Lists/ListItemExtract";
import ListItemRadio from "components/Lists/ListItemRadio";
import SlidUp from "components/Modals/SlidUp";

import bankIcon from "assets/icons/bank.icon-gray.svg";
import chevronRightIcon from "assets/icons/navigationIcons/bx-chevron-right.svg";
import logo from "assets/icons/logos/logo-fill-pink.svg";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useModal } from "hooks/useModal";

import postWithdrawRoses from "../../api/rosesTransactions/postWithdrawRoses";
import getAutoRecharge from "../../api/rosesTransactions/autoRecharge/getAutoRecharge";

import { IToast } from "../../types/toast";

import { formatDateShort } from "../../utils/formatDateShort";
import { thousandsFormat } from "../../utils/thousandsFormat";
import { textCapitalize } from "../../utils/textCapitalize";

import cardsMock from "../../mocks/cards";
import getTransactionsExtract from "api/transactions/getTransactionsExtract";
import { useToast } from "hooks/useToast";

type IExtract = {
  transactionId: string;
  entryType: string;
  amount: number;
  createdAt: string;
  transaction: {
    transactionType: string;
    description: string;
  };
};

const UserSettingsWalletPage: React.FC = () => {
  // Contexts
  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();
  const { setPrincipalScreen, params } = useDeskNavigation();
  const { showToast } = useToast();

  // Local States
  const [activeSection, setActiveSection] = useState<string>(
    params.btn ? params.btn : "button1"
  );
  const [activatedButton, setActivatedButton] = useState(
    params.id ? Number(params.id) : 1
  );
  const [cardDefault, setCardDefault] = useState("");
  const [extracts, setExtracts] = useState<IExtract[]>();
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  // Refs
  const listExtractsRef = useRef<(HTMLDivElement | null)[]>([]);

  // Others hooks
  const { userInfo } = useUserInfo();
  const { setOpenUseModal, setModalContent } = useModal();

  // Dummy data/Mock
  const cards = cardsMock;

  useEffect(() => {
    handleGetExtract();
  }, [availableRoses]);

  const applyRechargeRequest = async () => {
    const request = await getAutoRecharge(userInfo.access_token);
    return request;
  };

  const handleRedeemRoses = async () => {
    const request = await postWithdrawRoses(
      userInfo.access_token,
      availableRoses
    );
    if (request.status === 201 && availableRoses > 0) {
      updateSubtractAvailableRoses(availableRoses);

      handleToast({
        type: "success",
        title: "Redeem Roses",
        description: "Congratulations",
      });
    } else if (availableRoses === 0) {
      handleToast({
        type: "info",
        title: "Redeem Roses",
        description: "Not has roses to redeem",
      });
    } else {
      handleToast({
        type: "error",
        title: "Redeem  Roses",
        description: "Error",
      });
    }
  };

  const mapTransactionType: Record<string, string> = {
    deposit: "Add Roses",
  };

  const transformExtract = (data: IExtract): IExtract => {
    return {
      ...data,
      transaction: {
        ...data.transaction,
        transactionType:
          mapTransactionType[data.transaction.transactionType.toLowerCase()] ||
          data.transaction.transactionType,
      },
    };
  };

  const handleGetExtract = async () => {
    // const dateEnd = new Date().toISOString();
    // const dateStart = new Date("2020-01-01").toISOString();
    const { res, status } = await getTransactionsExtract({
      token: userInfo.access_token,
    });

    const transformedResponse = res.transactions.map(transformExtract);

    if (status === 200) {
      setExtracts(transformedResponse);
    }
  };

  const handleToast = ({ type, title, description }: IToast) => {
    showToast({
      type,
      title,
      description,
    });
  };

  const handleModalRedeem = () => {
    setModalContent(
      <aside className="redeem-modal padding-16">
        <header className="redeem-modal__top">
          <img
            src={logo}
            alt="Logo Roses"
            className="redeem-modal__logo margin-bottom-16"
          />
          <h2 className="redeem-modal__title no-margin margin-bottom-16">
            Would you like to rescue your roses?
          </h2>
        </header>
        <div>
          <Button
            children={"Yes"}
            buttonStyle={"primary"}
            onClick={() => {
              handleRedeemRoses();
              setOpenUseModal(false);
            }}
          />
          <Button
            children={"No"}
            buttonStyle={"quaternary"}
            onClick={() => setOpenUseModal(false)}
          />
        </div>
      </aside>
    );
  };

  const mapMonths = {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  };

  const getTextContent = (parent: HTMLDivElement, selector: string) =>
    parent.querySelector(selector)?.textContent?.toLocaleLowerCase() || "";

  const handlerSearchFilter = (text: string) => {
    const formattedSearch = text
      .toString()
      .toLocaleLowerCase()
      .trim()
      .split(/\s+/);

    listExtractsRef.current.forEach((item) => {
      if (!item) return;

      const typeTransaction = getTextContent(item, "[data-title]");
      const dataTransaction = getTextContent(item, "[data-date]");
      const amountTransaction = getTextContent(item, "[data-amount]");
      const valueTransaction = getTextContent(item, "[data-value]");

      const dataShort = dataTransaction ? dataTransaction.slice(0, 3) : "";
      const dataLong = mapMonths[dataShort as keyof typeof mapMonths] || "";

      const completeTransaction = `${typeTransaction} ${amountTransaction} ${dataTransaction}${dataLong} ${valueTransaction}`;

      const match = formattedSearch.every((term) =>
        completeTransaction.includes(term)
      );

      item.classList.toggle("show", match);
      item.classList.toggle("hidden", !match);
    });
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    { text: "Overview", clickAction: () => setActiveSection("button1"), id: 1 },
    { text: "Activity", clickAction: () => setActiveSection("button2"), id: 2 },
    { text: "Payout", clickAction: () => setActiveSection("button3"), id: 3 },
  ];

  return (
    <div className="user-settings-wallet">
      <div className="actions-wrapper">
        <PageTitle
          title={"My Wallet"}
          subtitle={"Manage Payment"}
          onBackClick={() => setPrincipalScreen("/user-settings", {})}
          hasCloseIcon={false}
          className="margin-hor-16"
        />
        <ButtonNavigation
          activatedButton={Number(activatedButton)}
          buttons={buttonsGroup}
          className="margin-vert-16"
        />
      </div>

      {activeSection === "button1" && (
        <section className="available-roses-recharge margin-hor-24">
          <RosesDashboard
            title={thousandsFormat(availableRoses)}
            showAddButton={true}
            showGoldRoses={true}
            onClick={() => setIsOpen(!isOpen)}
          />

          {/* <AutoRecharge
            cards={cards}
            applyRechargeCallback={applyRechargeRequest}
          /> */}
          <SlidUp
            isOpen={isOpen}
            classNameContainer="user-settings-wallet__slid"
            classNameChildren="user-settings-wallet__slide-children"
            closeArea={() => {
              setIsOpen(false);
            }}
          >
            <SendRosesModal
              title="Buy Roses"
              description="Select Roses below"
              showRosesOptions
              showInfoGold
              showTable={{
                table: false,
                header: {
                  gold: true,
                  roses: true,
                  total: true,
                },
              }}
              typeModal="buy"
              buttonText="Buy Roses"
              onClose={() => setIsOpen(false)}
            />
          </SlidUp>
        </section>
      )}

      {activeSection === "button2" && (
        <section className="transactions-activity margin-hor-24">
          <InputText
            value={search}
            placeholder="Search"
            onChangeInput={(text) => {
              setSearch(text);
              handlerSearchFilter(text);
            }}
            searchInput
            inputType="text"
          />
          <div className="list-container margin-top-16">
            {extracts &&
              extracts.map((extract, index) => {
                return (
                  <ListItemExtract
                    ref={(el) => (listExtractsRef.current[index] = el)}
                    key={index}
                    title={textCapitalize(extract.transaction.transactionType)}
                    description={formatDateShort(extract.createdAt)}
                    date={{
                      long: extract.createdAt,
                      short: formatDateShort(extract.createdAt),
                    }}
                    amount={thousandsFormat(extract.amount)}
                    isExtract
                    iconRight={chevronRightIcon}
                    onClick={() => {
                      setPrincipalScreen("/user-settings-extract-details", {
                        transactionId: extract.transactionId,
                      });
                    }}
                  />
                );
              })}
          </div>
        </section>
      )}

      {activeSection === "button3" && (
        <section className="redeem-credits padding-hor-24">
          <RosesDashboard
            title={thousandsFormat(availableRoses)}
            showRedeemButton={true}
            onClick={() => {
              setOpenUseModal(true);
              handleModalRedeem();
            }}
          />

          <p className="redeem-credits__header margin-top-16 margin-bottom-4">
            Bank account
          </p>
          <div className="list-container">
            {cards.map((card, index, arr) => (
              <ListItemRadio
                key={index}
                groupName="card-default"
                radioId={index + ""}
                imageLeft={bankIcon}
                hasSeparator={arr.length > index + 1}
                highlightText={card.banner}
                simpleText={card.number}
                checkValueCallback={setCardDefault}
                isImageCircle
                inputValue={card.number}
                isChecked={card.default}
                rightNodeContent={
                  card.default && (
                    <p className="text-detail no-margin margin-right-16">
                      Default
                    </p>
                  )
                }
              />
            ))}
          </div>
          <Button
            buttonStyle="quaternary"
            onClick={() => {}}
            children={"Connect Account"}
            className="margin-top-16"
          />
        </section>
      )}
    </div>
  );
};

export default UserSettingsWalletPage;

import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

type TooltipPosition = "top" | "bottom" | "left" | "right";

interface ITooltip {
  text: string;
  className?: string;
}

const Tooltip: React.FC<ITooltip> = (props) => {
  const { text = "Need a message", className } = props;

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const tooltipBoxRef = useRef<HTMLDivElement | null>(null);

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<TooltipPosition>("top");

  let [isFadingOut, setIsFadingOut] = useState(false);

  const resetTooltipClasses = () => {
    if (!tooltipBoxRef.current) return;
    tooltipBoxRef.current.classList.remove(
      "tooltip__top",
      "tooltip__left",
      "tooltip__right",
      "tooltip__bottom",
      "tooltip__visible",
      "tooltip__fade-out"
    );
  };

  useEffect(() => {
    if (!visible) return;

    const calculatePosition = () => {
      if (!tooltipRef.current || !tooltipBoxRef.current) return;

      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const triggerRect = tooltipBoxRef.current.getBoundingClientRect();
      const { width, height } = document.body.getBoundingClientRect();

      const canTop = tooltipRect.y - triggerRect.height > 0;
      const canLeft = tooltipRect.x - triggerRect.width > 0;
      const canRight =
        tooltipRect.x + tooltipRect.width + triggerRect.width < width;
      const canBottom =
        tooltipRect.y + tooltipRect.height + triggerRect.height < height;

      let newPosition: TooltipPosition = "top";

      if (canTop && canLeft) {
        setPosition("top");
      } else if (!canTop && canRight) {
        setPosition("right");
      } else if (canBottom && canLeft) {
        setPosition("bottom");
      } else {
        setPosition("left");
      }

      setPosition(newPosition);
    };

    calculatePosition();
    window.addEventListener("resize", calculatePosition);
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [visible]);

  return (
    <div
      ref={tooltipRef}
      className={`
        tooltip 
        ${className ?? ""}
    `}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => {
        setVisible(false);
        setTimeout(() => {
          if (isFadingOut) {
            resetTooltipClasses();
            setIsFadingOut(false);
          }
        }, 250);
      }}
    >
      <span className="icon icon-info"></span>
      <div
        ref={tooltipBoxRef}
        className={`
          tooltip__box 
          tooltip__${position}
          ${!visible ? "tooltip__fade-out" : ""}
        `}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;

import React from "react";
import "./styles.scss";

import Button from "../Buttons/Button";

import cameraIconGray from "assets/icons/camera-gray.svg";
import imagesIconGray from "assets/icons/images-icon-gray.svg";
import lockIconGray from "assets/icons/lock-icon-gray.svg";

interface LockedFilesChatProps {
  message: string;
  caption?: string;
  rosesPrice: string | number;
  qntPhotos: string | number;
  qntVideos: string | number;
  lockedImageBlurred: string;
  className?: string;
  unlockClick: (a: any) => void;
}

const LockedFilesChat: React.FC<LockedFilesChatProps> = (props) => {
  const {
    message,
    caption,
    rosesPrice,
    qntPhotos,
    qntVideos,
    lockedImageBlurred,
    className,
    unlockClick,
  } = props;
  return (
    <div
      className={`
        locked-files-chat 
        ${className ? className : ""}
      `}
    >
      <div
        className={`locked-files-chat__locked ${
          !caption ? "locked-files-chat-locked--with-caption" : ""
        }`}
      >
        {lockedImageBlurred && (
          <img src={lockedImageBlurred} alt="locked images" />
        )}
        {/* <div className=".locked-files-chat__info-container">
          <span className="locked-files-chat__icon-box">
            <img src={cameraIconGray} alt="camera icon" />
            <p className="locked-files-chat__icon-text no-margin">{qntVideos}</p>
          </span>
          <span className="locked-files-chat__icon-box">
            <img src={imagesIconGray} alt="images icon" />
            <p className="locked-files-chat__icon-text no-margin">{qntPhotos}</p>
          </span>
        </div> */}
        <div className="locked-files-chat__main-content">
          <span className="icon icon-roses-default icon-md"></span>
          {/* <img src={lockIconGray} alt="lock icon" /> */}
          <h4 className="locked-files-chat__title no-margin">{rosesPrice}</h4>
          {/* <p className="locked-files-chat__text no-margin">{message}</p> */}
        </div>
        <Button
          className="locked-files-chat__btn"
          buttonStyle="quintenary"
          onClick={unlockClick}
        >
          Unlock
        </Button>
      </div>
      {caption && (
        <div className="locked-files-chat__caption">
          <p className="locked-files-chat__caption-text no-margin">{caption}</p>
        </div>
      )}
    </div>
  );
};

export default LockedFilesChat;
